import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ValidationService } from '@app/services/validation/validation.service';
import { AuthService } from '@app/services/auth/auth-service.service';
import { Router } from '@angular/router';
import { catchError, finalize } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { UiService } from '@app/services/cdk/ui.service';
import notEmailValidator from '@app/utils/validators/not-email/not-email.validator';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  username: FormControl = new FormControl(null, [Validators.required, notEmailValidator]);
  controlHasError = this.validations.controlHasError;
  loading = false;

  constructor(
    private validations: ValidationService,
    private auth: AuthService,
    private router: Router,
    private ui: UiService
  ) {}

  ngOnInit(): void {}

  submit() {
    if (this.username.invalid) {
      return;
    }

    this.loading = true;
    this.ui.startLoading();
    this.auth
      .forgotPassword(this.username.value)
      .pipe(
        catchError((err) => {
          this.username.setErrors({
            httpError:
              'There has been an error with the entered information. Please try again',
          });
          return throwError(() => new Error('Error'));
        }),
        finalize(() => {
          this.loading = false;
          this.ui.stopLoading();
        })
      )
      .subscribe((response) => {
        this.router.navigate(['/forgot-password', 'email-confirmation']);
      });
  }
}
