
export interface Contract {
  id: number;
  name: string;
  slug: string;
  content: string;
  type: string;
  active: boolean;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
  front_component: string;
  send_copy: boolean;
  send_copy_read_only: boolean;
  send_copy_value: boolean;
  text_component: string
  title: string
  content_transformed: ContentTransformed
}

export interface ContentTransformed {
  title: string;
  content: string;
  inputs: string[];
  rules: any;
}

export interface ContractSign{
  effectiveDate: string;
  username:string;
  send_copy: number;
  signature: string;
  printedName: string;
  npn: string;
  title: string;
  agencyName: string;
  id_contract: number;
}
