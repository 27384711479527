import {
  Component,
  Input,
  ContentChild,
  TemplateRef,
  forwardRef,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { PerfectScrollbarComponent } from "ngx-perfect-scrollbar";

@Component({
  selector: "cdk-filter-input",
  templateUrl: "./filter-input.component.html",
  styleUrls: ["./filter-input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterInputComponent),
      multi: true,
    },
  ],
})
export class FilterInputComponent<Option> implements ControlValueAccessor {
  /**
   * NgTemplateRef of option. Use as selected fallback when selectedTmpl not provided.
   */
  @ContentChild("itemTmpl", { static: true, read: TemplateRef })
  itemTmpl: TemplateRef<any>;

  @ViewChild(PerfectScrollbarComponent, { static: false })
  perfectScrollbar: PerfectScrollbarComponent;

  @Input() set options(data: Option[]) {
    this.items = data;
    this.selectedItem = undefined;
    this.resultFocusIndex = 0;
  }
  @Input() isLoading = false;
  @Input() showSelected = true;
  @Input() icon?: string;
  @Input() placeholder = "Select an option";
  @Input() set closeFilter(value: boolean) {
    this.isOpen = value;
  }

  items: Option[] = [];
  selectedItem?: Option;
  isDisabled = false;
  isOpen = false;
  resultFocusIndex = 0;

  @Output() changeSelected = new EventEmitter<any>();
  @Output() search = new EventEmitter<string>();
  @Output() scrollPagination = new EventEmitter<void>();
  @Output() openChanged = new EventEmitter<boolean>();

  constructor() {}

  onChange(option: Option | string) {}
  onTouched() {}

  onChangeSelected(item: Option): void {
    this.selectedItem = item;
    this.changeSelected.emit(item);
    this.onChange(item);
    this.isOpen = false;
    this.openChanged.emit(false);
  }

  onSearch(event: any): void {
    this.search.emit(event.target.value);
  }

  openOptions(): void {
    this.isOpen = true;
    this.openChanged.emit(true);
  }

  closeOptions(event: MouseEvent | any): void {
    if (event?.relatedTarget?.id === "filter-input-component") {
      return;
    }
    this.isOpen = false;
    this.openChanged.emit(false);
  }

  onScroll(e?: any): void {
    this.scrollPagination.emit();
  }

  writeValue(obj: Option): void {
    this.selectedItem = obj;
  }

  registerOnChange(fn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
