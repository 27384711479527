import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/services/auth/auth-service.service';
import { UiService } from '@app/services/cdk/ui.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss'],
})
export class ContractsComponent implements OnInit, OnDestroy {
  contract: string;
  appLogo: string;

  private subscriptions: Record<string, Subscription> = {};

  counterContracts: { index: number, totalContracts: number } = { index: 1, totalContracts: 1 };

  constructor(
    private route: ActivatedRoute,
    private _ui: UiService,
    private _auth:AuthService,
  ) {
    this.subscriptions['getAppLogo'] = this._ui.getAppLogo$().subscribe((logo) => {
      this.appLogo = logo;
    });

    this.subscriptions['declineBtn'] = this._auth.
      declineWithCounter$.subscribe(
        (value:boolean) =>{
          if ( value=== true ) this.scrollToTop();
        }
      );
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.contract = params.get('type');
    });
  }

  scrollToTop() {
    document.getElementById('app').scrollTop = 0;
  }

  ngOnDestroy(): void {
    Object.values(this.subscriptions).forEach((value) => value.unsubscribe());
  }
}
