import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
} from "@angular/core";

@Directive({
  selector: "[appCrashImage]",
})
export class CrashImageDirective {
  @Input() imgSrc = "./assets/img/utilidades/doc.png";
  @Input() width = "7.5rem";
  @Input() height = "auto";

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  @HostListener("error")
  uploadDeafultImage(): void {
    const element = this.elementRef.nativeElement;
    element.src = this.imgSrc;
    this.renderer.setStyle(element, "width", this.width);
    this.renderer.setStyle(element, "height", this.height);
  }
}
