import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PowerbiComponent} from './powerbi.component';
import {LoadingModule} from '@app/shared/loading/loading.module';

@NgModule({
  declarations: [PowerbiComponent],
  imports: [CommonModule, LoadingModule],
  exports: [PowerbiComponent],
})
export class PowerbiModule {}
