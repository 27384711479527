import {
  Directive,
  Input,
  OnChanges,
  SimpleChanges,
  ElementRef,
} from '@angular/core';

@Directive({
  selector: '[cdkMenuItem]',
  host: {class: 'dropdown-item'},
})
export class MenuItemDirective implements OnChanges {
  @Input('cdkMenuItem') addClass = true;

  constructor(private _el: ElementRef<HTMLElement>) {}

  ngOnChanges({addClass}: SimpleChanges) {
    if (addClass) {
      if (!addClass.currentValue) {
        this._el.nativeElement.classList.remove('dropdown-item');
      } else {
        this._el.nativeElement.classList.add('dropdown-item');
      }
    }
  }
}
