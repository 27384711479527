<div class="contract-container">
  <div class="bg-white rounded">
    <div class="row mx-0 contracts-sign__body pb-0">
      <h4 class="mb-4" [innerHTML]="contract?.content_transformed.title"></h4>
      <div class="position-relative">
        <div
          id="bodyContract"
          class="contract-paragraph contract-text"
          [innerHtml]="sanitizeInnerHtml(contract?.content_transformed.content)"
          #scrollContainer
          (scroll)="onScroll($event)"></div>
        <div
          *ngIf="shouldShowBanner"
          class="d-flex justify-content-center info-card position-absolute mx-0 mx-md-4"
          [@inOutAnimation]>
          <span cdkIcon="outlined">keyboard_double_arrow_down</span>You must
          scroll through the entire terms of the agreement before you can agree
        </div>
      </div>
    </div>
    <div class="user-actions contracts">
      <div class="row contracts-sign__form-actions">
        <div class="d-flex flex-column-reverse flex-md-row gap-3">
          <div class="pe-0 pe-sm-5 col-md-4 d-block">
            <div
              *ngIf="contract?.text_component.length"
              class="d-flex disclaimer gap-2">
              <span class="info-icon"
                ><span cdkIcon="outlined">info</span></span
              >
              <span>{{ contract?.text_component }}</span>
            </div>
            <div class="d-flex align-content-center contracts__send-mail">
              <cdk-checkbox
                *ngIf="contract?.send_copy"
                class="w-100"
                [disabled]="isSendCopyReadOnly"
                [(ngModel)]="sendCopy">
                <span class="option-action">Send copy to my email</span>
              </cdk-checkbox>
            </div>
          </div>

          <div class="col-12 col-md-8">
            <ng-container
              *ngIf="componentName; else notFound"
              [ngComponentOutlet]="componentMap[componentName]"></ng-container>
            <ng-template #notFound>
              <div class="d-flex justify-content-center">
                Formulario no encontrado.
              </div>
            </ng-template>
          </div>
        </div>

        <div
          class="d-flex flex-column-reverse flex-sm-row justify-content-end p-0 m-0 mt-4">
          <div class="col-12 col-sm-3">
            <button
              type="button"
              class="mt-2 mt-sm-0 me-0 me-sm-5 w-100 btn-skip-to-home"
              cdkButton
              [outline]="true"
              *ngIf="isAsr && isShowSkip"
              (click)="skipToHome()">
              Skip to Home
            </button>
          </div>
          <div
            class="d-flex flex-column-reverse flex-sm-row justify-content-end contracts__button-actions col-12 col-sm-9">
            <button
              type="button"
              class="mt-2 mt-sm-0 me-0 me-sm-5"
              cdkButton="dark"
              [outline]="true"
              *ngIf="decline"
              (click)="declineContract()">
              I decline
            </button>
            <button
              type="submit"
              cdkButton
              [disabled]="!validateForm() || loading"
              class="btn btn-next"
              (click)="submit()">
              I agree
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
