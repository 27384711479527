<div class="message-container align-items-start d-flex">
  <div class="notification-status">
    <span class="notification-status-indicator active"></span>
  </div>
  <div class="notification-content d-flex flex-column">
    <span class="notification-subject mb-2 ms-3 active">
      {{ notification?.subject }}
    </span>
    <span class="notification-stamps ms-3">
      {{ notification?.updated_at | date : 'MMM dd, YYYY' }} at {{ notification?.updated_at | date : 'H:mm a' }}
    </span>
  </div>
</div>
