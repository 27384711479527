import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

const nope = (_?: any) => {
  // not to do
};

@Component({
  host: {
    class:
      'form-check custom-checkbox cursor-pointer d-inline-flex align-items-center user-select-none',
  },
  selector: 'cdk-checkbox',
  template: `
    <input
      #input
      type="checkbox"
      class="form-check-input"
      [checked]="checked"
      [disabled]="disabled"
      *ngIf="!close" />
    <div
      class="close"
      *ngIf="close"
      (click)="$event.stopPropagation(); closeChanged.emit()">
      <i class="ic-close-bold ic-sm"></i>
    </div>
    <label
      class="d-inline-flex align-items-center form-check-label cursor-pointer" [ngClass]="customClassLabel">
      <ng-content></ng-content>
    </label>
  `,
  styles: [
    `
      :host {
        z-index: 0;
        min-height: 1rem !important;
      }
      label {
        font-size: 0.825rem;
        line-height: 1.1 !important;
        font-weight: normal;
        min-width: 20px;
        width: 100%;
        vertical-align: middle !important;
        margin-bottom: 0;
        margin-top: 0.3rem;
      }
      .form-check-label:before {
        border-color: currentColor;
      }
      .form-check-label:before,
      .form-check-label:after {
        top: 50%;
        transform: translateY(-0.5rem);
      }
      .close {
        position: absolute;
        left: 0;
        z-index: 1;
        width: 1rem;
        height: 1rem;
        cursor: pointer;
        border-radius: 4px;
        background: var(--green);
        color: var(--main);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() disabled: boolean;
  @Input() checked: boolean;
  @Input() customClassLabel: string;
  @Output() checkedChange = new EventEmitter<boolean>();
  @ViewChild('input', {static: true, read: ElementRef})
    input: ElementRef<HTMLInputElement>;

  @Input()
  set indeterminate(value: boolean) {
    if (this.disabled) {
      return;
    }

    if (value) {
      this.checked = false;
      this.changed(undefined);
    }
    if (this.input?.nativeElement) {
      this.input.nativeElement.indeterminate = !!value;
    }
  }

  @Input()
    close = false;

  @Output() closeChanged = new EventEmitter<boolean>();

  private onChange = nope;
  private onTouched = nope;

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    this.checked = obj;
  }

  @HostListener('click', ['$event'])
  click(e: MouseEvent) {
    e.stopPropagation();
    if (this.disabled) {
      return;
    }
    this.changed(!this.checked);
  }

  changed(value) {
    this.checked = value;
    this.checkedChange.emit(this.checked);
    this.onChange(this.checked);
  }
}
