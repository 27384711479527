<div class="welcome-container d-flex flex-column align-items-center">
  <div class="welcome-logo d-flex flex-column align-items-center text-center">
    <app-logo-arc></app-logo-arc>
    <p class="text-white">By {{ companyName }}</p>
  </div>
  <div
    class="d-flex flex-column text-center text-white align-items-center mt-4">
    <p class="welcome-title fs-2">
      Welcome to the <span class="text-celeste-500">family</span>.
    </p>

    <p class="welcome-message fw-normal px-2">
      We’re glad you are part of our ARC family. Before we started, please
      select your default login account.
    </p>

    <div
      class="d-flex flex-sm-row flex-column justify-content-between align-items-center my-4 w-100">
      <div *ngFor="let rol of roles">
        <app-rol-choise
          [rol]="rol.name?.toLowerCase()"
          [active]="rol.id === selectedRol"
          (click)="selectedRol = rol.id"></app-rol-choise>
      </div>
    </div>

    <button
      cdkButton
      class="mb-4 text-white welcome-btn"
      [disabled]="!selectedRol"
      (click)="submit()">
      Continue
    </button>
  </div>

  <app-social-media backgroundClass="bg-warning"></app-social-media>
</div>
