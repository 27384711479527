import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
  forwardRef,
  Output,
  EventEmitter,
} from "@angular/core";
import { FilterInputComponent } from "@modules/@cdk";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "cdk-form-filter",
  templateUrl: "./forms-filter.component.html",
  styleUrls: ["./forms-filter.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormsFilterComponent),
      multi: true,
    },
  ],
})
export class FormsFilterComponent<Option>
  extends FilterInputComponent<Option>
  implements OnChanges
{
  @Input() searchPlaceholder = "search";
  @Input() height = "auto";
  @Input() direction = "ltr";
  @Input() async = false;
  @Input() searchBy: string;
  @Input() trackBy: string;
  @Input() tooltipBy: string;
  @Input() enabledAddItemUnsearchable = false;
  @Output() updateNewItems = new EventEmitter<string>();
  @Output() removeNewItems = new EventEmitter<number>();

  _items: Option[] = [];
  value: Option;

  @Input() set options(items: Option[]) {
    this.items = items;
    this._items = this.items;
    this.selectedItem = undefined;
    this.resultFocusIndex = 0;
  }

  @HostListener("click", ["$event"])
  toggle() {
    if (this.isDisabled) {
      return;
    }

    this.openOptions();
  }

  constructor(
    public elementRef: ElementRef<HTMLDivElement>,
    private _cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.items.length || !this.value) return;

    if (changes["options"]) {
      this.selectedItem = this.mapValue(this.value);
    }
  }

  get filteredOptions(): Option[] {
    return this.async ? this.items : this._items;
  }

  close = (event: any): void => {
    this.closeOptions(event);
    this.onTouched();
  };

  getItemTooltip(item: Option): string {
    if (!this.tooltipBy) return null;

    return item[this.tooltipBy];
  }

  onSearch(event: Event) {
    const value: string = event.target["value"];

    if (this.async) {
      return this.search.emit(value);
    }

    if (!value) {
      this._items = this.items;
      return;
    }

    this._items = this.items.filter(i => {
      const _value: string = this.searchBy ? i[this.searchBy] : i;
      return _value.toLowerCase().includes(value.toLowerCase());
    });
  }

  onChangeSelected(item: Option): void {
    this.selectedItem = item;
    this.changeSelected.emit(item);

    this.value = this.trackBy
      ? this.selectedItem[this.trackBy]
      : this.selectedItem;

    this.onChange(this.value);

    this.isOpen = false;
    this.openChanged.emit(false);
    this._cd.markForCheck();
  }

  writeValue(value: Option): void {
    if (!value) {
      this.selectedItem = undefined;
      this.value = undefined;
      this._cd.detectChanges();
      return;
    }

    this.value = value;
    this.selectedItem = this.mapValue(value);

    this._cd.detectChanges();
  }

  mapValue(value: Option) {
    if (this.trackBy) {
      return this.items.find(item => {
        return (
          item[this.trackBy] === value[this.trackBy] ||
          item[this.trackBy] === value
        );
      });
    }

    return this.items.find(item => item === value);
  }

  /**
   * Obtiene el value y emite un Output para que el componente padre se encargue de gestionar
   * el nuevo item, luego reinicia el valor del search
   * @param{HTMLInputElement} inputSearch Elemento HTML del input de donde se va a obtener el value
   */
  initiateAddItemUnsearchable(inputSearch: HTMLInputElement): void {
    const { value } = inputSearch;

    this.updateNewItems.emit(value);

    inputSearch.value = "";

    const event = new Event("input", {
      bubbles: true,
      cancelable: true,
    });
    inputSearch.dispatchEvent(event);
  }

  /**
   * Emite un evento para que el componente padre sea el responsable de gestionar la eliminación del item
   * @param{number} index Posición en la que se encuentra el array
   */
  removeItemUnsearchable(index: number): void {
    this.removeNewItems.emit(index);
  }
}
