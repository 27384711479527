<app-auth-layout hero="/assets/img/temp/auth.svg" [signUp]="signUp">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div class="sign-info">
      <label class="title_section">Welcome!</label>
      <h3 class="title text-normal"><strong>Sign In</strong></h3>
      <cdk-form-group class="mt-4">
        <label>Username</label>
        <span cdkSuffix>
          <span cdkIcon="outlined" styleClass="icon_input">account_circle</span>
        </span>
        <input
          type="text"
          cdkInput
          formControlName="username"
          placeholder="E.g Jane" />
        <cdk-error *ngIf="controlHasError(form, 'username', 'required')"
          >This field is required</cdk-error
        >
      </cdk-form-group>
      <cdk-form-group class="mt-4">
        <label>Password</label>
        <input
          [type]="visiblePassword ? 'text' : 'password'"
          cdkInput
          formControlName="password"
          placeholder="***************" />
        <span cdkSuffix>
          <span
            cdkIcon="outlined"
            [styleClass]="
              controlHasError(form, 'password', 'required')
                ? 'error_input'
                : 'icon_input'
            "
            (click)="visiblePassword = !visiblePassword"
            >{{
              !form.get('password').value
                ? 'lock'
                : visiblePassword
                ? 'visibility_off'
                : 'visibility'
            }}
          </span>
        </span>
        <cdk-error *ngIf="controlHasError(form, 'password', 'required')"
          >This field is required</cdk-error
        >
      </cdk-form-group>
      <p id="error-message" class="text-danger status-message" *ngIf="message">{{ message }}</p>
      <div class="align-items-center row mt-3">
        <div class="col-7">
          <cdk-checkbox
            [checked]="rememberUser"
            (checkedChange)="rememberMe()"
            class="sign-action w-100"
            >&nbsp; Remember me
          </cdk-checkbox>
        </div>
        <div class="col-5 d-flex justify-content-end text-center">
          <a routerLink="/forgot-password" class="sign-action forgot-password"
            >Forgot Password?</a
          >
        </div>
      </div>
    </div>
    <div class="align-items-center row sign-footer">
      <div class="col-12 col-md-6 order-md-2 order-sm-1">
        <button
          type="submit"
          cdkButton
          class="text-light w-100"
          [disabled]="form.invalid || loading">
          Sign In
        </button>
      </div>
      <div class="col-12 col-md-6 order-md-1 order-sm-2 my-2">
        <p class="disclaimer text-center">
          By pressing "Sign in" you agree to the
          <a href="javascript:void(0)" (click)="openPrivacyPolicy()"
            >Privacy Policy</a
          >
        </p>
      </div>
    </div>
  </form>
</app-auth-layout>
