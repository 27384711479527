import {Role, User} from '@app/models/users/user.model';
import {createAction, props} from '@ngrx/store';

// User Actions
export const login = createAction('[Auth Module] login', props<User>());

export const activeAsr = createAction('[Auth Module] activeAsr', props<{ status: boolean }>());

export const changeRole = createAction(
  '[Auth Module] changeRole',
  props<Role>()
);
