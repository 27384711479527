import {Directive, Input, TemplateRef} from '@angular/core';

@Directive({
  selector: '[appDataTableTemplate]',
})
export class DataTableTemplateDirective {
  @Input() type: string;
  @Input() name: string;
  constructor(public templateRef: TemplateRef<any>) {}
}
