import {PopoverOptionsDirective} from './popover-options.directive';
import {PopoverDirective} from './popover.directive';

import {OverlayModule} from '@angular/cdk/overlay';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {PopoverContextmenuTriggerDirective} from './popover-contextmenu-triger.directive';

@NgModule({
  declarations: [
    PopoverDirective,
    PopoverOptionsDirective,
    PopoverContextmenuTriggerDirective,
  ],
  imports: [CommonModule, OverlayModule],
  exports: [
    PopoverDirective,
    PopoverOptionsDirective,
    PopoverContextmenuTriggerDirective,
  ],
})
export class PopoverModule {}
