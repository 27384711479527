import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GeneralAlertComponent} from './components/general-alert/general-alert.component';
import {MatDialogModule} from '@angular/material/dialog';
import {IconModule} from '@app/modules/@cdk/icon/icon.module';
import {AlertComponent} from './components/alert/alert.component';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';


@NgModule({
  declarations: [GeneralAlertComponent, AlertComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    IconModule,
    MatButtonModule,
    MatTooltipModule,
  ],
  exports: [GeneralAlertComponent],
})
export class NotificationsModule { }
