import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from '@app/models/users/user.model';
import { AuthService } from '@app/services/auth/auth-service.service';
import { ContractsService } from '@app/services/contracts/contracts.service';
import { UsersService } from '@app/services/users/users.service';
import { ValidationService } from '@app/services/validation/validation.service';
import { Subscription, fromEvent } from 'rxjs';

@Component({
  selector: 'app-contract-agent',
  templateUrl: './contract-agent.component.html',
  styleUrls: ['./contract-agent.component.scss'],
})
export class ContractAgentComponent implements AfterViewInit, OnDestroy {
  @ViewChild('formSign') formContractID: ElementRef;

  formContract!: FormGroup;
  currentUser: User;
  controlHasError = this._validations.controlHasError;
  private subscriptions: Record<string, Subscription> = {};

  constructor(
    private fb: FormBuilder,
    private _userService: UsersService,
    private _validations: ValidationService,
    private _contractService: ContractsService,
    private _auth: AuthService
  ) {
    this.currentUser = this._userService.getUser();
    this.formContract = this.fb.group(this.validationsFormForRoleAgent());
    this.formContract.disable();
    
    this._contractService.formContract = this.formContract;
    this.subscriptions['formSubscriptions'] = this.formContract?.valueChanges
      .subscribe((value) => {
        this._auth.declineWithCounter$.next(false);
      });
  }

  ngAfterViewInit(): void {
    const formEventAgency = this.formContractID.nativeElement.querySelectorAll('input');

    formEventAgency.forEach((element, i) => {
      this.subscriptions[`formSubscription_${i}`] =
        fromEvent(element, 'focus')
          .subscribe((value) => {
            if (value) this._auth.declineWithCounter$.next(false);
          });
    });
  }

  validationsFormForRoleAgent() {
    const { sf_npn: NPN } = this.currentUser;
    return {
      signature: new FormControl(null, [Validators.required]),
      printedName: new FormControl(null, [Validators.required]),
      npn: new FormControl(null, [Validators.required, this._validations.equals(NPN)]),
      title: new FormControl({
        value: 'Agent',
        disabled: true,
      }, [Validators.required]),
    };
  }

  ngOnDestroy(): void {
    Object.values(this.subscriptions).forEach((value) => value.unsubscribe());
  }
}
