import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import {UsersService} from '@app/services/users/users.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WelcomeGuard implements CanActivate {
  constructor(private userService: UsersService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.userService.fetchUser().pipe(
      map((data) => {
        const {
          user,
          menu: { roles },
        } = data;

        if (!!user.default_role_id || roles.filter((role) => role.name !== 'ASR').length <= 0) {
          this.router.navigate(['/home']);
          return false;
        }

        return true;
      })
    );
  }
}
