import {CheckboxComponent} from './checkbox.component';
import {SwitchComponent} from './switch.component';

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';

import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [CheckboxComponent, SwitchComponent],
  imports: [CommonModule, FormsModule, TranslateModule.forChild()],
  exports: [CheckboxComponent, SwitchComponent],
})
export class CheckboxModule {}
