import { Component, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { UsersService } from '@app/services/users/users.service';
import { FormGroup } from '@angular/forms';
import { ValidationService } from '@app/services/validation/validation.service';
import { UiService } from '@app/services/cdk/ui.service';
import { UserData } from '@app/models/users/user.model';
import { AddressAutocompleteResponse, ConfigView } from '@app/models';
import { StepService } from '../../services/step.service';
import { UserRole } from '@app/enums/user-role';
import { CarrierStateLobService } from '@app/services/carrier-state-lob/carrier-state-lob.service';
import { LocalStorageService } from '@app/services/storage/local-storage.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-address',
  templateUrl: './home-address.component.html',
  styleUrls: ['./home-address.component.scss'],
})
export class HomeAddressComponent implements OnInit, OnDestroy {
  private subscriptions: Record<string, Subscription> = {};
  form: FormGroup;
  state;
  states = [];
  isTheSameAddress = true;
  isPreregisterRole = false;

  controlHasError = this.validations.controlHasError;

  @ViewChild('addressInputAutocomplete', { static: true })
    addressInput: ElementRef<HTMLInputElement>;

  constructor(
    private users: UsersService,
    private validations: ValidationService,
    private _carrierState: CarrierStateLobService,
    private ui: UiService,
    private _stepService: StepService,
    private _userService: UsersService,
    private _localStorageService: LocalStorageService
  ) {
    this.form = this.users.updateStep2HomeAddress;
    this.loadStates();
  }

  ngOnInit(): void {
    this._validateIfIsPreRegisterRole();
  }

  ngOnDestroy(): void {
    Object.values(this.subscriptions).forEach(value => value.unsubscribe());
  }

  get address(): string {
    return this.form.get('sf_address').value;
  }

  loadStates() {
    this.ui.startLoading();
    this._carrierState.getResource('states').subscribe({
      next: resp => {
        this.states = resp;

        const stateForm =
          this.users.updateStep2HomeAddress.get('sf_state').value;

        const state = this.states.find(
          ({ States__c }) => States__c === stateForm
        );

        this.form.get('sf_state').setValue(state);
      },
      error: () => {
        this.ui.stopLoading();
      },
      complete: () => {
        this.ui.stopLoading();
      },
    });
  }

  updateAddress(event: AddressAutocompleteResponse) {
    const { address, addressData } = event;

    this.state = this.states.find(({ item }) => item === addressData.state);

    this.form.patchValue({
      sf_address: address,
      sf_state: this.state,
      sf_city: addressData.city,
      sf_county: addressData.county,
      sf_zip_code: addressData?.zip_code ?? '',
    });
  }

  goBack() {
    this.saveInformation();
    this.users.updateStep$.next(1);
  }

  next() {
    this.saveInformation();
    this.users.updateStep$.next(3);
  }

  saveInformation() {
    const user = this.users.getUser();
    const token = this.users.loadUserToken();
    const menu = this.users.getMenu();

    const dataFrom = this.form.getRawValue();
    dataFrom.sf_state = dataFrom.sf_state?.States__c ?? {};
    const userData: UserData = {
      menu,
      token,
      user: { ...user, home_address: { ...dataFrom } },
    };
    this.users.setCurrentUser(userData);
  }

  onMailingAddressChange() {
    const isTheSameAddress = this.form.get('sf_same_mailing').value;
    this._userService.updateStep2
      .get('sf_same_mailing')
      .setValue(isTheSameAddress);

    this._userService.updateStep$.next(1);

    if (isTheSameAddress) {
      delete this._stepService.steps.homeAddress;
    } else {
      const arraySteps = Object.entries(this._stepService.steps);
      const newData = {
        title: 'Home Address',
        name: 'homeAddress',
        component: HomeAddressComponent,
        formGroup: this._userService.updateStep2HomeAddress,
      };

      arraySteps.splice(-1, 0, ['homeAddress', newData]);

      const steps: { [key: string]: ConfigView<any> } = {};

      for (const [key, value] of arraySteps) {
        steps[key] = value;
      }

      this._stepService.steps = steps;
    }
  }

  private _validateIfIsPreRegisterRole(): void {
    this.subscriptions["userRole"] = this._userService.userRole$.subscribe(
      role => {
        this.isPreregisterRole = role?.name === UserRole.PREREGISTER;
      }
    );
  }
}
