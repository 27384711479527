import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SelectComponent} from './select.component';
import {FormsModule} from '@angular/forms';
import {PopoverModule} from '@app/modules/popover/popover.module';
import {TranslateModule} from '@ngx-translate/core';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {IconModule} from '../../icon/icon.module';
import {CheckboxModule} from '../checkbox/checkbox.module';
import { FormGroupModule } from '../input/form-group/form-group.module';

@NgModule({
  declarations: [SelectComponent],
  imports: [
    CommonModule,
    FormsModule,
    PopoverModule,
    PerfectScrollbarModule,
    IconModule,
    CheckboxModule,
    TranslateModule.forChild(),
    FormGroupModule,
  ],
  exports: [SelectComponent],
})
export class SelectModule {}
