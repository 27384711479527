import { AgencyTree } from "@app/models/tree/tree-agency-subangency.model"
import { cloneDeep } from "lodash";

export const agencyTreeToList = (agency: AgencyTree) => {
  return getAgenciesChildren([agency]).filter(sub => sub.Type === 'Agency');
}

export const agentsTreeToList = (agency: AgencyTree) => {
  return getAgenciesChildren([agency]);
}

export const agencyListToTree = (agencies: AgencyTree[], query: string) => {
  const resp: AgencyTree = agencies.find(agency => !agency.parenId);
  resp.Dependents = resp.Name.toLocaleLowerCase().includes(query.toLocaleLowerCase())
    ? getAgenciesTree(agencies, resp.Id, '')
    : getAgenciesTree(agencies, resp.Id, query);
  return [resp].filter(sub => sub.Name.toLocaleLowerCase().includes(query.toLocaleLowerCase()) || sub.Dependents?.length > 0);
}

const getAgenciesChildren = (agencies: AgencyTree[], parentId?: string) => {
  let resp: AgencyTree[] = [];

  agencies.forEach(value => {
    const sub = cloneDeep(value);
    sub.parenId = parentId;
    if (sub.Dependents?.length > 0) {
      resp = resp.concat(getAgenciesChildren(sub.Dependents, sub.Id));
      sub.Dependents = [];
      resp.push(sub);
      return;
    }
    resp.push(sub);
  });

  return resp;
};

const getAgenciesTree = (agencies: AgencyTree[], parentId: string, query: string) => {
  return agencies
    .filter(sub => sub.parenId === parentId)
    .map(sub => {
      sub.Dependents = sub.Name.toLocaleLowerCase().includes(query.toLocaleLowerCase())
        ? getAgenciesTree(agencies, sub.Id, '')
        : getAgenciesTree(agencies.filter(filter => filter.Name.toLowerCase().includes(query.toLocaleLowerCase())), sub.Id, query);
      return sub;
    })
    .filter(sub => sub.Name.toLocaleLowerCase().includes(query.toLocaleLowerCase()) || sub.Dependents?.length > 0);
}
