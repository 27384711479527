import { Module } from '@app/models';

export function treeToList(tree: Module[]): Module[] {
  return tree.reduce((acc, module) => {
    if (module) {
      acc.push({...module, items: []});
    }

    if (module.items) {
      acc = acc.concat(this.treeToList(module.items));
    }

    return acc;
  }, []);
}
