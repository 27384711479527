import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonDirective} from './button.directive';
import {IconButtonDirective} from './icon-button.directive';

@NgModule({
  declarations: [ButtonDirective, IconButtonDirective],
  imports: [CommonModule],
  exports: [ButtonDirective, IconButtonDirective],
})
export class ButtonModule {}
