import {ChangeDetectorRef, Injectable, Injector} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpService} from '../http/http.service';
import {ComponentType, Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {OverlayService} from './overlay.service';
import {GeneralLoadingComponent} from '@app/shared/loading/components/general-loading/general-loading.component';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import {GeneralAlertComponent} from '@app/shared/notifications/components/general-alert/general-alert.component';
import {AlertComponent} from '@app/shared/notifications/components/alert/alert.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ConfirmationModalComponent} from '@app/shared/confirmation-modal/confirmation-modal.component';
import {BehaviorSubject, Observable} from 'rxjs';
import {NotificationModalComponent} from '@app/shared/notification-modal/notification-modal.component';
import {Title} from '@angular/platform-browser';
import {map} from 'rxjs/operators';
import { InternalCompany } from '@app/models/internal-company/internal-company.model';

export interface BreadCrumRedirect {
  label: string;
  route: string | string[];
  icon?: string;
}

export interface ISocialMedia {
  url: string;
  icon: string;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class UiService {
  private _loadingComponents: {
    general: GeneralLoadingComponent;
  };

  private _dialogRef: MatDialogRef<any>;

  private _overlayRef: OverlayRef;
  private _alertRef: OverlayRef;

  private _breadcrumbRedirect$: BehaviorSubject<BreadCrumRedirect | null> =
    new BehaviorSubject(null);

  public readonly breadcrumbRedirect$: Observable<BreadCrumRedirect | null> =
    this._breadcrumbRedirect$.asObservable();

  private _appLogo$: BehaviorSubject<string> = new BehaviorSubject(
    `assets/img/logo/${environment.company}.svg`
  );

  private _setup$: BehaviorSubject<Record<string, any>> = new BehaviorSubject(
    {}
  );

  private internalCompany: InternalCompany;

  constructor(
    private _http: HttpService,
    private _overlay: OverlayService,
    private _cdkOverlay: Overlay,
    private _title: Title
  ) {}

  getAppLogo$(): Observable<string> {
    return this._appLogo$.asObservable();
  }

  getAppSetup$(): Observable<Record<string, any>> {
    return this._setup$.asObservable();
  }

  getAppSetup(): Record<string, any> {
    return this._setup$.getValue();
  }

  getInternalCompany(): InternalCompany {
    return this.internalCompany;
  }

  setAppLogo(imageName: string) {
    this._appLogo$.next(`assets/img/logo/${imageName}`);
  }

  setAppSetup(data) {
    this._setup$.next(data);
  }

  setInternalCompany(data: InternalCompany) {
    this.internalCompany = data;
  }

  setBreadCrumbRedirect(buttonData: BreadCrumRedirect) {
    this._breadcrumbRedirect$.next(buttonData);
  }

  loadSiteConfig() {
    return this._http.getModule('setup').pipe(map((res) => res.data));
  }

  createComponentPortal<T>(component: ComponentType<T>): ComponentPortal<T> {
    return new ComponentPortal(component);
  }

  startLoading(component = 'general') {
    if (this._dialogRef) {
      return;
    }

    this._dialogRef = this._overlay.openDialog(
      GeneralLoadingComponent,
      {
        disableClose: true,
        panelClass: ['cdk-dialog-no-background'],
        backdropClass: ['cdk-dialog-light-background'],
      },
      true,
      true
    );
  }

  stopLoading() {
    if (this._dialogRef) {
      this._dialogRef.close();
      this._dialogRef = null;
    }
  }

  showAlert(
    message: string,
    status: 'success' | 'warning' | 'error' = 'success',
    parentID = '#app_nav_bar',
    parentBoundingKey: 'top' | 'bottom' = 'bottom'
  ): void {
    let container = document.querySelector(parentID);

    if (!container) {
      container = document.querySelector('body');
    }

    let top = container.getBoundingClientRect()[parentBoundingKey];
    let left = container.getBoundingClientRect().left;

    if (top < 0) {
      top = 0;
    }

    if (left < 0) {
      left = 0;
    }

    if (this._alertRef) {
      return;
    }

    this._alertRef = this._cdkOverlay.create({
      hasBackdrop: false,
      positionStrategy: this._cdkOverlay
        .position()
        .global()
        .left(`${left}px`)
        .top(`${top}px`),
      panelClass: 'cdk-dialog--notification-panel',
    });

    const portal = new ComponentPortal(AlertComponent);
    const componentRef = this._alertRef.attach(portal);
    componentRef.instance.message = message;
    componentRef.instance.status = status;
    componentRef.instance.close.subscribe((data) => {
      this.closeAlert();
    });
  }

  closeAlert() {
    if (this._alertRef) {
      this._alertRef.detach();
      this._alertRef = null;
    }
  }

  confirm(title = 'Confirm', message: string): MatDialogRef<any> {
    return this._overlay.openDialog(ConfirmationModalComponent, {
      maxWidth: '382px',
      disableClose: true,
      data: {
        title,
        message,
      },
    });
  }

  /**
   *
   * @param title the title for the notification
   * @param content the message to be displayed. Can be a HTML text
   * @param filesHistory in case the notification is for a massive load
   * @param config in case there's an additional configuration needed
   *
   * @return MatDialogRef<NotificationModalComponent>
   */
  openNotificationModal(
    title: string,
    content: string,
    filesHistory = false,
    config: MatDialogConfig = {}
  ): MatDialogRef<NotificationModalComponent> {
    return this._overlay.openDialog(NotificationModalComponent, {
      maxWidth: '700px',
      width: '95%',
      ...config,
      data: {
        title,
        content,
        filesHistory,
      },
    });
  }

  setTitle(title: string) {
    this._title.setTitle(title);
  }
}
