<div
  class="switch-account d-flex justify-content-between cursor-pointer align-items-center"
  (click)="changeStateList()">
  <span> Click to switch account </span>
  <div class="d-flex flex-column justify-content-center">
    <span cdkIcon="outlined" styleClass="text-white fs-40"
      >keyboard_arrow_up</span
    >
    <span cdkIcon="outlined" styleClass="text-white fs-40"
      >keyboard_arrow_down</span
    >
  </div>
</div>
<div class="switch-account-list" [@slideDown]="showList">
  <ng-content></ng-content>
</div>
