export interface SupportDocumentRow {
  NPN__c: string;
  Id: string;
  CreatedById: string;
  Carrier__c: string;
  MARKETPLACE_ID__c: string;
  CLIENT_FULL_NAME__c: string;
  DOB__c: string;
  STATE__c: string;
  COMPLETE_DOCUMENTS__c: string;
  DISPOSITON__c: string;
  UPLOAD_DATE__c: string;
  EMAIL_CONFIRMATION_DATE__c?: string;
  FOLLOW_UP_DATE__c: string;
  ADITIONAL_INFORMATION__c?: string;
  CREATE_DATE__c: string;
  Full_name1__c: string;
  Type_of_document1__c: string;
  Document1__c: string;
  Full_name2__c?: string;
  Type_of_document2__c?: string;
  Document2__c?: string;
  Full_name3__c?: string;
  Type_of_document3__c?: string;
  Document3__c?: string;
  Full_name4__c?: string;
  Type_of_document4__c?: string;
  Document4__c?: string;
  Full_name5__c?: string;
  Type_of_document5__c?: string;
  Document5__c?: string;
  Full_name6__c?: string;
  Type_of_document6__c?: string;
  Document6__c?: string;
  Full_name7__c?: string;
  Type_of_document7__c?: string;
  Document7__c?: string;
  Full_name8__c?: string;
  Type_of_document8__c?: string;
  Document8__c?: string;
  Full_name9__c?: string;
  Type_of_document9__c?: string;
  Document9__c?: string;
  Full_name10__c?: string;
  Type_of_document10__c?: string;
  Document10__c?: string;
  Full_name11__c?: string;
  Type_of_document11__c?: string;
  Document11__c?: string;
  Full_name12__c?: string;
  Type_of_document12__c?: string;
  Document12__c?: string;
  Full_name13__c?: string;
  Type_of_document13__c?: string;
  Document13__c?: string;
  Full_name14__c?: string;
  Type_of_document14__c?: string;
  Document14__c?: string;
  Full_name15__c?: string;
  Type_of_document15__c?: string;
  Document15__c?: string;
  Full_name16__c?: string;
  Type_of_document16__c?: string;
  Document16__c?: string;
  Referrals__r?: string;
  Carrier__r: Carrierr;
  PendingDocuments__c?: string;
}

export interface Carrierr {
  attributes: Attributes;
  Name: string;
}

export interface Attributes {
  type: string;
  url: string;
}
