import {Directive, ElementRef, EventEmitter, Output} from '@angular/core';
import {AddressAutocompleteResponse, GeocoderAddressComponent} from '@app/models';

@Directive({
  selector: '[addressAutocompleteGoogle]',
})
export class AddressAutocompleteGoogleDirective {
  @Output() updateAddress: EventEmitter<AddressAutocompleteResponse> = new EventEmitter();
  @Output() updateFieldAddress: EventEmitter<string> = new EventEmitter();

  constructor(private autoCompleteAddressInput: ElementRef) {
    this.setAutocomplete();
    this.onListenInputChange();
  }

  setAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(
      this.autoCompleteAddressInput.nativeElement,
      {
        componentRestrictions: {country: 'US'},
        types: ['address'],
      }
    );

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const {address_components, formatted_address} = autocomplete.getPlace();
      const data: AddressAutocompleteResponse = {
        addressData: this.formatAddressComponents(address_components),
        address: formatted_address,
      };

      this.updateAddress.emit(data);
    });
  }

  private formatAddressComponents(data: GeocoderAddressComponent[]) {
    let state: string; let county: string; let city: string; let zip_code: any;

    state = this.getComponent(data, 'administrative_area_level_1');
    county = this.getComponent(data, 'administrative_area_level_2');
    city = this.getComponent(data, 'locality');
    zip_code = this.getComponent(data, 'postal_code');

    return {state, county, city, zip_code};
  }

  private getComponent(
    addressComponents: GeocoderAddressComponent[],
    type: string
  ) {
    return addressComponents.find((c) => c.types.includes(type))?.long_name ?? '';
  }

  onListenInputChange() {
    this.autoCompleteAddressInput.nativeElement.addEventListener('input', (_) => {
      this.updateFieldAddress.emit(this.autoCompleteAddressInput.nativeElement.value);
    });
  }
}
