import { Component, Input } from '@angular/core';

@Component({
  selector: 'cdk-error',
  template: `
    <p class="text-danger" [ngClass]="customClass">
      <ng-content></ng-content>
    </p>
  `,
  styles: [
  ],
})
export class ErrorComponent {
  @Input() customClass = '';
}
