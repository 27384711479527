import {Component, OnInit, Input} from '@angular/core';

export enum ThemeColor {
  DARK = 'dark',
  LIGHT = 'light',
}

export enum MenuPosition {
  CENTERED = 'center',
  ABOVE = 'up',
  BELOW = 'down',
  ABOVE_CENTERED = 'up-center dropup',
  RIGHT = 'end',
  LEFT = 'start',
}

export enum MenuSize {
  EXTRA_LARGE = 'xl',
  LARGE = 'lg',
  MEDIUM = 'md',
  SMALL = 'sm',
}

@Component({
  selector: 'cdk-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  @Input() theme: ThemeColor | string = ThemeColor.LIGHT;
  @Input() position: MenuPosition | string = MenuPosition.BELOW;
  @Input() alignRight = false;
  @Input() size: MenuSize | string = null;

  constructor() {}

  ngOnInit(): void {}

  get menuClass(): string {
    return `drop${this.position}`;
  }

  get dropdownClass(): { [key: string]: any } {
    const classes = {
      'dropdown-menu-dark': this.theme === 'dark',
      'dropdown-menu-end': this.alignRight,
    };

    return classes;
  }
}
