import {Role, User} from '@app/models/users/user.model';
import {ActionReducerMap} from '@ngrx/store';
import {activeAsrReducer, roleReduce, userReducer} from './user.reducer';

export interface AppState {
  user: User;
  role: Role;
  activeAsr: Role;
}

export const reducers: ActionReducerMap<any> = {
  user: userReducer,
  role: roleReduce,
  activeAsr: activeAsrReducer,
};
