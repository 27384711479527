import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import { ContractsRoutingModule } from './contracts-routing.module';
import { ContractsComponent } from './contracts.component';
import { SocialMediaModule } from '@app/shared/social-media/social-media.module';
import { ContractSignModule } from '@app/shared/contract-sign/contract-sign.module';
import { MessageWithCounterModule } from '@app/shared/message-with-counter/message-with-counter.module';

@NgModule({
  declarations: [ContractsComponent],
  imports: [
    CommonModule,
    ContractsRoutingModule,
    SocialMediaModule,
    ContractSignModule,
    MessageWithCounterModule,
  ],
  exports: [ContractsComponent],
})
export class ContractsModule { }
