import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PasswordFormComponent} from './password-form.component';
import {FormGroupModule} from '@app/modules/@cdk/forms/input/form-group/form-group.module';
import {InputModule} from '@app/modules/@cdk/forms/input/input.module';
import {IconModule} from '@app/modules/@cdk/icon/icon.module';
import {ReactiveFormsModule} from '@angular/forms';
import {ButtonModule} from '@app/modules/@cdk/forms/button/button.module';
import {HeaderFormComponent} from './components/header-form/header-form.component';

@NgModule({
  declarations: [PasswordFormComponent, HeaderFormComponent],
  imports: [
    CommonModule,
    FormGroupModule,
    InputModule,
    IconModule,
    ButtonModule,
    ReactiveFormsModule,
  ],
  exports: [PasswordFormComponent],
})
export class PasswordFormModule {}
