<form class="row" *ngIf="form" [formGroup]="form" (ngSubmit)="next()">
  <div class="col-12 col-sm-6">
    <cdk-form-group>
      <label>First name</label>
      <input
        type="text"
        cdkInput
        placeholder="E.g John"
        formControlName="sf_first_name" />
      <cdk-error *ngIf="controlHasError(form, 'sf_first_name', 'required')"
        >This field is required</cdk-error
      >
      <cdk-error *ngIf="controlHasError(form, 'sf_first_name', 'pattern')"
        >Please insert a valid value</cdk-error
      >
    </cdk-form-group>
  </div>
  <div class="col-12 col-sm-6">
    <cdk-form-group>
      <label>Last name</label>
      <input
        type="text"
        cdkInput
        placeholder="E.g Doe"
        formControlName="sf_last_name" />
      <cdk-error *ngIf="controlHasError(form, 'sf_last_name', 'required')"
        >This field is required</cdk-error
      >
      <cdk-error *ngIf="controlHasError(form, 'sf_last_name', 'pattern')"
        >Please insert a valid value</cdk-error
      >
    </cdk-form-group>
  </div>
  <div class="col-12 col-sm-6">
    <cdk-form-group [class.form-group-fill]="!!form.get('sf_birth_day').value">
      <label *ngIf="!isPreregisterRole">Date of birth</label>
      <label *ngIf="isPreregisterRole">DOB</label>
      <input
        cdkInput
        [matDatepicker]="picker"
        [max]="minDate"
        [min]="maxDate"
        formControlName="sf_birth_day"
        placeholder="MM/DD/YYYY"
        appDateInput />
      <mat-datepicker-toggle cdkSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <cdk-error *ngIf="controlHasError(form, 'sf_birth_day', 'required')"
        >This field is required</cdk-error
      >

      <cdk-error
        *ngIf="controlHasError(form, 'sf_birth_day', 'matDatepickerMax')"
        >Please insert a valid date of birth</cdk-error
      >
    </cdk-form-group>
  </div>
  <div class="col-12 col-sm-6" *ngIf="!isPreregisterRole">
    <cdk-form-group [class.form-group-fill]="!!form.get('locale').value">
      <label>Language*</label>
      <cdk-select
        [options]="['en']"
        [noBackground]="true"
        cdkInput
        formControlName="locale"
        [placeholder]="'Select language'">
        <ng-template #itemTmpl let-item> English </ng-template>
      </cdk-select>

      <span cdkSuffix>
        <span cdkIcon="outlined">language</span>
      </span>
      <cdk-error *ngIf="controlHasError(form, 'locale', 'required')"
        >This field is required</cdk-error
      >
    </cdk-form-group>
  </div>
  <div class="col-12 col-sm-6" *ngIf="isPreregisterRole">
    <cdk-form-group>
      <label>SSN</label>
      <input
        type="text"
        cdkInput
        formControlName="sf_ssn"
        placeholder="000000000"
        maxlength="9"
        minlength="9"
        mask="XXXXXXXXX" />
      <cdk-error *ngIf="controlHasError(form, 'sf_ssn', 'required')"
        >This field is required</cdk-error
      >
    </cdk-form-group>
  </div>
  <div class="col-12">
    <cdk-form-group>
      <label>Email</label>
      <input
        type="email"
        cdkInput
        formControlName="sf_email"
        placeholder="mail@example.com"
        [readonly]="!isPreregisterRole"/>
      <span cdkSuffix>
        <span cdkIcon="outlined">mail</span>
      </span>
      <cdk-error *ngIf="controlHasError(form, 'sf_email', 'required')"
        >This field is required</cdk-error
      >
      <cdk-error
        *ngIf="
          controlHasError(form, 'sf_email', 'email') ||
          controlHasError(form, 'sf_email', 'pattern')
        "
        >Please insert a valid email</cdk-error
      >
    </cdk-form-group>
  </div>
  <div class="col-12" [ngClass]="{ 'col-sm-6': isPreregisterRole }">
    <cdk-form-group>
      <label>Phone number</label>
      <input
        type="tel"
        cdkInput
        formControlName="sf_phone"
        placeholder="(762) 123 4567"
        [defaultValue]="form.get('sf_phone').value ?? ''"
        mask="(XXX) XXX XXXX" />
      <span cdkSuffix>
        <span cdkIcon="outlined">phone</span>
      </span>
      <cdk-error *ngIf="controlHasError(form, 'sf_phone', 'required')"
        >This field is required</cdk-error
      >
      <cdk-error *ngIf="controlHasError(form, 'sf_phone', 'mask')"
        >Please insert a valid phone number</cdk-error
      >
    </cdk-form-group>
  </div>
  <div class="col-12 col-sm-6" *ngIf="isPreregisterRole">
    <cdk-form-group [refreshDomRequired]="!isFFMFieldRequiered">
      <label>FFM user ID</label>
      <input
        type="text"
        cdkInput
        formControlName="sf_ffm"
        placeholder="E.g username123" />
      <cdk-error *ngIf="controlHasError(form, 'sf_ffm', 'required')"
        >This field is required</cdk-error
      >
    </cdk-form-group>
  </div>
  <div class="d-flex justify-content-between pt-5">
    <a
      class="skip-home d-flex align-items-center"
      (click)="skipToHome()"
      *ngIf="isAsr"
      >Skip to Home</a
    >
    <button
      cdkButton
      type="submit"
      class="text-light"
      [disabled]="form.invalid">
      Next
    </button>
  </div>
</form>
