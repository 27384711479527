// / <reference types="@types/googlemaps" />
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import {AddressAutocompleteResponse} from '@app/models/address/address';

declare let google: any;

@Component({
  selector: 'app-address-autocomplete',
  templateUrl: './address-autocomplete.component.html',
  styleUrls: ['./address-autocomplete.component.scss'],
})
export class AddressAutocompleteComponent implements OnInit, AfterViewInit {
  @ViewChild('addressInput', {static: true})
    address: ElementRef<HTMLInputElement>;

  @Output() valueChange: EventEmitter<AddressAutocompleteResponse> =
    new EventEmitter();
  @Output() inputChange: EventEmitter<string> = new EventEmitter();
  @Output() listenInputChange: EventEmitter<string> = new EventEmitter();
  @Input() value: string;
  @Input() required = false;
  @Input() placeholder = '';

  constructor() {}

  ngOnInit(): void {
    this.onListenInputChange();
  }

  ngAfterViewInit(): void {
    this.setAutocomplete();
  }

  setAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(
      this.address.nativeElement,
      {
        componentRestrictions: {country: 'US'},
        types: ['address'],
      }
    );

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      const {address_components, formatted_address} = place;
      const data = {
        addressData: this.formatAddressComponents(address_components),
        address: formatted_address,
      };

      this.valueChange.emit(data);
    });
  }

  private formatAddressComponents(data) {
    let state: string; let county: string; let city: string; let zip_code: any;

    state = this.getComponent(data, 'administrative_area_level_1');
    county = this.getComponent(data, 'administrative_area_level_2');
    city = this.getComponent(data, 'locality');
    zip_code = this.getComponent(data, 'postal_code');

    return {state, county, city, zip_code};
  }

  private getComponent(
    addressComponents: {
      long_name: string;
      short_name: string;
      types: string[];
    }[],
    type: string
  ) {
    return addressComponents.find((c) => c.types.includes(type))?.long_name ?? '';
  }

  onListenInputChange() {
    this.address.nativeElement.addEventListener('input', (_) => {
      this.listenInputChange.emit(this.address.nativeElement.value);
    });
  }

  onChange(event: any) {
    this.inputChange.emit(event.target?.value);
  }
}
