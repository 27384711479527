<app-auth-layout hero="/assets/img/temp/auth.svg">
  <div
    class="d-flex flex-column justify-content-center align-items-center text-center">
    <p class="fs-3 title fw-bold lh-lg">Check your email</p>

    <p class="head-text fw-normal">
      We’ve sent you a code to your email. Please enter it to finish the
      security verification.
    </p>
    <form
      class="form-code d-flex flex-column text-center"
      [formGroup]="codeForm"
      (ngSubmit)="submit()">
      <div
        class="d-flex flex-row justify-content-center aling-items-center mb-3">
        <ng-container *ngFor="let i of [1, 2, 3, 4, 5, 6]">
          <cdk-form-group class="code code-margin">
            <input
              type="text"
              cdkInput
              inputrestriction="[0-9]"
              [formControlName]="'code-' + i"
              maxlength="1"
              [autofocus]="i === 1"
              (input)="nextFocus(i, $event.target.value)"
              (paste)="pasteVerificationCode($event)"
              (keydown)="onKeyPress(i, $event)"
              #code />
          </cdk-form-group>
        </ng-container>
      </div>
      <div class="d-flex align-items-center my-3">
        <cdk-checkbox [(checked)]="trustDevice" class="w-100"
          >&nbsp;Trust this device</cdk-checkbox
        >
      </div>
      <p class="text-danger error-message" *ngIf="message">{{ message }}</p>
      <p class="text-danger" *ngIf="error.status">{{ error.message }}</p>

      <div class="align-items-center row mt-4">
        <div class="col-12 col-md-6 order-md-2 order-sm-1">
          <button
            type="submit"
            cdkButton
            class="verify text-light w-100"
            [disabled]="codeForm.invalid || loading">
            Verify
          </button>
        </div>

        <div class="col-12 col-md-6 order-md-1 order-sm-2 my-2">
          <button
            type="button"
            cdkButton="outline-dark"
            (click)="router.navigate(['/login'])"
            class="cancel w-100">
            Cancel
          </button>
        </div>
      </div>

      <div class="d-flex flex-sm-column align-items-sm-center mt-4">
        <span><h6 class="color-gray">Don’t get a code?</h6></span>
        <span class="timeout-text"
          ><strong
            [class]="
              timeout === 0 && !loading ? 'disabled-action' : 'active-action'
            "
            >&nbsp;&nbsp;{{ displayTimeout }}&nbsp;&nbsp;</strong
          ></span
        >
        <span
          [class]="
            timeout === 0 && !loading ? 'active-action' : 'disabled-action'
          "
          (click)="sendVerificationCode()"
          ><h6>Click to resend</h6></span
        >
      </div>
    </form>
  </div>
</app-auth-layout>
