import { Type } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { StepTitle } from "@app/enums/step-title";

import { StepCommunicationPreferencesComponent } from "@app/pages/home/components/steppers/step-communication-preferences/step-communication-preferences.component";
import { StepSetPaymentInformationRequestComponent } from "@app/pages/home/components/steppers/step-set-payment-information-request/step-set-payment-information-request.component";
import { StepUploadPaymentDocumentComponent } from "@app/pages/home/components/steppers/step-upload-payment-document/step-upload-payment-document.component";

export interface SteperRegister {
  title: StepTitle | string;
  description: string;
  isShow: boolean;
  isCompleted: boolean;
  form: FormGroup;
  buttonText: string;
  component: Type<
    | StepCommunicationPreferencesComponent
    | StepSetPaymentInformationRequestComponent
    | StepUploadPaymentDocumentComponent
    | null
  >;
}
