import { FormGroupComponent } from "./form-group.component";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PrefixDirective } from "./prefix.directive";
import { SuffixDirective } from "./suffix.directive";
import { ErrorComponent } from "../error/error.component";
import { HintComponent } from "../hint/hint.component";
import { ZelleModule } from "@app/modules/@cdk/icon/zelle/zelle.module";

@NgModule({
  declarations: [
    FormGroupComponent,
    PrefixDirective,
    SuffixDirective,
    ErrorComponent,
    HintComponent,
  ],
  exports: [
    FormGroupComponent,
    PrefixDirective,
    SuffixDirective,
    ErrorComponent,
    HintComponent,
  ],
  imports: [CommonModule, ZelleModule],
})
export class FormGroupModule {}
