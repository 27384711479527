import {InputContainer} from './form-group/form-group.component';

import {
  ChangeDetectorRef,
  Directive,
  DoCheck,
  EmbeddedViewRef,
  Input,
  KeyValueDiffer,
  KeyValueDiffers,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {FormControl} from '@angular/forms';

@Directive({selector: '[cdkControlMessage]'})
export class ControlMessageDirective implements DoCheck {
  @Input('cdkControlMessage') error;
  // tslint:disable-next-line:no-input-rename
  @Input('cdkControlMessageOnlyTouched') onlyTouched = true;

  private differ: KeyValueDiffer<any, any>;
  embeddedView: EmbeddedViewRef<any>;

  constructor(
    private tpl: TemplateRef<any>,
    private vcr: ViewContainerRef,
    private inputContainer: InputContainer,
    private cd: ChangeDetectorRef,
    private renderer2: Renderer2,
    private _differs: KeyValueDiffers
  ) {
    inputContainer.formControl$.subscribe((control) => {
      if (!control) {
        return;
      }
      this.differ = this._differs.find(this.getState(control)).create();
    });
  }

  getState(control: FormControl) {
    return {
      touched: control.touched,
      errors: control.errors,
    };
  }

  get displayedError(): string {
    return this.inputContainer.displayErrorMessage;
  }

  set displayedError(str: string) {
    this.inputContainer.displayErrorMessage = str;
  }

  ngDoCheck() {
    if (!this.differ) {
      return;
    }
    if (this.displayedError && this.displayedError !== this.error) {
      return;
    }
    const changes = this.differ.diff(
      this.getState(this.inputContainer.formControl)
    );
    if (changes) {
      let touched;
      let hasError;

      changes.forEachItem(({key, currentValue, previousValue}) => {
        if (key === 'touched') {
          touched = currentValue;
        }
        if (key === 'errors') {
          hasError = currentValue && !!currentValue[this.error];
        }
      });

      if (
        !this.embeddedView &&
        ((this.onlyTouched && touched && hasError) ||
          (!this.onlyTouched && hasError))
      ) {
        this.displayedError = this.error;
        this.embeddedView = this.vcr.createEmbeddedView(this.tpl);
        this.addClass(this.embeddedView.rootNodes[0]);
        return;
      }

      if (this.embeddedView && touched && !hasError) {
        if (this.displayedError === this.error) {
          this.displayedError = undefined;
        }
        this.vcr.clear();
        this.embeddedView = undefined;
      }
    }
  }

  addClass(el: HTMLElement) {
    ['validation-feedback', 'text-danger', 'small'].map((clazz) =>
      this.renderer2.addClass(el, clazz)
    );
  }
}
