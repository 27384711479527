import {
  Directive,
  EmbeddedViewRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {MediaScreenService} from '@app/modules/media-screen/media-screen.service';
import {Subscription} from 'rxjs';

@Directive({selector: '[mediaScreenUp]'})
export class MediaScreenUpDirective implements OnDestroy, OnChanges {
  @Input() mediaScreenUp: string;
  private subs = new Subscription();

  private embedded: EmbeddedViewRef<any>;

  constructor(
    private tpl: TemplateRef<any>,
    private vcr: ViewContainerRef,
    private ms: MediaScreenService
  ) {
    this.subs.add(
      this.ms.current$.subscribe((subs) => {
        this.update();
      })
    );
  }

  ngOnChanges({mediaScreenUp}: SimpleChanges) {
    if (mediaScreenUp) {
      this.update();
    }
  }

  update() {
    if (
      this.ms.getBreakpointSize(this.mediaScreenUp) < this.ms.getScreenSize()
    ) {
      if (!this.embedded) {
        this.embedded = this.vcr.createEmbeddedView(this.tpl);
      }
    } else if (this.embedded) {
      this.embedded.destroy();
      this.embedded = undefined;
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    if (this.embedded) {
      this.embedded.destroy();
    }
  }
}
