import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import * as pbi from 'powerbi-client';
import {environment} from '../../../../environments/environment';
import {lastValueFrom} from 'rxjs';
import {finalize, retry} from 'rxjs/operators';
import {ActivatedRoute, Route, Router} from '@angular/router';
import { PowerbiService } from '@app/services/powerbi/powerbi.service';

@Component({
  selector: 'cdk-powerbi-report',
  templateUrl: './powerbi.component.html',
  styleUrls: ['./powerbi.component.scss'],
})
export class PowerbiComponent implements OnInit, OnChanges {
  private report: pbi.Embed;
  @ViewChild('reportContainer', {static: false})
  private reportContainer: ElementRef;

  @Input() token: string;
  @Input() report_id: string;

  loading = false;

  private _pbi = new pbi.service.Service(
    pbi.factories.hpmFactory,
    pbi.factories.wpmpFactory,
    pbi.factories.routerFactory
  );

  constructor(
    private _powerbi: PowerbiService,
    public _route: ActivatedRoute
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.token && this.report_id) {
      this.generateReport();
    }
  }

  async generateReport() {
    if (this.reportContainer) {
      this._pbi.reset(this.reportContainer.nativeElement);
    }

    this.showReport(this.token, this.report_id);
  }

  showReport(token: string, id: string) {
    const embedUrl = environment.powerBI.reportBaseURL;

    const settings: pbi.IEmbedSettings = {
      filterPaneEnabled: false,
      navContentPaneEnabled: false,
    };

    const config: pbi.IEmbedConfiguration = {
      accessToken: token,
      embedUrl,
      id,
      tokenType: pbi.models.TokenType.Embed,
      type: 'report',
      pageView: 'fitToWidth',
      filters: [],
      settings,
    };

    const reportContainer = this.reportContainer.nativeElement;
    this.report = this._pbi.embed(reportContainer, config);

    this.report.off('loaded');
    this.report.on('loaded', () => {});
    this.report.on('error', () => {});
  }
}
