<div class="notification-button">
  <button
    [cdkButton]="!mobile && 'dark'"
    [ngClass]="!mobile && 'dark'"
    class="menu-buttom"
    #toggleButton
    (click)="openNotifications ()">
    <span
      cdkIcon="rounded"
      [styleClass]="mobile ? 'icons_nav' : ''"
      >notifications</span
    >
  </button>
  <div
    class="notification-popup shadow"
    *popover="
      notificationsToggle;
      target: toggleButton;
      dir: 'ct';
      closed: closedPopup
    ">
    <app-menu></app-menu>
  </div>
  <div class="notifications-badge" *ngIf="notificationsCount > 0"></div>
</div>
