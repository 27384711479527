<div class="container px-0">
  <div class="card-image rounded"></div>
  <h4 class="text-celeste fw-semibold text-center mt-5 mb-3">
    {{ data.title }}
  </h4>
  <div [innerHTML]="sanitizeInnerHtml(data.content)"></div>
  <p class="text-center mb-5" *ngIf="data.filesHistory">
    For more information, please view
    <a
      href="javascript:void(0)"
      class="text-celeste fw-semibold"
      (click)="dialog.close(true)"
      >Uploaded files history
      <span cdkIcon="outlined" styleClass="position-absolute"
        >open_in_new</span
      ></a
    >
  </p>
  <div class="d-flex justify-content-center">
    <button cdkButton (click)="dialog.close()" class="bg-blue-400 btn-confirm">
      <span class="text-light">Ok</span>
    </button>
  </div>
</div>
