<div class="update-information">
  <app-auth-layout hero="/assets/img/temp/auth.svg">
    <span class="title-header-stepper text-center"
      >Update your information</span
    >
    <div class="stepper" [ngClass]="{ 'stepperPreRegister': isPreregisterRole }">
      <div
        class="stepper-action"
        [class.active]="configView.name === option.name"
        *ngFor="let option of getStepsOptions(); let i = index"
        (click)="loadComponentView(option.name, i)">
        <div class="step-icon">
          <span>{{ i + 1 }}</span>
        </div>
        <label class="tepper-action__title-stepper">{{ option.title }}</label>
      </div>
    </div>
    <ng-template [cdkPortalOutlet]="currentPortal"></ng-template>
  </app-auth-layout>
</div>
