import { Injectable } from "@angular/core";
import { HttpService } from "../http/http.service";
import { filter, map, Observable, tap } from "rxjs";
import { FormGroup } from "@angular/forms";
import { LocalStorageService } from "../storage/local-storage.service";
import { CommissionAdvanceRequestBase, ContractSign } from "@app/models";
import { RoleEnum } from "@app/enums/role";
import { IDataTableHttpResponse } from "@app/shared";
import * as qs from "qs";
import { DataTableService } from "@app/services/data-table/data-table.service";
import { NewAgentResponse } from "@app/models/new-agent-report/new-agent-report";
import { ResponseAPI } from "@app/models/response-api/response-api";

@Injectable({
  providedIn: "root",
})
export class ContractsService extends DataTableService {
  extraArgumentsSign: Record<string, any>;

  public formContract: FormGroup;
  private readonly _contractUrl = "contracts";
  private readonly _profileLeadUrl = "profile-lead";

  constructor(
    protected http: HttpService,
    private _localStorage: LocalStorageService
  ) {
    super(http);
  }

  getContractByRole(payload: CommissionAdvanceRequestBase, role: string) {
    const url = this._getContractUrl(role);

    return this.http.postModule(`${url}`, payload).pipe(
      tap(res => {
        this._localStorage.setItem("contracts", [res.data.contract]);
      })
    );
  }

  private _signContractByRole(payload: Record<string, any>, role = "") {
    const url = this._getContractUrl(role);
    return this.http.postModule(`${url}/sign`, payload);
  }

  private _getContractUrl(role = ""): string {
    let url = `${this._contractUrl}/custom`;

    if (role === RoleEnum.AGENT_OF_AGENCY) {
      url += "/agent-of-agency-commission-in-advance";
    } else {
      url += "/direct-agent-commission-in-advance";
    }

    return url;
  }

  signContract(fieldsContract: ContractSign, role = "", url = "") {
    const extraArguments = this.extraArgumentsSign;

    const payload: Record<string, any> = {
      ...fieldsContract,
      ...extraArguments,
    };

    if (url === "/payments/commission-advance") {
      return this._signContractByRole(payload, role);
    }

    return this.http.postModule(
      `user/${this._contractUrl}/${fieldsContract.id_contract}/sign`,
      payload
    );
  }

  getContract(slug: string) {
    return this.http.getModule(`${this._contractUrl}/terms/${slug}`);
  }

  getModuleContract(module: string, type = "terms"): Observable<any> {
    return this.http.getModule(
      `${this._contractUrl}/${type}/module/${module}/pending`
    );
  }

  getResumeDashboard(rol: string) {
    return this.http
      .getModule(`${this._contractUrl}/resume-dashborad/${rol}`)
      .pipe(filter(data => !!data));
  }

  getNewAgentReport(params: any = {}): Observable<IDataTableHttpResponse> {
    const url = `${this._profileLeadUrl}/get-leads?` + qs.stringify(params);
    return this.http
      .getModule<ResponseAPI<NewAgentResponse>>(url)
      .pipe(map(res => res.data.leads));
  }

  async getNewAgentDataDownload(params: any = {}): Promise<boolean> {
    const url =
      `${this._profileLeadUrl}/download-leads-report?` + qs.stringify(params);
    return await this.downloadExcel(url);
  }
}
