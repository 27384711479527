import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {VerificationCodeRoutingModule} from './verification-code-routing.module';
import {VerificationCodeComponent} from './verification-code.component';
import {AuthLayoutModule} from '@app/layout/auth-layout/auth-layout.module';
import {InputModule} from '@app/modules/@cdk/forms/input/input.module';
import {FormGroupModule} from '@app/modules/@cdk/forms/input/form-group/form-group.module';
import {CheckboxModule} from '@app/modules/@cdk/forms/checkbox/checkbox.module';
import {ButtonModule} from '@app/modules/@cdk/forms/button/button.module';
import {IconModule} from '@app/modules/@cdk/icon/icon.module';
import {ReactiveFormsModule} from '@angular/forms';
import {NgxMaskModule} from 'ngx-mask';


@NgModule({
  declarations: [VerificationCodeComponent],
  imports: [
    CommonModule,
    VerificationCodeRoutingModule,
    AuthLayoutModule,
    InputModule,
    FormGroupModule,
    CheckboxModule,
    ButtonModule,
    IconModule,
    ReactiveFormsModule,
    NgxMaskModule,
  ],
  exports: [VerificationCodeComponent],
})
export class VerificationCodeModule { }
