import { AsrModule, AsrType, Module, Role } from '@app/models';

export function joinAsrTypesModules(types: AsrType[]): AsrModule[] {
  return types
    .reduce((acc: AsrModule[], current: AsrType) => [...acc, ...current.modules], [])
    .reduce((acc: AsrModule[], current: AsrModule) => {
      const foundItem = acc.find((module) => module.id === current.id);
      if (foundItem) {
        foundItem.permissions = foundItem.permissions ?
          [...foundItem.permissions, ...current.permissions.filter((currentPermission) => !foundItem.permissions.includes(currentPermission))] :
          [];
      } else {
        acc.push(current);
      }
      return acc;
    }, []);
}

export function joinUserModulesWithAsr(userRoles: Role[], asrModules: AsrModule[]): Role[] {
  return userRoles.map((role) => {
    role.asrPermissions = [];
    role.modules = role.modules.reduce((acc: Module[], current: Module) => {
      const foundAsrModule = asrModules.find((asrModule) => asrModule.id === current.id);
      if (foundAsrModule) {
        current.permissions = foundAsrModule.permissions;
        role.asrPermissions = [...role.asrPermissions, ...mapAsrModuleToPermission(foundAsrModule)];
        acc = [...acc, current];
      }
      return acc;
    }, []);
    return role;
  });
}

export function mapAsrModuleToPermission(asrModule: AsrModule): string[] {
  return asrModule.permissions.map((permission) => `${asrModule.slug ?? ''}:${permission}`);
}
