import {
  Component,
  forwardRef,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Input,
  SimpleChanges,
  OnChanges,
} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { BehaviorSubject, Subscription, Observable, fromEvent } from "rxjs";

export abstract class InputContainer {
  private readonly formControlSubject = new BehaviorSubject<FormControl>(null);
  readonly formControl$ = this.formControlSubject.asObservable();

  displayErrorMessage: string;

  get formControl(): FormControl {
    return this.formControlSubject.getValue();
  }

  setFormControl(formControl: FormControl) {
    if (!formControl) {
      return;
    }
    const original = formControl.markAsTouched;
    formControl.markAsTouched = function () {
      formControl.patchValue(formControl.value, {
        emitEvent: false,
        emitModelToViewChange: false,
        emitViewToModelChange: false,
        onlySelf: false,
      });
      original.apply(formControl);
    };
    this.formControlSubject.next(formControl);
  }

  destroy() {
    this.formControlSubject.complete();
  }
}

@Component({
  host: { class: "d-block form-group cdk-form" },
  selector: "cdk-form-group",
  template: `
    <ng-content></ng-content>
    <div
      class="form-group-content"
      [ngClass]="componentClass"
      [class.form-group-content-invalid]="displayErrors"
      [class.fg-textarea]="isTextArea"
      [class.disabled]="isDisabled"
      #inputContainer>
      <div class="zelle_banner text-white" *ngIf="isZelleInput">
        Transfer to my <zelle-icon></zelle-icon>
      </div>
      <ng-content select="[cdkPrefix]"></ng-content>
      <ng-content select="[cdkInput]"></ng-content>
      <ng-content select="[cdkInputContainer]"></ng-content>
      <ng-content select="[cdkSuffix]"></ng-content>
    </div>
    <div class="feedback-container" *ngIf="displayErrors">
      <ng-content select="cdk-error"></ng-content>
    </div>
    <ng-content select="cdk-hint" *ngIf="!displayErrors"></ng-content>
  `,
  styles: [
    `
      .zelle_banner {
        display: flex;
        width: 350px;
        height: 41px;
        padding: 2px 6px 2px 8px;
        justify-content: center;
        align-items: center;
        border-radius: 8px 0px 0px 0px;
        background: #9747ff;
        margin-left: -8px;
      }

      .disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    `,
  ],
  providers: [
    {
      provide: InputContainer,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => FormGroupComponent),
    },
  ],
})
export class FormGroupComponent
  extends InputContainer
  implements OnDestroy, AfterViewInit, OnChanges
{
  @Input() isZelleInput: boolean;
  @Input() isDisabled = false;
  @Input() noContent = false;
  @Input() refreshDomRequired = false;
  @ViewChild("inputContainer", { static: true })
  private container: ElementRef<HTMLElement>;

  htmlInput: HTMLInputElement;

  private _inputSubscription: Subscription;
  private _input$: Observable<Event>;

  required = false;
  isTextArea = false;

  constructor(private el: ElementRef<HTMLElement>) {
    super();
  }

  ngAfterViewInit() {
    this.htmlInput = this.container?.nativeElement.querySelector("input");
    this.isTextArea = !!this.container?.nativeElement.querySelector("textarea");

    this.configInputRequired();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["refreshDomRequired"]) {
      this.configInputRequired();
    }
  }

  configInputRequired(): void {
    if (this.htmlInput) {
      this.required =
        this.htmlInput.required ||
        this.formControl?.hasValidator(Validators.required);

      this.required
        ? this.el.nativeElement.classList.add("form-group-required")
        : this.el.nativeElement.classList.remove("form-group-required");

      this.listenInput(this.htmlInput);
    }
  }

  get displayErrors(): boolean {
    return (
      !!this.formControl?.errors &&
      (this.formControl.touched || !this.formControl.pristine)
    );
  }

  get componentClass(): Record<string, boolean> {
    return {
      "form-group-content-invalid": this.displayErrors,
      "fg-textarea": this.isTextArea,
      disabled: this.isDisabled,
      "p-2 d-flex": !this.noContent,
    };
  }

  ngOnDestroy(): void {
    super.destroy();
    this._inputSubscription?.unsubscribe();
  }

  listenInput(htmlInput: HTMLInputElement): void {
    const inputValue = htmlInput.value;
    this.changeStyles(inputValue);
    this._input$ = fromEvent(htmlInput, "input");

    this._inputSubscription = this._input$.subscribe(event => {
      const inputValue = (event.target as HTMLInputElement).value;
      this.changeStyles(inputValue);
    });

    this.formControl?.valueChanges.subscribe((value: string) => {
      this.changeStyles(value ?? "");
    });
  }

  changeStyles(inputValue: string) {
    if (inputValue.toString().length > 0) {
      this.el.nativeElement.classList.add("form-group-fill");
    } else {
      this.el.nativeElement.classList.remove("form-group-fill");
    }
  }
}
