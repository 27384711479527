export interface ASR {
  id: string;
  full_name: string;
  email_account: string;
  user: string;
  npn_account: string;
}

export interface AsrModule {
  id: number;
  name: string;
  title: string;
  route: string;
  slug: string;
  permissions: string[];
}

export interface AsrType {
  id: number;
  name: string;
  modules: AsrModule[];
}

export interface ASRC {
  npn: string;
  name: string;
  email: string;
}
