import {Inject, Injectable, NgZone} from '@angular/core';
import {BREAK_POINTS_TOKEN} from '@app/modules/media-screen/media-screen.module';
import {BehaviorSubject, interval} from 'rxjs';
import {debounce, filter} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class MediaScreenService {
  private breakpoints = new Map<string, number>();
  private current = 'sm';
  private currentSubject = new BehaviorSubject<string>(null);
  public readonly current$ = this.currentSubject.pipe(
    debounce(() => interval(10)),
    filter((e) => !!e)
  );

  constructor(
    @Inject(BREAK_POINTS_TOKEN) breakpoints: string[],
    private zone: NgZone
  ) {
    const body: HTMLBodyElement = document.querySelector('body');
    const styles = getComputedStyle(body);

    const normalize = (value: string): number =>
      parseInt(value.trim().replace('px', ''), 10);

    breakpoints.forEach((size) => {
      this.breakpoints.set(
        size,
        normalize(styles.getPropertyValue(`--breakpoint-${size}`))
      );
    });

    this.zone.runOutsideAngular(() => {
      const emit = (name) =>
        this.zone.run(() => {
          this.currentSubject.next(this.getBreakpoint());
          if (this.getBreakpointSize(name) < this.getScreenSize()) {
            this.current = name;
          }
        });

      Array.from(this.breakpoints.entries()).forEach(([name, size]) => {
        const media = matchMedia(`(min-width: ${size}px)`);
        media.onchange = () => emit(name);
        if (media.matches) {
          emit(name);
        }
      });
    });
  }

  getCurrentBreakpoint() {
    return this.current;
  }

  getScreenSize(): number {
    return window.innerWidth;
  }

  getBreakpointSize(str: string): number {
    return this.breakpoints.get(str);
  }

  getBreakpoints() {
    return this.breakpoints;
  }

  downTo(breakpoint: string) {
    return this.getScreenSize() < this.getBreakpointSize(breakpoint);
  }

  getBreakpoint(): string {
    const size = this.getScreenSize();

    if (size < 576) {
      return 'sx';
    } else if (size >= 576 && size < 768) {
      return 'sm';
    } else if (size >= 768 && size < 992) {
      return 'md';
    } else if (size >= 992 && size < 1200) {
      return 'lg';
    } else {
      return 'xl';
    }
  }
}
