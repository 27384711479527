<div *ngIf="modules.length" class="d-flex flex-column justify-content-start">
  <div
    *ngIf="currentModule"
    class="current-page d-flex flex-row justify-content-start align-items-center">
    <span [cdkIcon]="fill" styleClass="route-icon">{{ currentModule.icon }}</span>
    <p class="route-title">{{ currentModule.title }}</p>
  </div>
  <div class="d-flex flex-row mt-1 breadcrumbs">
    <ng-container *ngFor="let module of modules">
      <p class="breadcrumb" (click)="navigate(module.route)">
        {{ module.name }}
      </p>
      <span class="separator">&nbsp;/&nbsp;</span>
    </ng-container>
  </div>
</div>
