export interface CasesReport {
  Id: string;
  CaseNumber: string;
  Status: string;
  Interaction_Reason__c: string;
  Dispositions__c: string;
  CaseResponse__c: string;
  Description: string;
  CaseQualification__c: string;
  CreatedDate: string;
  statusLang: string;
  canBeReopened: boolean;
  isQualifiable: boolean;
  Attachment1__c: string;
  Attachment2__c: string;
  Attachment3__c: string;
  Attachment4__c: string;
  detailVisible: boolean;
}
