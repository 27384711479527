import {Component} from '@angular/core';
import {Router} from '@angular/router';
import { UiService } from '@app/services/cdk/ui.service';
import { LocalStorageService } from '@app/services/storage/local-storage.service';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-root',
  template: ` <router-outlet></router-outlet> `,
  styleUrls: ['./root.component.scss'],
})
export class RootComponent {
  public version = environment.version;

  constructor(
    private _ui: UiService,
    private _storage:LocalStorageService,
    private _router: Router,

  ) {
    this._ui.loadSiteConfig().subscribe((res) => {
      const {setup, internal_company_id, internal_company_name} = res;

      this._ui.setTitle(setup.title);
      this._ui.setAppLogo(setup.logo);
      this._ui.setAppSetup(setup);
      this._ui.setInternalCompany({internal_company_id, internal_company_name})

      const favicon: HTMLLinkElement = document.querySelector('#favIcon');
      favicon.href = `assets/favicon/${setup.favicon}`;
    });

    const version = this._storage.getItem('version');
    if (!version || version != this.version) {
      localStorage.clear();
      this._storage.setItem('version', this.version);
      this._router.navigateByUrl('login');
    }
  }
}
