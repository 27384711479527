export interface AuditObject{
  user_id?: string | number ;
  event?: string;
  auditable_type?: string;
  old_values?: string;
  new_values?: string;
  url?: string;
  ip_address?: string;
  salesforce_response?: string;
  tags?: string;
  created_at?: Date | string;
  updated_at?: Date | string;
}

export interface Events{
 readonly created: string;
 readonly updated: string;
 readonly deleted: string;
 readonly cloned: string;
}

export interface Auditable_type{
  readonly medicare: string;
  readonly supplementary: string;
  readonly life: string;
  readonly referrals: string;
  readonly reconciliations: string;
  readonly aca: string;
  readonly agreement: string;
  readonly advance_comission: string;
}
