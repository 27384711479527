<div
  class="select-input-container d-flex align-items-center"
  [class.justify-content-center]="full" #selectInput
>
  <span cdkIcon="outlined" styleClass="me-1" *ngIf="prependIcon">tune</span>
  <i class="ic-search me-3 text-emphasis-medium" *ngIf="searchable"></i>
  <div
    class="container-div text-nowrap text-truncate"
    [class.flex-grow-1]="!full"
  >
    <p
      *ngIf="showSelected ? (!value || !value?.length) &&
      (multi ? !selectedOption?.length : !selectedOption) : true
      "
      class="select-placeholder text-nowrap text-truncate"
      [style.font-size.px]="placeholderSize"
      [style.color]="placeholderColor"
    >
      {{ placeholder }}
    </p>
    
    <ng-container *ngIf="selectedOption && showSelected">
      <ng-container
        [ngTemplateOutlet]="selectedTmpl || itemTmpl || defaultItemTmpl"
        [ngTemplateOutletContext]="{
          $implicit: multi ? selectedOption[0] : selectedOption
        }"
      ></ng-container>
    </ng-container>
  </div>

  <span *ngIf="multi && selectedOption?.length > 1" class="ms-1">
    (+{{ selectedOption.length - 1 }})
  </span>
  <span cdkIcon="outlined">{{ icon }}</span>
</div>

<div
  scrollSpy
  (canLoadMoreEvent)="!searchString?.length ? nextBatch.emit() : ''"
  [perfectScrollbar]="{ suppressScrollX: true, minScrollbarLength: 150 }"
  *popover="
    showPopover;
    target: elementRef.nativeElement;
    closed: close;
    height: optionsHeight;
    dir: direction
  "
  [ngClass]="optionsCustomClass ?? ''"
  class="options-container dropdown shadow position-relative"
  [style]="'min-width: ' + selectorWidth + 'px'"
  (wheel)="$event.stopPropagation()"
  (scroll)="$event.stopPropagation()"
  (window:keydown.arrowDown)="focusResult(1)"
  (window:keydown.arrowUp)="focusResult(-1)"
  (window:keydown.enter)="selectFocusResult()"
> 
  <div
    class="dropdown-menu w-100 mw-100"
    [class.multiple]="multi"
    [ngStyle]="{ 'padding-top.rem': stylePaddingTop }"
    style="z-index: unset"
  >
    <ng-container *ngIf="searchable">
      <cdk-form-group class="search-input px-2">
        <input cdkInput type="text" [placeholder]="placeholder ?? 'Search an option'" [(ngModel)]="searchString"
          (ngModelChange)="search$.next($event)">
        <span cdkSuffix> 
          <span cdkIcon="sharp">search</span>
        </span>
      </cdk-form-group>
    </ng-container>
    
    <ng-container *ngIf="popupHeaderTmpl">
      <div (click)="close()">
        <ng-container *ngTemplateOutlet="popupHeaderTmpl"></ng-container>
      </div>
    </ng-container>

    <div
      class="d-flex align-items-center ms-3 mb-3 py-3 border-bottom border-divider cursor-pointer"
      *ngIf="selectAll && multi"
      (click)="selectAllOptions($event)"
    >
      <cdk-checkbox
        [indeterminate]="
          value.length && (results$ | async).length !== value.length
        "
        [checked]="(results$ | async).length === value.length"
        (click)="selectAllOptions($event)"
      >
        <span class="text-emphasis-high body-2 ms-3">
          {{
            (results$ | async).length === value.length
              ? "Deselect All"
              : "Select All"
          }}
        </span>
      </cdk-checkbox>
    </div>

    <ng-container *ngFor="let result of results$ | async; let index = index">
      <ng-container *ngIf="result.option === 'hr'; else itemRenderTemplate">
        <hr />
      </ng-container>

      <ng-template #itemRenderTemplate>
        <div
          class="dropdown-item cursor-pointer"
          [ngClass]="[result.option === selectedOption ? selectedClass??'': '', dropdownItemClass??'']"
          [ngStyle]="{ 'margin-bottom.rem': styleMarginBottom }"
          [class.focus]="
            result.option === selectedOption || index === resultFocusIndex
          "
          (click)="select(result)"
        >
          <ng-container *ngIf="!multi; else itemMultiTmpl">
            <ng-container
              *ngTemplateOutlet="
                itemTmpl || defaultItemTmpl;
                context: { $implicit: result.option }
              "
            ></ng-container>
          </ng-container>

          <ng-template #itemMultiTmpl>
            <div class="d-flex align-items-center justify-content-start">
              <cdk-checkbox
                class="user-select-none w-100"
                (click)="select(result); $event.stopPropagation()"
                [checked]="checkedItemsMap[result.$$track]"
              >
                <div class="d-flex align-items-center ms-3 w-100">
                  <ng-container
                    *ngTemplateOutlet="
                      itemTmpl || defaultItemTmpl;
                      context: { $implicit: result.option }
                    "
                  ></ng-container>
                </div>
              </cdk-checkbox>
            </div>
          </ng-template>
        </div>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="popupFooterTmpl">
      <ng-container *ngTemplateOutlet="popupFooterTmpl"></ng-container>
    </ng-container>
  </div>
</div>
<ng-template #defaultItemTmpl let-item>
  {{ item }}
</ng-template>
