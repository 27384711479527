import { Component, Input } from '@angular/core';
import { Notification } from '../../interfaces';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent {
  @Input() notification!: Notification;

  constructor() {
    // not todo
  }
}
