import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { UsersService } from "@app/services/users/users.service";
import { catchError, finalize } from "rxjs/operators";
import { Subscription, throwError } from "rxjs";
import { UiService } from "@app/services/cdk/ui.service";
import { UserData } from "@app/models/users/user.model";
import { UserRole } from "@app/enums/user-role";
import { LocalStorageService } from "@app/services/storage/local-storage.service";

@Component({
  selector: "app-password",
  templateUrl: "./password.component.html",
  styleUrls: ["./password.component.scss"],
})
export class PasswordComponent implements OnInit, OnDestroy {
  private subscriptions: Record<string, Subscription> = {};
  form: FormGroup | Record<string, any>;

  loading = false;
  message: string;

  private _isPreregisterRole = false;

  public validationForm = true;

  constructor(
    private _userService: UsersService,
    private router: Router,
    private ui: UiService,
    private _localStorageService: LocalStorageService
  ) {
    this.form = this._userService.updateStep3;
  }

  ngOnInit(): void {
    this._getUserRole();
  }

  ngOnDestroy(): void {
    Object.values(this.subscriptions).forEach(value => value.unsubscribe());
  }

  private _getUserRole(): void {
    this.subscriptions["userRole"] = this._userService.userRole$.subscribe(
      role => {
        this._isPreregisterRole = role?.name === UserRole.PREREGISTER;
      }
    );
  }

  goBack(): void {
    this.saveInformation();

    const isTheSameAddress =
      this._userService.updateStep2.get("sf_same_mailing").value;

    if (!isTheSameAddress && this._isPreregisterRole) {
      this._userService.updateStep$.next(2);
      return;
    }

    this._userService.updateStep$.next(1);
  }

  saveInformation() {
    const user = this._userService.getUser();
    const token = this._userService.loadUserToken();
    const menu = this._userService.getMenu();

    const dataFrom = this.form.getRawValue();

    const userData: UserData = { menu, token, user: { ...user, ...dataFrom } };

    this._userService.setCurrentUser(userData);
  }

  submit() {
    if (this.form.invalid) {
      return;
    }
    this.message = "";
    this.loading = true;
    this.ui.startLoading();
    this._userService
      .updateData()
      .pipe(
        catchError(err => {
          this.message = "There has been an error";
          return throwError(() => new Error(this.message));
        }),
        finalize(() => {
          this.loading = false;
          this.ui.stopLoading();
        })
      )
      .subscribe(res => {
        this.router.navigate(["/home"]);
      });
  }
}
