import { Component, OnInit, Input } from "@angular/core";
import { UiService } from "@app/services/cdk/ui.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-auth-layout",
  templateUrl: "./auth-layout.component.html",
  styleUrls: ["./auth-layout.component.scss"],
})
export class AuthLayoutComponent implements OnInit {
  @Input() hero: string;
  @Input() signUp = false;

  appLogo: string;
  signUpUrl: string = environment.signUpUrl;

  constructor(private _ui: UiService) {
    this._ui.getAppLogo$().subscribe(logo => {
      this.appLogo = logo;
    });
  }

  ngOnInit(): void {
  }
}
