import { Injectable } from '@angular/core';
import { PersonalInformationComponent } from '../components/personal-information/personal-information.component';
import { AddressComponent } from '../components/address/address.component';
import { HomeAddressComponent } from '../components/home-address/home-address.component';
import { PasswordComponent } from '../components/password/password.component';
import { ConfigView } from '@app/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { UsersService } from '@app/services/users/users.service';

@Injectable({ providedIn: 'root' })
export class StepService {
  private _steps: { [key: string]: ConfigView<any> } = {
    personalInfo: {
      title: 'Personal Information',
      name: 'personalInfo',
      component: PersonalInformationComponent,
      formGroup: this._userService.updateStep1,
    },
    address: {
      title: 'Address',
      name: 'address',
      component: AddressComponent,
      formGroup: this._userService.updateStep2,
    },
    homeAddress: {
      title: 'Home Address',
      name: 'homeAddress',
      component: HomeAddressComponent,
      formGroup: this._userService.updateStep2HomeAddress,
    },
    password: {
      title: 'Reset your password',
      name: 'password',
      component: PasswordComponent,
      formGroup: this._userService.updateStep3,
    },
  };

  private _stepsSubject = new BehaviorSubject<{
    [key: string]: ConfigView<any>;
  }>(this._steps);

  constructor(private _userService: UsersService) {}

  getSteps$(): Observable<{ [key: string]: ConfigView<any> }> {
    return this._stepsSubject.asObservable();
  }

  get steps() {
    return this._steps;
  }

  set steps(steps: { [key: string]: ConfigView<any> }) {
    this._steps = steps;
    this._stepsSubject.next(this._steps);
  }
}
