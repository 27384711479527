export interface Disposition {
  disposition: DispositionElement;
}

export interface DispositionElement {
  id: number;
  name: string;
  sf_value: string;
  active: boolean;
  parent_id?: number;
  picklist_id: number;
  created_at: Date;
  updated_at?: Date;
  deleted_at?: Date;
  children: DispositionElement[];
}
