import {Component, OnInit} from '@angular/core';
import {humanizeSeconds} from '@app/utils/humanizeSeconds';
import {Router} from '@angular/router';

@Component({
  selector: 'app-reset-confirmation',
  templateUrl: './reset-confirmation.component.html',
  styleUrls: ['./reset-confirmation.component.scss'],
})
export class ResetConfirmationComponent implements OnInit {
  timeout = 10000;
  displayTimeout: string;

  constructor(private _router: Router) { }

  ngOnInit(): void {
    this.startTimeout();
  }

  startTimeout() {
    const interval = setInterval(() => {
      this.timeout -= 1000;
      this.displayTimeout = humanizeSeconds(Math.round(this.timeout / 1000), 'ss');

      if (this.timeout === 0) {
        clearInterval(interval);
        this._router.navigate(['/login']);
      }
    }, 1000);
    this.displayTimeout = '';
  }
}
