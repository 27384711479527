import { Module, ModuleStatusEnum } from './module.model';

export interface MenuRole {
  id: number;
  name: string;
  title: string;
  order: number;
  modules: MenuGroup[];
}

export interface MenuGroup {
  id: number;
  name: string;
  title: string;
  icon: string;
  route: null | string;
  preview: null;
  status: ModuleStatusEnum;
  order: number;
  parent_id: number | null;
  permissions: any[];
  items?: Module[];
}
