import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { UsersService } from "@app/services/users/users.service";
import { FormGroup, Validators } from "@angular/forms";
import { ValidationService } from "@app/services/validation/validation.service";
import { DatePipe } from "@angular/common";
import { Subscription } from "rxjs";
import { UserData } from "@app/models/users/user.model";
import { Router } from "@angular/router";
import { Asr } from "@app/enums/localstorage/asr";
import { UserRole } from "@app/enums/user-role";
import { LocalStorageService } from "@app/services/storage/local-storage.service";
import { AsrService } from "@app/services/asr/asr.service";

@Component({
  selector: "app-personal-information",
  templateUrl: "./personal-information.component.html",
  styleUrls: ["./personal-information.component.scss"],
  providers: [DatePipe],
})
export class PersonalInformationComponent implements OnInit {
  form: FormGroup;
  controlHasError = this.validations.controlHasError;
  minDate: Date;
  maxDate: Date;

  languages = [
    {
      label: "English",
      value: "en",
    },
  ];

  defaultLang: string;
  subscription: Subscription;

  private _isPreregisterRole = false;

  isFFMFieldRequiered = true;

  isAsr = false;

  constructor(
    private users: UsersService,
    private validations: ValidationService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private asrService: AsrService,
    private _cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this._getUpdateStepForm();
    this._validateIfIsASR();
    this._getUserAndLoadInitValues();
    this._setupFormByUserRole();
    this._getMaxAndMinDate();
    this._cd.detectChanges();
  }

  private _validateIfIsASR(): void {
    this.isAsr = this.localStorageService.getItem(Asr.ASR_STATUS);

    if (this.isAsr) {
      this.users.fetchUser().subscribe(({ user }) => {
        this.asrService.setUpAsr(user.id);
        this.asrService.getAsr().subscribe((userAsr) => {
          this.localStorageService.setItem(
            "currentRole",
            userAsr.data.menu.roles[0]
          );
          const role = this.users.getRoles()[0];
          this.users.setCurrentRole(role);
        });
      });
    } else {
      this.localStorageService.getItem(Asr.ASR_STATUS);
      this.users.setActiveAsr(false);
    }
  }

  private _getUpdateStepForm(): void {
    this.form = this.users.updateStep1;
  }

  private _getUserAndLoadInitValues(): void {
    const user = this.users.getUser();
    const role = this.localStorageService.getItem("roles")[0];

    this._isPreregisterRole = role?.name === UserRole.PREREGISTER;
    if (user) this.users.loadInitValues(this.form, user);
    this._cd.detectChanges();
  }

  private _setupFormByUserRole(): void {
    if (this._isPreregisterRole) {
      this._validateIfFFMFieldIsRequired();
      return;
    }

    this.form.get("sf_ffm").clearValidators();
    this.form.get("sf_ssn").clearValidators();
    this.form.get("locale").setValidators([Validators.required]);
  }

  private _validateIfFFMFieldIsRequired(): void {
    this.users.fetchUser().subscribe(({ user }) => {
      if (!user.sf_interested?.toLowerCase().toString().includes("aca")) {
        this.isFFMFieldRequiered = false;
        this.form.get("sf_ffm").clearValidators();
      }
    });
  }

  private _getMaxAndMinDate(): void {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const currentDay = new Date().getDate();

    this.minDate = new Date(currentYear - 18, currentMonth, currentDay);
    this.maxDate = new Date(currentYear - 120, currentMonth, currentDay);
  }

  selectLanguage(data: Record<string, any>) {
    // To Do
    // if (!data) return;
    // const { value } = data;
    // this.form.get('locale').setValue(value);
  }

  next() {
    this.saveInformation();
    this.users.updateStep$.next(1);
  }

  saveInformation() {
    const user = this.users.getUser();
    const token = this.users.loadUserToken();
    const menu = this.users.getMenu();

    const dataFrom = { ...this.form.getRawValue() };

    const userData: UserData = { menu, token, user: { ...user, ...dataFrom } };
    this.users.setCurrentUser(userData);
  }

  skipToHome() {
    if (this.isAsr) {
      this.localStorageService.setItem(Asr.SKIP_ASR, true);
      this.router.navigate(["/home"]);
    }
  }

  get isPreregisterRole(): boolean {
    return this._isPreregisterRole;
  }
}
