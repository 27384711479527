import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { LocalStorageService } from "@app/services/storage/local-storage.service";
import { UsersService } from "@app/services/users/users.service";
import { map } from "rxjs/operators";
import { Asr } from "@app/enums/localstorage/asr";

@Injectable({
  providedIn: "root",
})
export class LoginGuard implements CanActivate {
  constructor(
    private userService: UsersService,
    private localStorageService: LocalStorageService,
    private router: Router
  ) {
    // not todo
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.userService.getToken()) {
      return this.userService.fetchUser().pipe(
        map(data => {
          const {
            user,
            contracts,
            menu: { asrTypes, roles },
          } = data;

          this.localStorageService.setItem(
            "defaultRoute",
            !!asrTypes.length ? "/asr" : "/home"
          );

          const skipAsr = this.localStorageService.getItem(Asr.SKIP_ASR);
          const skipAsrContract = this.localStorageService.getItem(
            Asr.SKIP_CONTRACT
          );

          if (!user.update_data && !skipAsr) {
            this.router.navigate(["/update-info"]);
            return false;
          }

          if (contracts.length > 0 && !skipAsrContract) {
            this.router.navigate(["/agreements"]);
            return false;
          }

          if (
            !user.default_role_id &&
            roles.filter(role => role.name !== "ASR").length > 0
          ) {
            this.router.navigate(["/welcome"]);
            return false;
          }

          return true;
        })
      );
    }

    if (!this.userService.hasStorageUser()) {
      const path = window.location.pathname;

      if (!path || path === "/login") {
        return this.router.parseUrl("/login");
      }
      return this.router.parseUrl(`/login`);
    }
    return true;
  }
}
