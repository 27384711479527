import {
  Directive,
  OnChanges,
  Input,
  SimpleChanges,
  ElementRef,
} from '@angular/core';

export enum IconType {
  SHARP = 'sharp',
  ROUNDED = 'rounded',
  OUTLINED = 'outlined',
}

@Directive({
  selector: '[cdkIcon]',
  host: {class: 'material-symbols-outlined'},
})
export class CdkIconDirective implements OnChanges {
  @Input('cdkIcon') type: IconType | string = IconType.OUTLINED;
  @Input() fill = false;
  @Input() styleClass: string;

  constructor(private _el: ElementRef<HTMLElement>) {}

  ngOnChanges({ type, styleClass }: SimpleChanges) {
    this._el.nativeElement.className = `material-symbols-${
      this.type ?? 'outlined'
    }`;

    this.fill ?
      this._el.nativeElement.classList.add('filled') :
      this._el.nativeElement.classList.remove('filled');

    this._el.nativeElement.className = `${this._el.nativeElement.className} ${
      this.styleClass ?? ''
    }`;
  }
}
