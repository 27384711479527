export interface NewTicket {
  disposition: string;
  subdisposition: string;
  additional_information: string;
  attachments: Attachment[];
}

export interface Attachment {
  url: string;
}
