import { Directive, OnInit, Input, ElementRef } from '@angular/core';
import { UsersService } from '@app/services/users/users.service';

@Directive({
  selector: '[disableDirective]',
})
export class DisableDirective implements OnInit {
  private permissionAccepted = '';

  @Input()
  set disableDirective(permission: string) {
    this.permissionAccepted = permission;
  }

  constructor(
    private eleRef: ElementRef,
    private _user: UsersService
  ) { }

  ngOnInit(): void {
    if (this._user.getActiveAsr()) this.checkPermissions(this._user.getRole().asrPermissions);
  }

  checkPermissions(userPermissions: string[] | undefined): void {
    if (this.permissionAccepted && !(userPermissions && userPermissions.includes(this.permissionAccepted))) {
      setTimeout(()=> {
        this.eleRef.nativeElement.disabled = true;
      }, 0);
    }
  }
}
