import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LogoArcComponent} from './logo-arc.component';


@NgModule({
  declarations: [
    LogoArcComponent,
  ],
  imports: [
    CommonModule,
  ],
  exports: [LogoArcComponent],
})
export class LogoArcModule { }
