import {ControlMessageDirective} from './control-message.directive';
import {InputDirective} from './input.directive';

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MdFormDirective} from './md-form.directive';
import {InputRestrictionDirective} from './input-restriction.directive';

@NgModule({
  declarations: [InputDirective, ControlMessageDirective, MdFormDirective, InputRestrictionDirective],
  exports: [InputDirective, ControlMessageDirective, MdFormDirective, InputRestrictionDirective],
  imports: [CommonModule],
})
export class InputModule {}
