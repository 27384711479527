import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpService} from '@app/services/http/http.service';
import {retry} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PowerbiService {
  constructor(private _http: HttpService) {}

  getToken(data: any, report: string) {
    const payload = new FormData();

    payload.append('rol', data.rol);
    payload.append('npn', data.npn);
    payload.append('company', data.company);
    if (report) payload.append('report', data.report);

    return this._http
      .post(`${environment.apiURL}Powerbi/token`, payload)
      .pipe(retry(3));
  }
}
