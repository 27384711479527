import {Component, OnDestroy} from '@angular/core';
import {Notification} from './interfaces';
import {NotificationService} from '@app/services/notification/notification.service';
import {Subscription, firstValueFrom} from 'rxjs';
import {Router} from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnDestroy {
  private subcriptions: Record<string, Subscription> = {};

  notifications: Notification[] = [];
  notificationsCount = 0;

  constructor(
    private _notifications: NotificationService,
    private _router: Router
  ) {
    this.subcriptions['count'] = this._notifications
      .getNotificationsCountRealtime()
      .subscribe((resp) => (this.notificationsCount = resp));
    this.subcriptions['notifications'] = this._notifications
      .getNotificationsRealtime()
      .subscribe((resp) => (this.notifications = resp));
  }

  async markAllAsRead() {
    if (this.notificationsCount < 1) return;
    return await firstValueFrom(this._notifications.markAllAsRead());
  }

  markAsRead(id: number) {
    this._router.navigate(['/notifications'], {
      queryParams: {notification: id},
    });
    this.close();
  }

  close() {
    this._notifications.closePopup();
  }

  ngOnDestroy(): void {
    Object.values(this.subcriptions).map((value) => value.unsubscribe());
  }
}
