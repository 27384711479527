<div class="d-flex flex-column justify-content-between px-4">
  <div class="d-flex flex-column">
    <p id="title" class="text-primary fw-semibold mb-3 fs-5">{{ message.title }}</p>
    <p id="message" [innerHTML]="message.message"></p>
  </div>
  <div
    class="confirmation-actions d-flex flex-column flex-md-row justify-content-between w-100">
    <button
      id="confirm"
      class="confirm text-light order-md-2 w-100"
      cdkButton
      (click)="close(true)">
      Confirm
    </button>
    <button
      id="cancel"
      class="mt-4 mt-md-0 me-0 me-md-2 order-md-1 w-100"
      cdkButton="dark"
      [outline]="true"
      (click)="close(false)">
      Cancel
    </button>
  </div>
</div>
