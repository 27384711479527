<div [class]="menuClass">
  <span data-bs-toggle="dropdown" aria-expanded="false">
    <ng-content select="[cdkMenuToggler]"></ng-content>
  </span>
  <div
    class="dropdown-menu"
    [ngClass]="dropdownClass"
    [class]="size ? 'menu menu-' + size : ''"
  >
    <ng-content select="[cdkMenuContent]"></ng-content>
  </div>
</div>
