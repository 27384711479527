import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PasswordComponent} from './password.component';
import {PasswordFormModule} from '@app/shared/password-form/password-form.module';
import {ReactiveFormsModule} from '@angular/forms';
import {ButtonModule} from '@app/modules/@cdk/forms/button/button.module';
import {IconModule} from '@app/modules/@cdk/icon/icon.module';

@NgModule({
  declarations: [
    PasswordComponent,
  ],
  imports: [
    CommonModule,
    PasswordFormModule,
    ReactiveFormsModule,
    ButtonModule,
    IconModule,
  ],
})
export class PasswordModule { }
