<app-auth-layout hero="/assets/img/temp/forgot-password.svg">
  <div class="d-flex flex-column h-100 justify-content-between">
    <div class="user-info">
      <h4 class="text-center">Forgot password?</h4>
      <p class="text-justify">
        No worries, please enter your username and we’ll send you an email with
        instructions to reset your password.
      </p>
      <cdk-form-group>
        <label>Username</label>
        <input
          type="text"
          cdkInput
          placeholder="E.g Jane"
          [formControl]="username"
          (keyup.Enter)="submit()" />
        <span cdkSuffix>
          <span cdkIcon="outlined">account_circle</span>
        </span>
        <cdk-error *ngIf="username.hasError('required')"
          >This field is required</cdk-error
        >
        <cdk-error *ngIf="username.hasError('notEmail')"
          >Email addresses are not allowed in this field</cdk-error
        >
        <cdk-error *ngIf="username.hasError('httpError')"
          >Unexpected Error</cdk-error
        >
      </cdk-form-group>
    </div>
    <div class="align-items-center row mt-5">
      <div class="col-12 col-md-6 order-md-2 order-sm-1">
        <button
          cdkButton
          type="submit"
          class="text-light w-100"
          (click)="submit()"
          [disabled]="username.invalid || loading">
          Reset password
        </button>
      </div>

      <div class="col-12 col-md-6 order-md-1 order-sm-2 my-2">
        <button
          class="w-100"
          cdkButton="dark"
          [outline]="true"
          routerLink="/login">
          Go back
        </button>
      </div>
    </div>
  </div>
</app-auth-layout>
