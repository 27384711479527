import {Directive} from '@angular/core';

@Directive({
  selector: '[cdkIconButton]',
  host: {
    class: 'cdk-icon-button',
  },
})
export class IconButtonDirective {
  constructor() { }
}
