import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FilterInputComponent } from "./filter-input.component";
import { IconModule } from "../../icon/icon.module";
import { FormsModule } from "@angular/forms";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { FormsFilterComponent } from "./components/forms-filter/forms-filter.component";
import { FormGroupModule } from "@app/modules/@cdk/forms/input/form-group/form-group.module";
import { PopoverModule } from "@app/modules/popover/popover.module";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
  declarations: [FilterInputComponent, FormsFilterComponent],
  imports: [
    CommonModule,
    IconModule,
    PerfectScrollbarModule,
    FormsModule,
    FormGroupModule,
    PopoverModule,
    MatTooltipModule,
  ],
  exports: [FilterInputComponent, FormsFilterComponent],
})
export class FilterInputModule {}
