<div #filterContainer class="cdk-form-filter-container">
  <cdk-form-group class="py-0 cursor-pointer">
    <div
      cdkInputContainer
      class="w-100 not-break-line"
      [class.text-gray-500]="!selectedItem">
      <ng-container
        *ngTemplateOutlet="
          selectedItem ? itemTmpl || defaultItemTmpl : placeholderTmpl;
          context: { $implicit: selectedItem }
        "></ng-container>
      <ng-template #placeholderTmpl>
        {{ placeholder }}
      </ng-template>
    </div>
    <span cdkSuffix>
      <span cdkIcon="outlined">arrow_drop_down</span>
    </span>
  </cdk-form-group>
</div>

<div
  *popover="
    isOpen;
    target: filterContainer;
    closed: close;
    height: height;
    dir: direction;
    minwidth: true
  "
  class="cdk-form-filter shadow position-relative w-100 card-light p-2">
  <cdk-form-group class="cdk-form-filter__search-input">
    <input
      #searchInput
      type="text"
      cdkInput
      [placeholder]="searchPlaceholder"
      (keyup.enter)="initiateAddItemUnsearchable(searchInput)"
      (input)="onSearch($event)" />
    <span *ngIf="!enabledAddItemUnsearchable" cdkSuffix class="text-gray-500">
      <span cdkIcon="outlined">search</span>
    </span>
    <span *ngIf="enabledAddItemUnsearchable" cdkSuffix>
      <span
        (click)="initiateAddItemUnsearchable(searchInput)"
        cdkIcon="outlined"
        styleClass="cursor-pointer text-blue-700"
        >add</span
      >
    </span>
  </cdk-form-group>
  <div
    class="dropdown options-container"
    scrollSpy
    [perfectScrollbar]="{
      suppressScrollX: true,
      minScrollbarLength: 150
    }">
    <div class="dropdown-menu border-0 w-100">
      <ng-container
        *ngTemplateOutlet="
          filteredOptions.length ? displayOptions : defaultEmptyTmpl
        "></ng-container>
    </div>
  </div>
</div>

<ng-template #displayOptions>
  <ng-container *ngFor="let item of filteredOptions; let i = index">
    <div
      class="dropdown-item text-truncate cursor-pointer d-flex justify-content-between align-items-center mb-0"
      [matTooltip]="getItemTooltip(item)">
      <div
        class="w-100 not-break-line mb-2 mt-2"
        (click)="onChangeSelected(item)">
        <ng-container
          *ngTemplateOutlet="
            itemTmpl || defaultItemTmpl;
            context: { $implicit: item }
          "></ng-container>
      </div>
      <span
        *ngIf="item['removable'] && enabledAddItemUnsearchable"
        cdkSuffix
        class="d-flex">
        <span
          cdkIcon="sharp"
          (click)="removeItemUnsearchable(i)"
          styleClass="cursor-pointer text-danger-500"
          >cancel</span
        >
      </span>
    </div>
  </ng-container>
</ng-template>

<ng-template #defaultEmptyTmpl>
  <div class="dropdown-item">No available options</div>
</ng-template>

<ng-template #defaultItemTmpl let-item>
  {{ item }}
</ng-template>
