import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  Host,
  HostListener,
  Input,
  OnDestroy,
  Optional,
  Output,
  Renderer2,
} from '@angular/core';
import {
  FormControl,
  FormControlDirective,
  FormControlName,
  NgModel,
} from '@angular/forms';
import {isEmptyString, isString} from '@app/utils/asserts';
import {interval, Subject, Subscription} from 'rxjs';
import {debounce, skip} from 'rxjs/operators';
import {InputContainer} from './form-group/form-group.component';
import {MdFormDirective} from './md-form.directive';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({selector: '[cdkInput]', host: {class: ''}})
export class InputDirective implements AfterViewInit, OnDestroy {
  @Input() disabled: boolean;
  @Output() save = new EventEmitter();
  @Input() autofocus: boolean;

  private focus: boolean;
  private subscribe: Record<string, Subscription> = {};
  private saveSubject = new Subject();

  @Input() inputRestriction = 'none';
  regexPattern = /^[a-zA-Z0-9áéíóúÁÉÍÓÚüÜ\s]*$/;
  onlyNumberRegexPattern = /^[0-9]+$/;
  onlyTextRegexPattern = /^[A-Za-z ]+$/;

  constructor(
    @Host() @Optional() private formControlName: FormControlName,
    @Host() @Optional() private formControl: FormControlDirective,
    @Host() @Optional() private ngModel: NgModel,
    @Optional() private inputContainer: InputContainer,
    @Optional() private mdFormDirective: MdFormDirective,
    private renderer2: Renderer2,
    private el: ElementRef<HTMLInputElement>,
    private cd: ChangeDetectorRef
  ) {
    this.subscribe['save'] = this.saveSubject
      .pipe(
        skip(1),
        debounce(() => interval(900))
      )
      .subscribe(() => {
        this.save.emit();
      });
  }

  private _value: string | number;

  get value(): string | number {
    return this._value;
  }

  get control(): FormControl | null {
    if (this.formControl) {
      return this.formControl.control;
    }
    if (this.formControlName) {
      return this.formControlName.control;
    }
    if (this.ngModel) {
      return this.ngModel.control;
    }
  }

  ngAfterViewInit(): void {
    if (this.autofocus) {
      setTimeout(() => {
        this.el.nativeElement.focus();
        this.focusFn();
      });
    }

    if (this.inputContainer && this.control) {
      this.inputContainer.setFormControl(this.control);
      this.setMdFormDirectiveActive(this.control.value);
    }

    if (this.control) {
      this.subscribe['control'] = this.control.valueChanges.subscribe((value) => {
        this.saveSubject.next(null);
        this.setMdFormDirectiveActive(value);
      });
    }
  }

  setMdFormDirectiveActive(value) {
    if (this.mdFormDirective) {
      this.mdFormDirective.active =
        this.focus ||
        (isString(value) ?
          !isEmptyString(value) :
          value !== undefined && value !== null);
      this.cd.detectChanges();
    }
  }

  ngOnDestroy(): void {
    Object.values(this.subscribe).forEach((s) => s.unsubscribe());
  }

  @HostListener('focus')
  focusFn() {
    this.focus = true;
    if (this.mdFormDirective) {
      this.mdFormDirective.active = true;
    }
  }

  @HostListener('blur', ['$event'])
  blurFn() {
    this.focus = false;
    this.control?.updateValueAndValidity();
    if (this.mdFormDirective) {
      this.mdFormDirective.active = !isEmptyString(this.el.nativeElement.value);
    }
  }

  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    if (this.inputRestriction === 'onlyNumbers') {
      this.onlyNumbers(event);
    }

    if (this.inputRestriction === 'noSpecialChars') {
      this.noSpecialChars(event);
    }

    if (this.inputRestriction === 'onlyText') {
      this.onlyText(event);
    }
  }

  onlyNumbers(event: KeyboardEvent) {
    if (!this.onlyNumberRegexPattern.test(event.key)) {
      event.preventDefault();
    }
  }

  onlyText(event: KeyboardEvent) {
    if (!this.onlyTextRegexPattern.test(event.key)) {
      event.preventDefault();
    }
  }

  noSpecialChars(event: KeyboardEvent) {
    const allowedKeys = [
      'Backspace',
      'ArrowLeft',
      'ArrowRight',
      'ArrowUp',
      'ArrowDown',
    ];

    if (allowedKeys.includes(event.key)) {
      return;
    }

    if (!this.regexPattern.test(event.key)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData?.getData('text/plain');
    if (!clipboardData) {
      return;
    }

    if (
      this.inputRestriction === 'noSpecialChars' &&
      !this.regexPattern.test(clipboardData)
    ) {
      event.preventDefault();
    }

    if (
      this.inputRestriction === 'onlyNumbers' &&
      !this.onlyNumberRegexPattern.test(clipboardData)
    ) {
      event.preventDefault();
    }
  }
}
