<div class="bg-red p-2 text-white text-center" *ngIf="show">
    <p class="px-5">
        <span class="font-bold">
            Important: You must sign the contract to access your ARC account.
        </span>
        Need assistance? Get in touch with your account executive. <b class="w-counter d-inline-block">{{ convertTimeToString(minutes) }}:{{ convertTimeToString(seconds) }}</b> To go to Login
    </p>
    <span
        (click)="close()"
        cdkIcon="outlined"
        styleClass="icon-close cursor-pointer">
        close
    </span>
</div>
