import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GeneralLoadingComponent} from './components/general-loading/general-loading.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@NgModule({
  declarations: [GeneralLoadingComponent],
  imports: [CommonModule, MatProgressSpinnerModule],
  exports: [GeneralLoadingComponent],
})
export class LoadingModule {}
