export interface DentalPay {
  item: string;
}
export interface UserToDelete {
  id: string;
  firstName: string;
  lasttName: string;
}
export interface IReferrals {
  Name?: string;
  method?: string;
  Policy_ID__c?: string | null;
  Name_Company__c?: string | null;
  Referring_Agent__c: string | null;
  Referring_Agent_Email__c: string | null;
  NPN__c: string | null;
  Referring_Agent_Phone__c: string | null;
  APPLICANT__c: string | null | boolean;
  First_Name__c: string | null;
  Middle_Name__c: string | null;
  Last_Name__c: string | null;
  Second_Last_Name__c: string | null;
  SSN__c: string | null;
  DOB__c: string | null | Date;
  Gender__c: string | null;
  Legal_Status__c: string | null;
  Alien_Number__c: string | null;
  Card_number_resident__c: string | null;
  Document_expiration_date_resident__c: string | null | Date;
  Category_Code__c: string | null;
  Phone__c: string | null;
  Email__c: string | null;
  Address__c: string | null;
  Apt__c: string | null;
  City__c: string | null;
  County__c: string | null;
  State__c: string | null;
  Zip_Code__c: string | null;
  Employed_or_Self_employed_If_employed__c?: string | null;
  IncomeType__c?: string | null;
  Position_Occupation__c: string | null;
  Annual_Income__c: string | null;
  Is_your_mailing_address_the_same_as_your__c: string | boolean | null;
  Mailing_Address__c: string | null;
  Mailing_Apt__c: string | null;
  Mailing_City__c: string | null;
  Mailing_County__c: string | null;
  Mailing_State__c: string | null;
  Mailing_Zipcode__c: string | null;
  Applicant1__c: string | null;
  Full_Name__c: string | null;
  SSN1__c: string | null;
  Relation_to_Primary__c: string | null;
  DOB1__c: string | null | Date;
  Gender1__c: string | null;
  Legal_Status1__c: string | null;
  Alien_Number1__c: string | null;
  Card_number_resident1__c: string | null;
  Document_expiration_date_resident1__c: string | null | Date;
  Category_Code1__c: string | null;
  Employed_or_Self_employed_If_employed1__c?: string | null;
  IncomeType1__c?: string | null;
  Position_Occupation1__c: string | null;
  Annual_Income1__c: string | null;
  Applicant2__c: string | null;
  Full_Name2__c: string | null;
  SSN2__c: string | null;
  Relation_to_Primary2__c: string | null;
  DOB2__c: string | null | Date;
  Gender2__c: string | null;
  Legal_Status2__c: string | null;
  Alien_Number2__c: string | null;
  Card_number_resident2__c: string | null;
  Document_expiration_date_resident2__c: string | null | Date;
  Category_Code2__c: string | null;
  Employed_or_Self_employed_If_employed2__c?: string | null;
  IncomeType2__c?: string | null;
  Position_Occupation2__c: string | null;
  Annual_Income2__c: string | null;
  Applicant3__c: string | null;
  Full_Name3__c: string | null;
  SSN3__c: string | null;
  Relation_to_Primary3__c: string | null;
  DOB3__c: string | null | Date;
  Gender3__c: string | null;
  Legal_Status3__c: string | null;
  Alien_Number3__c: string | null;
  Card_number_resident3__c: string | null;
  Document_expiration_date_resident3__c: string | null | Date;
  Category_Code3__c: string | null;
  Employed_or_Self_employed_If_employed3__c?: string | null;
  IncomeType3__c?: string | null;
  Position_Occupation3__c: string | null;
  Annual_Income3__c: string | null;
  Applicant4__c: string | null;
  Full_Name4__c: string | null;
  SSN4__c: string | null;
  Relation_to_Primary4__c: string | null;
  DOB4__c: string | null | Date;
  Gender4__c: string | null;
  Legal_Status4__c: string | null;
  Alien_Number4__c: string | null;
  Card_number_resident4__c: string | null;
  Document_expiration_date_resident4__c: string | null | Date;
  Category_Code4__c: string | null;
  Employed_or_Self_employed_If_employed4__c?: string | null;
  IncomeType4__c?: string | null;
  Position_Occupation4__c: string | null;
  Annual_Income4__c: string | null;
  Applicant5__c: string | null;
  Full_Name5__c: string | null;
  SSN5__c: string | null;
  Relation_to_Primary5__c: string | null;
  DOB5__c: string | null | Date;
  Gender5__c: string | null;
  Legal_Status5__c: string | null;
  Alien_Number5__c: string | null;
  Card_number_resident5__c: string | null;
  Document_expiration_date_resident5__c: string | null | Date;
  Category_Code5__c: string | null;
  Employed_or_Self_employed_If_employed5__c?: string | null;
  IncomeType5__c?: string | null;
  Position_Occupation5__c: string | null;
  Annual_Income5__c: string | null;
  Applicant6__c: string | null;
  Full_Name6__c: string | null;
  SSN6__c: string | null;
  Relation_to_Primary6__c: string | null;
  DOB6__c: string | null | Date;
  Gender6__c: string | null;
  Legal_Status6__c: string | null;
  Alien_Number6__c: string | null;
  Card_number_resident6__c: string | null;
  Document_expiration_date_resident6__c: string | null | Date;
  Category_Code6__c: string | null;
  Employed_or_Self_employed_If_employed6__c?: string | null;
  IncomeType6__c?: string | null;
  Position_Occupation6__c: string | null;
  Annual_Income6__c: string | null;
  Company__c: string | null;
  Plan_Name__c: string | null;
  Metal_Level__c: string | null;
  Effective_Date__c: string | null;
  Subsidy_Amount__c: string | null;
  Final_Plan_Premium__c: string | null;
  Additional_Information__c: string | null;
  Method_of_Payment__c: string | null;
  Automatic_Payment__c: string | boolean | null;
  Bank_Name__c: string | null;
  Account_Type__c: string | null;
  Routing_Number__c: string | null;
  Account_Number__c: string | null;
  Account_Holder_First_Name__c: string | null;
  Account_Holder_Last_Name__c: string | null;
  Email_Address__c: string | null;
  Billing_Address__c: string | null;
  Apt1__c: string | null;
  City1__c: string | null;
  State_pay__c: string | null;
  Zip__c: string | null;
  Card_Type__c: string | null;
  CVV__c: string | null;
  Card_Number__c: string | null;
  Expiration_Date__c: string | null;
  Card_Holder_First_Name__c: string | null;
  Card_Holder_Last_Name__c: string | null;
  Email_Address1__c: string | null;
  Billing_Address2__c: string | null;
  Apt2__c: string | null;
  City2__c: string | null;
  State2__c: string | null;
  Zip2__c: string | null;
  CreatedDate?: string | null;
  Disposition__c?: string | null;
  Documents_Sent__c?: string | null;
  Need_Documents__c?: string | null;
  Doc_Expiry_Date__c?: string | null;
  Observations__c?: string | null;
  Marketplace_ID__c?: string | null;
  DOCUMENTS_NOTES__c?: string | null;
  Payment_Disposition__c?: string | null;
  Controller_Changes__c?: string | null;
  CLIENT_FULL_NAME__c?: string | null;
}
