// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  powerBI: {
    reportBaseURL: "https://app.powerbi.com/reportEmbed",
    qnaBaseURL: "https://app.powerbi.com/qnaEmbed",
    tileBaseURL: "https://app.powerbi.com/embed",
  },
  urlReportClaroinsurance: "",
  apiURL: "https://apiarc-qa.claroinsurancehub.com/api",
  serverUrl: "apiarc-dev.claroinsurancehub.com",
  domain: "arcqa.claroinsurancehub.com",
  ipServer: "https://ipinfo.io",
  company: "claro",
  companyName: "Claro Insurance",
  appUrl: "http://claro.dev.claroinsurance.com",
  wsKey: "qgOZx5oTCMnVCUyT4yzjUkcKTVSJkXdfFKGuRuzY",
  tawkSrc: "https://embed.tawk.to/5e5d3b056d48ff250ad8df37/default",
  signUpUrl:
    "https://claroinsurance.com/sign-up-now-become-an-insurance-agent/",
  version: "2.0",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
