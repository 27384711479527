export interface Referral {
  First_Name__c: string;
  Last_Name__c: string;
  DOB__c: string;
  Gender__c: string;
  Phone_Number__c: string;
  Email__c: string;
  Address__c: string;
  Apt__c: string;
  City__c: string;
  County__c: string;
  State__c: string;
  Zip_Code__c : string;
  Product_Type__c: string;
  Question_Medical_Condition__c: string;
  Medical_Condition_Answer__c: string;
  Additional_Information__c: string;
  attached_1__c: string;
  attached_2__c: string;
  attached_3__c: string;
}
