import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  numberPattern = /[0-9]/;
  uppercasePattern = /[A-Z]/;
  lowercasePattern = /[a-z]/;
  symbolsPattern = /[!@#$%^&*_=+-\.]/;
  urlPattern = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

  emailPattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';
  textPattern = /^[a-zA-Z ]+$/;
  passwordMinLength = 8;

  constructor() { }

  controlHasError(form: FormGroup, control: string, error: string) {
    return form ? form.get(control)?.hasError(error) && form.get(control)?.touched : null;
  }

  sameValue(compareField: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formGroup = control.parent;

      if (formGroup) {
        const val1 = control.value;
        const val2 = formGroup.get(compareField)?.value;

        if (val2?.length && val1 !== val2) {
          return {
            mismatchValues: true,
          };
        }
      }

      return null;
    };
  }

  sameValueForControls(control1: AbstractControl, control2: AbstractControl): ValidationErrors | null {
    if (control1.value !== control2.value) {
      return {
        mismatchValues: true,
      };
    }

    return null;
  }

  equals(compare: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (value !== compare) {
        return {equals: true};
      }
      return null;
    };
  }

  equalsIgnoreCase(compare: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let value = control.value;
      value = value ? value.toLowerCase() : value;
      if (value !== compare.toLocaleLowerCase()) {
        return {equals: true};
      }
      return null;
    };
  }

  zelleInformationValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;

      if (!value) {
        return null;
      }

      const isNumeric = /^[0-9]{10}$/.test(value);
      const isEmail = new RegExp(this.emailPattern).test(value);

      if (isNumeric || isEmail) {
        return null;
      } else {
        return { 'numericOrEmail': true };
      }
    };
  }

  maxArrayLength(max: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (value?.length > max) {
        return {maxArrayLength: true};
      }
      return null;
    };
  }
}
