import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-header-form',
  templateUrl: './header-form.component.html',
  styleUrls: ['./header-form.component.scss'],
})
export class HeaderFormComponent implements OnInit {
  @Input() passwordValidations = {
    change: false,
    caracters: false,
    uppercase: false,
    lowercase: false,
    number: false,
    symbols: false,
  };

  @Input() styleMarginTop: number;
  @Input() component: string;

  constructor() {}

  ngOnInit(): void {}

  get change(): boolean {
    return this.passwordValidations.change;
  }
}
