<form [formGroup]="formTerms" #formSign>
  <cdk-form-group class="pt-0">
    <label class="input-label">Write your user to sign</label>
    <input
      type="text"
      cdkInput
      placeholder="E.g Jane Doe"
      formControlName="username" />
    <cdk-error *ngIf="formTerms.get('username').hasError('required')"
      >This field is required</cdk-error
    >
    <cdk-error *ngIf="formTerms.get('username').hasError('pattern')"
      >Please insert a valid username</cdk-error
    >
  </cdk-form-group>
</form>
