import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@app/services/auth/auth-service.service';
import { ContractsService } from '@app/services/contracts/contracts.service';
import { Subscription, fromEvent } from 'rxjs';

@Component({
  selector: 'app-contract-terms',
  templateUrl: './contract-terms.component.html',
  styleUrls: ['./contract-terms.component.scss'],
})
export class ContractTermsComponent implements AfterViewInit, OnDestroy {
  formTerms!: FormGroup;
  private subscriptions: Record<string, Subscription> = {};
  @ViewChild('formSign') formContractID: ElementRef;

  constructor(
    private fb: FormBuilder,
    private _contractService: ContractsService,
    private _auth: AuthService
  ) {
    this.formTerms = this.fb.group({
      username: new FormControl(null, [Validators.required]),
    });
    this.formTerms.disable();

    this._contractService.formContract = this.formTerms;
    this.subscriptions['formSubscriptions'] = this.formTerms?.valueChanges
      .subscribe((value) => {
        if (value) this._auth.declineWithCounter$.next(false);
      });
  }

  ngAfterViewInit(): void {
    const formEventAgency = this.formContractID.nativeElement.querySelectorAll('input');

    formEventAgency.forEach((element, i) => {
      this.subscriptions[`formSubscription_${i}`] =
      fromEvent(element, 'focus')
        .subscribe( (value) => {
          if (value) this._auth.declineWithCounter$.next(false);
        });
    });
  }

  ngOnDestroy(): void {
    Object.values(this.subscriptions).forEach((value) => value.unsubscribe());
  }
}
