<app-auth-layout hero="/assets/img/temp/reset-password.svg">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <h5 class="text-center">Set a new password</h5>
    <p class="text-center mb-2">
      Your new password must be different to previously used password and
      contain at least:
    </p>
    <app-password-form
      component="reset-password"
      [passwordControl]="form.get('password')"
      [passwordConfirmationControl]="form.get('password_confirmation')"
      (validationForm)="validationForm = !$event"
      [styleMarginTop]="1.5"></app-password-form>
    <div class="d-flex flex-sm-column">
      <button
        type="submit"
        cdkButton
        class="text-light"
        [disabled]="form.invalid || loading || validationForm">
        Reset Password
      </button>
    </div>
  </form>
</app-auth-layout>
