import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-general-loading',
  templateUrl: './general-loading.component.html',
  styleUrls: ['./general-loading.component.scss'],
})
export class GeneralLoadingComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
