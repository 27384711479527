export interface CommissionAdvanceRequestBase {
  Id: string;
  date: string;
  to_sign: number;
}

interface CommissionAdvanceSignBase extends CommissionAdvanceRequestBase {
  signature: string;
  printedName: string;
  state: string;
  send_copy: number;
}

export interface AgencyCommissionAdvanceSign extends CommissionAdvanceSignBase {
  title: string;
  entity: string;
}

export interface AgentCommissionAdvanceSign extends CommissionAdvanceSignBase {
  npn: string;
}
