<app-auth-layout hero="/assets/img/temp/email-confirmation.svg">
  <h4 class="text-center">Check your mail</h4>
  <p class="text-center">
    We sent a password reset link to your registered email account
  </p>
  <div class="d-flex justify-content-center">
    <img src="/assets/img/icons/envelop.svg" alt="Mail" />
  </div>
  <p class="text-center">
    Please, go to your email and click on the link we sent you to start the
    process.
  </p>
  <div class="call-to-action">
    <div class="d-flex flex-sm-column align-items-sm-center">
      <span><h6 class="color-gray mb-0">Didn't receive an email?</h6></span>
      <span class="timeout-text"
        ><strong
          [class]="
            timeout === 0 && !loading ? 'disabled-action' : 'active-action'
          "
          >{{ displayTimeout }}</strong
        ></span
      >
      <span
        [class]="
          timeout === 0 && !loading ? 'active-action' : 'disabled-action'
        "
        (click)="sendEmail()"
        ><h6 class="mb-0">Click to resend</h6></span
      >
    </div>
  </div>
</app-auth-layout>
