import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';
import {
  BehaviorSubject,
  Observable,
  Subject,
  firstValueFrom,
  map,
  tap,
  catchError,
  pipe,
  of,
} from 'rxjs';
import {IDataTableHttpResponse} from '@app/shared';
import qs from 'qs';
import {UsersService} from '../users/users.service';
import {SocketService} from '../socket-service/socket-service.service';
import Echo from 'laravel-echo';

export interface INotification {
  content?: string;
  created_at?: Date;
  id?: number;
  read_at?: Date | null;
  status?: string;
  subject?: string;
  updated_at?: Date;
  active: boolean;
  isSelected: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private _module = 'notifications';

  private notifications: Subject<any[]> = new BehaviorSubject<any[]>([]);
  private notifications$: Observable<any[]> = this.notifications.asObservable();

  private notificationsUnreadCount: Subject<number> =
    new BehaviorSubject<number>(0);
  private notificationsUnreadCount$: Observable<number> =
    this.notificationsUnreadCount.asObservable();

  private close: Subject<void> = new Subject<void>();
  private close$: Observable<void> = this.close.asObservable();

  audio = new Audio('/assets/audio/notification/notification.mp3');
  userId: number;
  socket: Echo;

  constructor(
    private http: HttpService,
    private _user: UsersService,
    private socketService: SocketService
  ) {
    this._user.user$.subscribe((user) => {
      const { id } = user;
      this.userId = id;
    });

    this.socket = this.socketService.getSocketInstance();

    this.socket
      .channel(`new-notification.${this.userId}`)
      .listen('NewNotification', () => {
        this.refreshData(true);
      });

    this.refreshData();
  }

  private async refreshData(sound = false) {
    // Todo: Revisar error response;
    this.getNotificationsData({read: 0})
      .pipe(catchError((val) => of(val)))
      .subscribe((response) => {
        if (!response.data) return;
        if (sound) this.audio.play();
        this.notifications.next(response.data.slice(0, 9));
        this.notificationsUnreadCount.next(response.total);
      });
  }

  public closePopup() {
    this.close.next();
  }

  public observeClosePopup() {
    return this.close$;
  }

  public getNotificationsRealtime(): Observable<any[]> {
    return this.notifications$;
  }

  public getNotificationsCountRealtime(): Observable<number> {
    return this.notificationsUnreadCount$;
  }

  public getNotificationsData(
    params: any = {}
  ): Observable<IDataTableHttpResponse> {
    const url = `${this._module}?` + qs.stringify(params);
    return this.http
      .getModule<any>(url)
      .pipe(map((res) => res.data.notifications));
  }

  public getNotificationsUnreadCount(): Observable<IDataTableHttpResponse> {
    const url = `${this._module}/unread-count`;
    return this.http
      .getModule<any>(url)
      .pipe(map((res) => res.data.notifications_count));
  }

  public markAsRead(id): Observable<IDataTableHttpResponse> {
    const url = `${this._module}/${id}/mark-as-read`;
    return this.http.putModule(url, {}).pipe(tap(() => this.refreshData()));
  }

  public markGroupAsRead(ids: number[]): Observable<any> {
    const url = `${this._module}/mark-as-reads`;
    return this.http
      .putModule(url, {notification_ids: [...ids]})
      .pipe(tap(() => this.refreshData()));
  }

  public markAllAsRead(): Observable<IDataTableHttpResponse> {
    const url = `${this._module}/mark-all-as-read`;
    return this.http.putModule(url, {}).pipe(tap(() => this.refreshData()));
  }
}
