import {FlexibleConnectedPositionStrategyOrigin} from '@angular/cdk/overlay';
import {Directive, HostListener, Input} from '@angular/core';
import {MediaScreenService} from '@app/modules/media-screen/media-screen.service';
import {Subject} from 'rxjs';
import {PopoverOptions} from './popover-options.directive';

@Directive({selector: '[popoverContextmenuTrigger]'})
export class PopoverContextmenuTriggerDirective {
  @Input() disabledScreenDown: string;

  private triggerSubject = new Subject<{
    origin: FlexibleConnectedPositionStrategyOrigin;
    options: PopoverOptions;
  }>();
  public readonly trigger$ = this.triggerSubject.asObservable();

  constructor(private ms: MediaScreenService) {}

  @HostListener('contextmenu', ['$event'])
  contextmenu(event: MouseEvent) {
    if (this.disabledScreenDown) {
      if (this.ms.downTo(this.disabledScreenDown)) {
        return;
      }
    }
    event.preventDefault();
    event.stopPropagation();
    this.triggerSubject.next({
      origin: {x: event.clientX, y: event.clientY},
      options: {dir: 'ltr'},
    });
  }
}
