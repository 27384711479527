import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CdkIconDirective} from './cdk-icon.directive';

@NgModule({
  declarations: [CdkIconDirective],
  imports: [CommonModule],
  exports: [CdkIconDirective],
})
export class IconModule {}
