import { ASR } from "./asr.model";
import { Contract } from "./contract.model";
import { Module } from "./module.model";

export interface UserData {
  user: User;
  token: string;
  menu: Menu;
  contracts?: Contract[];
  logged_in_as?: boolean;
  account_executive?: AccountExecutive;
  account_commissions_paid_type?: AccountCommissionsPaidType;
}

export interface Role {
  id?: number;
  name?: string;
  order?: number;
  title?: string;
  modules?: Module[];
  asrPermissions?: string[];
}

export interface Menu {
  favorites: number[];
  modules: Module[];
  roles: Role[];
  asrTypes?: [];
}

export class User {
  id: number;
  username: string;
  sf_fullname?: string;
  sf_first_name?: string;
  sf_last_name?: string;
  sf_email?: string;
  sf_npn?: string;
  sf_phone?: string;
  sf_gender?: string;
  sf_status?: boolean;
  sf_birth_day?: Date;
  sf_state?: string;
  sf_county?: string;
  sf_city?: string;
  sf_zip_code?: string;
  sf_address?: string;
  sf_lead_id?: string;
  img_account?: string;
  update_data?: boolean;
  terms_conditions?: boolean;
  email_verified_at: Date;
  locale: string;
  verification_code?: number;
  onboarding_at: Date;
  default_role_id: number;
  created_at: Date;
  updated_at: Date;
  accessToken: string;
  rule_skip_assignment_date?: boolean;
  rule_assignment_without_policy_out_of_date?: boolean;
  birth_day_account: string;
  email_account: string;
  phone_account: string;
  full_name: string;
  npn_account: string;
  user: string;
  contrato_id: string;
  logged_in_as?: ASR;
  npn_account_prev: string;
  arc_provider_active?: boolean;
  arc_provider_active_domain?: string;
  sf_ffm?: string;
  sf_interested?: string;
  sf_same_mailing?: boolean;
  home_address?: HomeAddress;
}

export interface IActiveDevice {
  device: string;
  ip: string;
  platform: string;
  browser: string;
  address: string;
  current_device: boolean;
  id: number;
  last_activity: string;
}

export interface AccountExecutive {
  Email__c: string;
  Phone__c: string;
  AE_Full_Name__c: string;
}

export interface Rol {
  usuario_id: string;
  id: string;
  rol: string;
}

export interface PowerBiUser {
  npn: string;
  company: string;
  rol: string;
}

interface HomeAddress {
  sf_address: string;
  sf_county: string;
  sf_city: string;
  sf_zip_code: string;
}
export interface AccountCommissionsPaidType {
  Commission_Payment__c: string;
}
export interface AccountCommissionsPaidType {
  Commission_Payment__c: string;
}
