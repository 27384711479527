import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { HttpService } from '../http/http.service';
import { IDataTableHttpResponse } from '@app/shared/data-table/interfaces/data-table';
import * as qs from 'qs';
import { LocalStorageService } from '../storage/local-storage.service';
import { AsrType, Module, ModuleStatusEnum } from '@app/models';
import { Asr } from '@app/enums/localstorage/asr';

@Injectable({
  providedIn: 'root',
})
export class AsrService {
  readonly routeASR: Partial<Module>[] = [
    {
      title: 'Commissions Pre liquidated',
      id: 34,
      name: 'Pre-liquidated',
      icon: 'paid',
      order: 4,
      route: '/commissions-preliquidated',
      status: ModuleStatusEnum.ACTIVE,
      preview: null,
      permissions: [],
      items: [
        {
          id: 1,
          name: 'Statements Agents',
          title: 'Statements Agents',
          icon: 'receipt',
          route: '/commissions-preliquidated/agent',
          preview: null,
          status: ModuleStatusEnum.ACTIVE,
          order: 0,
          parent_id: 34,
          permissions: [],
          isPowerBi: false,
        },

        {
          id: 1,
          name: 'Statements Agency',
          title: 'Statements Agency',
          icon: 'receipt',
          route: '/commissions-preliquidated/agency',
          preview: null,
          status: ModuleStatusEnum.ACTIVE,
          order: 0,
          parent_id: 34,
          permissions: [],
          isPowerBi: false,
        },
      ],
    },
  ];

  constructor(
    private http: HttpService,
    private localStorage: LocalStorageService
  ) {}

  public getAsr() {
    return this.http.getModule('user/asr').pipe(filter((data) => !!data));
  }

  public getAsrData(params: any = {}): Observable<IDataTableHttpResponse> {
    const url = 'user/asr/list?' + qs.stringify(params);

    return this.http.getModule<any>(url).pipe(map((res) => res.data.users));
  }

  public getAsrTypes(): Observable<AsrType[]> {
    const url = 'profile/asr-types';

    return this.http.getModule<any>(url).pipe(map((res) => res.data.asr_types));
  }

  get asrMenu() {
    return this.routeASR;
  }

  public setUpAsr(user_id: number): Observable<any> {
    return this.http.postModule('user/asr', { user_id }).pipe(
      filter((data) => !!data),
      tap(() => this.localStorage.deleteItem('currentRole'))
    );
  }

  public removeAsr(): Observable<any> {
    return this.http.deleteModule('user/asr').pipe(
      map((res) => res.data),
      tap(() => {
        this.localStorage.deleteItem('currentRole');
        this.localStorage.setItem(Asr.ASR_STATUS, false);
        this.localStorage.deleteItem(Asr.SKIP_ASR);
        this.localStorage.deleteItem(Asr.SKIP_CONTRACT);
      })
    );
  }
}
