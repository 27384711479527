import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
@Component({
  selector: 'app-general-alert',
  templateUrl: './general-alert.component.html',
  styleUrls: ['./general-alert.component.scss'],
})
export class GeneralAlertComponent {
  constructor( public dialogRef: MatDialogRef<GeneralAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, message: string, }) { }
}
