import { Component, Input, OnDestroy } from '@angular/core';
import { Dialog } from '@angular/cdk/dialog';
import { NotificationService } from '@app/services/notification/notification.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { MenuService } from '@app/services/menu/menu.service';

@Component({
  selector: 'app-notifications-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnDestroy {
  private subcriptions: Record<string, Subscription> = {};
  @Input() mobile = false;
  notificationsCount = 0;
  notificationsToggle = false;

  constructor(
    public dialog: Dialog,
    private _notifications: NotificationService,
    public menuService: MenuService,
    public router: Router
  ) {
    this.subcriptions['count'] = this._notifications
      .getNotificationsCountRealtime()
      .subscribe((resp) => (this.notificationsCount = resp));

    this.subcriptions['close'] = this._notifications
      .observeClosePopup()
      .subscribe(() => (this.notificationsToggle = false));
  }

  ngOnDestroy(): void {
    Object.values(this.subcriptions).map((value) => value.unsubscribe());
  }

  closedPopup = () => {
    this.openPopup(false);
  };

  openPopup(notificationsToggle = !this.notificationsToggle) {
    this.notificationsToggle = notificationsToggle;
    this.menuService.toggleSateMoreNavBar$.next(this.notificationsToggle);
  }

  get isScreenSmall() {
    return window.innerWidth < 576;
  }

  openNotifications() {
    this.isScreenSmall ?
      this.router.navigate(['/notifications']) :
      (this.notificationsToggle = !this.notificationsToggle);
  }
}
