export interface NewTicketResponse {
  msg: string;
  data: Data;
}

interface Data {
  case: Case;
}

export interface Case {
  Id: string;
  CaseNumber: string;
  attributes: Attribute;
}

interface Attribute {
  type: string;
  url: string;
}
