import {Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'cdk-hint',
  template: `
    <div class="d-flex" [class]="alignClasses[align]">
      <p class="text-secondary">
        <ng-content></ng-content>
      </p>
    </div>
  `,
  styles: [],
})
export class HintComponent implements OnInit {
  @Input() align: 'start' | 'center' | 'end' = 'start';

  alignClasses = {
    center: 'justify-content-center',
    end: 'justify-content-end',
  };

  constructor() {}

  ngOnInit(): void {}
}
