import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {WelcomeRoutingModule} from './welcome-routing.module';
import {WelcomeComponent} from './welcome.component';
import {LogoArcModule} from '@app/images/logo-arc/logo-arc.module';
import {ButtonModule} from '@app/modules/@cdk/forms/button/button.module';
import {SocialMediaModule} from '@app/shared/social-media/social-media.module';
import {RolChoiseModule} from './rol-choise/rol-choise.module';


@NgModule({
  declarations: [WelcomeComponent],
  imports: [
    CommonModule,
    WelcomeRoutingModule,
    LogoArcModule,
    ButtonModule,
    SocialMediaModule,
    RolChoiseModule,
  ],
  exports: [WelcomeComponent],
})
export class WelcomeModule { }
