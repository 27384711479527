import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Role } from '@app/models/users/user.model';
import { LocalStorageService } from '@app/services/storage/local-storage.service';
import { UsersService } from '@app/services/users/users.service';
import { Subscription, catchError, take, throwError } from 'rxjs';

@Component({
  selector: 'app-switch-control',
  templateUrl: './switch-control.component.html',
  styleUrls: ['./switch-control.component.scss'],
})
export class SwitchControlComponent implements OnDestroy {
  @Input() isMobile = false;
  roles: Role[];
  defaultRole: Role;
  currentRole: Role;

  private subscriptions: Record<string, Subscription> = {};

  constructor(
    private userService: UsersService,
    private localStorageService: LocalStorageService,
    private route: Router
  ) {
    this.roles = this.userService
      .getRoles()
      .filter(role => role.name !== 'ASR');
    const isAsr = this.localStorageService.getItem('skipAsr');

    this.subscriptions['userService'] = this.userService.userRole$.subscribe(
      role => {
        if (isAsr) {
          this.currentRole = this.localStorageService.getItem('currentRole');
          const role = this.userService.getRole();
          this.setDefaultRole(role.id);
        } else {
          this.currentRole = role;
          const defaultRoleId = this.userService.getUser().default_role_id;
          this.setDefaultRole(defaultRoleId);
        }
      }
    );
  }

  ngOnDestroy(): void {
    Object.values(this.subscriptions).forEach(value => value.unsubscribe());
  }

  updateCurrentRole() {
    this.currentRole = this.userService.getRole();
  }

  setDefaultRole(defaultRolId: number) {
    this.defaultRole = this.roles.find(role => {
      if (role.id === defaultRolId) return role;
    });
  }

  changeRole($event: Role) {
    if (this.currentRole.id !== $event.id) {
      this.userService.setCurrentRole($event);
      this.updateCurrentRole();
      this.route.navigate(['/home']);
    }
  }

  setFav(item: Role) {
    if (item.id !== this.defaultRole.id) {
      this.setDefaultRole(item.id);
      this.userService
        .updateDefaultProfile(item.id)
        .pipe(
          take(1),
          catchError(error => throwError(error))
        )
        .subscribe();
    }
  }
}
