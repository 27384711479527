import {Injectable, inject} from '@angular/core';
import {HttpService} from '../http/http.service';
import {catchError, lastValueFrom, throwError} from 'rxjs';
import {UiService} from '../cdk/ui.service';

export class DataTableService {
  private _ui;

  constructor(protected http: HttpService) {
    this._ui = inject(UiService);
  }

  protected async downloadExcel(url: string): Promise<boolean> {
    const data: any = await lastValueFrom(
      this.http.getModule<Blob>(url, true).pipe(
        catchError((error) => throwError(() => new Error(error)))
      )
    );

    if (!data || data.status !== 200) {
      this.msgError();

      return false;
    }

    return this.makeExcel(data);
  }

  private makeExcel(data: any): boolean {
    try {
      const name: string = decodeURI(
        data.headers.get('Content-Disposition')
          .split('filename="')[1]
          .split('"')[0]
      );

      const a = document.createElement('a');

      const objectUrl = URL.createObjectURL(data.body);
      a.href = objectUrl;
      a.download = name;
      a.click();

      URL.revokeObjectURL(objectUrl);

      return true;
    } catch (error) {
      this.msgError();
      return false;
    }
  }

  private msgError(): void {
    this._ui.showAlert(
      'We are having troubles getting your file, please try again later.',
      'error'
    );
  }
}
