import { Component } from "@angular/core";
import { UiService } from "@app/services/cdk/ui.service";

@Component({
  selector: "app-logo-arc",
  template: `<img [src]="src" alt="" class="d-block h-100" />`,
})
export class LogoArcComponent {
  src: string;

  constructor(private _ui: UiService) {
    this._ui.getAppLogo$().subscribe(src => {
      this.src = src;
    });
  }
}
