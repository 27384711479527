import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

export enum ButtonRole {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  DANGER = 'danger',
  WARNING = 'warning',
  INFO = 'info',
  LIGHT = 'light',
  DARK = 'dark',
}

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({
  selector: '[cdkButton]',
  host: {
    class: 'btn d-inline-flex align-items-center justify-content-center',
  },
})
export class ButtonDirective implements OnChanges {
  @Input('cdkButton') role: ButtonRole | string = '';
  @Input() qa: string;
  @Input() size: 'xsm' | 'sm' | 'lg' | '' = '';
  @Input() outline: boolean;
  @Input() pill: boolean;
  @Input() circle: boolean;
  @Input() loading: boolean;

  constructor(
    private render2: Renderer2,
    private el: ElementRef<HTMLButtonElement>
  ) {
    // not to do
  }

  ngOnChanges({
    role,
    size,
    outline,
    pill,
    loading,
    circle,
    qa,
  }: SimpleChanges) {
    if (role || size || outline || pill || circle || qa) {
      this.removeClasses(
        this.getClasses({
          outline: outline ? outline.previousValue : this.outline,
          role: role ? role.previousValue : this.role,
          size: size ? size.previousValue : this.size,
          pill: pill ? pill.previousValue : this.pill,
          circle: circle ? circle.previousValue : this.circle,
        })
      );
      this.addClasses(
        this.getClasses({
          outline: outline ? outline.currentValue : this.outline,
          role: role ? role.currentValue : this.role,
          size: size ? size.currentValue : this.size,
          pill: pill ? pill.currentValue : this.pill,
          circle: circle ? circle.currentValue : this.circle,
        })
      );

      this.addQaIdentifier(qa?.currentValue);
    }

    if (loading) {
      if (this.loading) {
        this.el.nativeElement.blur();
        this.render2.setStyle(this.el.nativeElement, 'pointer-events', 'none');
        this.render2.setAttribute(this.el.nativeElement, 'tabindex', '-1');
      } else {
        this.render2.removeStyle(this.el.nativeElement, 'pointer-events');
        this.render2.removeAttribute(this.el.nativeElement, 'tabindex');
      }
    }
  }

  addClasses(classes: string) {
    classes
      .split(' ')
      .filter((c) => c.trim())
      .forEach((_class) =>
        this.render2.addClass(this.el.nativeElement, _class)
      );
  }

  removeClasses(classes: string) {
    classes
      .split(' ')
      .filter((c) => c.trim())
      .forEach((_class) =>
        this.render2.removeClass(this.el.nativeElement, _class)
      );
  }

  getClasses({size, role, outline, pill, circle}) {
    return (
      (size ? `btn-${size} ` : '') +
      (pill ? 'rounded-pill ' : '') +
      (circle ? 'btn-circle ' : '') +
      (role ? `btn-${outline ? 'outline-' : ''}${role} ` : '')
    );
  }

  addQaIdentifier(qaIdentifier: string) {
    this.render2.setAttribute(this.el.nativeElement, 'id', qaIdentifier);
  }
}
