<div class="auth-flow-container align-items-center bg-warning">
  <div class="row m-0 auth-flow-row">
    <div class="position-absolute d-none d-sm-block auth-flow-overlay">
      <img [src]="appLogo" alt="ARC" height="40" />
    </div>
    <div class="d-sm-none mt-5 mb-0 px-3">
      <img [src]="appLogo" alt="ARC" height="40" />
    </div>
    <div class="col col-lg-6 d-none d-lg-block p-0 position-relative">
      <img
        class="position-absolute d-none d-lg-block auth-flow-image"
        [src]="hero"
        *ngIf="hero"
        alt="" />
    </div>
    <div class="col col-12 col-lg-6">
      <div
        class="d-flex flex-column justify-content-center align-items-center h-100">
        <div class="row align-items-center justify-content-center py-5 px-2">
          <div
            class="auth-flow-sign card col-12 position-relative"
            [class.shaped]="!!signUp">
            <ng-content></ng-content>
            <div class="position-absolute container_end" *ngIf="signUp">
              <div class="container_end__signup g-1">
                <p class="container_end__signup__title">
                  Already have an account?
                  <a
                    class="container_end__signup__nav fst-normal"
                    [href]="signUpUrl"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Sign Up</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
        <app-social-media backgroundClass="bg-warning"></app-social-media>
      </div>
    </div>
  </div>
</div>
