import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-rol-choise',
  templateUrl: './rol-choise.component.html',
  styleUrls: ['./rol-choise.component.scss'],
})
export class RolChoiseComponent implements OnChanges {
  @Input() rol: string;
  @Input() active = false;

  public img: string;

  rolImg: Record<string, string> = {
    "agent": "/assets/img/icons/agent.svg",
    "agency": "/assets/img/icons/agency.svg",
    "agent of agency": "/assets/img/icons/agent.svg",
  };
  constructor() {}

  ngOnChanges({rol}: SimpleChanges): void {
    if (rol) {
      this.rol = rol.currentValue;
      this.img = this.rolImg[this.rol];
    }
  }
}
