import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MenuItemComponent} from './menu-item.component';
import {IconModule} from '@app/modules/@cdk/icon/icon.module';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [MenuItemComponent],
  imports: [CommonModule, IconModule, RouterModule],
  exports: [MenuItemComponent],
})
export class MenuItemModule {}
