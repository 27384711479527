<form class="row" [formGroup]="form" (ngSubmit)="next()">
  <div class="col-12">
    <cdk-form-group [class.form-group-fill]="!!form.get('sf_address').value">
      <label *ngIf="!isPreregisterRole">Address</label>
      <label *ngIf="isPreregisterRole">Mailing address</label>
      <input
        type="text"
        cdkInput
        addressAutocompleteGoogle
        (updateAddress)="updateAddress($event)"
        [required]="true"
        formControlName="sf_address"
        placeholder="E..g Road Yellow, 5th, Lane" />
      <span cdkSuffix>
        <span cdkIcon="outlined">mail</span>
      </span>
    </cdk-form-group>
  </div>
  <div class="col-12 col-md-6">
    <cdk-form-group [class.form-group-fill]="!!form.get('sf_county').value">
      <label>County</label>
      <input
        type="text"
        cdkInput
        placeholder="County"
        (keydown.enter)="next()"
        formControlName="sf_county" />

      <cdk-error *ngIf="controlHasError(form, 'sf_county', 'required')"
        >This field is required</cdk-error
      >
    </cdk-form-group>
  </div>
  <div class="col-12 col-md-6">
    <cdk-form-group [class.form-group-fill]="!!form.get('sf_state').value">
      <label>State*</label>
      <cdk-select
        [options]="states"
        [noBackground]="true"
        [value]="state"
        cdkInput
        [icon]="'arrow_drop_down'"
        [placeholder]="'Select state'"
        formControlName="sf_state">
        <ng-template #itemTmpl let-item>{{ item.item }}</ng-template>
      </cdk-select>
      <cdk-error *ngIf="controlHasError(form, 'sf_state', 'required')"
        >This field is required</cdk-error
      >
    </cdk-form-group>
  </div>
  <div class="col-12 col-md-6">
    <cdk-form-group [class.form-group-fill]="!!form.get('sf_city').value">
      <label>City</label>
      <input
        type="text"
        cdkInput
        placeholder="City"
        (keydown.enter)="next()"
        formControlName="sf_city" />

      <cdk-error *ngIf="controlHasError(form, 'sf_city', 'required')"
        >This field is required</cdk-error
      >
    </cdk-form-group>
  </div>
  <div class="col-12 col-md-6">
    <cdk-form-group [class.form-group-fill]="true">
      <label>ZIP Code</label>
      <input
        type="text"
        cdkInput
        formControlName="sf_zip_code"
        placeholder="00000"
        maxlength="5"
        minlength="5"
        inputRestriction="onlyNumbers"
        (keydown.enter)="next()" />
      <cdk-error *ngIf="controlHasError(form, 'sf_zip_code', 'required')"
        >This field is required</cdk-error
      >
    </cdk-form-group>
  </div>
  <div class="col-12 d-flex mt-4 me-2 ms-2" *ngIf="isPreregisterRole">
    <mat-slide-toggle
      formControlName="sf_same_mailing"
      (change)="onMailingAddressChange()">
      My mailing address is the same <br />
      as my home address
    </mat-slide-toggle>
  </div>

  <div class="d-flex justify-content-around mt-5 gx-2">
    <div>
      <button cdkButton class="go-back" (click)="goBack()">Go back</button>
    </div>
    <div>
      <button
        cdkButton
        cdkFocusInitial
        type="submit"
        class="text-light"
        [disabled]="form.invalid">
        Next
      </button>
    </div>
  </div>
</form>
