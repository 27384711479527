export interface ResponseRegisterParam {
  register_params: RegisterParam;
}

export interface RegisterParam {
  dataForParameters: DataForParameters;
  FormSteps__c: string;
  showLanguagePreference: boolean;
  showPaymentPreference: boolean;
  showUploadPaymentDocument: boolean;
  parameter_nine: boolean;
}

export interface DataForParameters {
  isAgencyRepresentative: boolean;
  accountCreated: boolean;
  fieldCommisionPaid: string;
  fieldCommunication: string;
  accountName: string;
}
