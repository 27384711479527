export interface Columns {
  name: string;
  display: string;
  htmlContent?: boolean;
}

export interface StatusColor {
  [key: string]: string;
}

export interface FilterParams {
  'search'?: string[];
  'IdsAgent'?: string[];
  'effectiveDate'?: EffectiveDate;
  'lineOfBusiness'?: string[];
  'state'?: string[];
  'status'?: string[];
  'policyType'?: string[];
  url?: string;
}

export interface EffectiveDate {
  fromDate: string;
  toDate: string;
}

export interface DirectEffectiveDate {
  value: Date | any;
  text: string;
}

export interface Paginator {
  per_page: number | string;
  url: string;
}
