import { Directive, TemplateRef, ViewContainerRef, OnInit, Input } from '@angular/core';
import { UsersService } from '@app/services/users/users.service';

@Directive({
  selector: '[hideDirective]',
})
export class HideDirective implements OnInit {
  private permissionAccepted = '';

  @Input()
  set hideDirective(permission: string) {
    this.permissionAccepted = permission;
  }

  constructor(
    private template: TemplateRef<any>,
    private container: ViewContainerRef,
    private _user: UsersService
  ) { }

  ngOnInit(): void {
    if (this._user.getActiveAsr()) this.checkPermissions(this._user.getRole().asrPermissions);
  }

  checkPermissions(userPermissions: string[] | undefined): void {
    this.container.clear();
    if (userPermissions && userPermissions.includes(this.permissionAccepted)) this.container.createEmbeddedView(this.template);
  }
}
