import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpService} from '../http/http.service';
import * as qs from 'qs';

@Injectable({
  providedIn: 'root',
})
export class PowerbiService {
  constructor(protected http: HttpService) { }

  public get(role: string, name: string): Observable<any> {
    const url = `powerbi/token/${role}/${name}`;

    return this.http.getModule<any>(url).pipe(map((res) => res.data));
  }

  public getDashboards(params: {role: string; dashboards: string[]}) {
    const url = `powerbi/tokens?` + qs.stringify(params);

    return this.http.getModule(url).pipe(map((res) => res.data));
  }
}
