import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent {
  private acceptance = false;
  public message: Record<string, any>;

  constructor(
    private dialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: Record<string, any>
  ) {
    this.message = this.data;
  }

  close($event: boolean) {
    this.acceptance = $event;
    this.dialogRef.close({accept: this.acceptance});
  }
}
