import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  Host,
} from '@angular/core';
import {ControlValueAccessor, FormControl, FormControlDirective, FormControlName, NG_VALUE_ACCESSOR, NgModel} from '@angular/forms';
import clone from '@app/utils/clone';
import {PerfectScrollbarComponent} from 'ngx-perfect-scrollbar';
import {BehaviorSubject, Subscription} from 'rxjs';
import {InputContainer} from '../input/form-group/form-group.component';
import {MdFormDirective} from '../input/md-form.directive';

interface OptionItem<T> {
  $$track: any;
  option: T;
}

type V<_V, P> = _V | P | _V[] | P[];

@Component({
  selector: 'cdk-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  // @ts-ignore
  host: {
    'class': 'form-control',
    '[class.cursor-pointer]': '!disabled',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent<Option, Value = Option>
implements ControlValueAccessor, OnDestroy, OnChanges, AfterViewInit {
  @ViewChild(PerfectScrollbarComponent, {static: false})
    perfectScrollbar: PerfectScrollbarComponent;

  @ViewChild('selectInput', {static: true}) selectorRef: ElementRef<HTMLInputElement>;

  /**
   * NgTemplateRef of option. Use as selected fallback when selectedTmpl not provided.
   */
  @ContentChild('itemTmpl', {static: true, read: TemplateRef})
    itemTmpl: TemplateRef<any>;

  @ContentChild('popupFooterTmpl', {static: true, read: TemplateRef})
    popupFooterTmpl: TemplateRef<any>;
  @ContentChild('popupHeaderTmpl', {static: true, read: TemplateRef})
    popupHeaderTmpl: TemplateRef<any>;

  /**
   * NgTemplateRef for selected item.
   */
  @ContentChild('selectedTmpl', {static: true, read: TemplateRef})
    selectedTmpl: TemplateRef<any>;

  /**
   * If show search field when focus
   */
  @HostBinding('class.searching') @Input() searchable: boolean;

  // TODO seria fino si secoloca el icono de prepend variable
  @Input() prependIcon: boolean;

  /**
   * A key present in option object to be used as search content.
   */
  @Input() searchField: string;

  /**
   * Use key/return as emitted value.
   * If defined, the value will be emitted as selected value
   */
  @Input() trackBy: string | Function;

  /**
   * Use key/return as options match
   * Used to check of an option is equal to another. Userful when the options os an object and didn't has a trackBy value.
   */
  @Input() matchBy: string | Function;

  /**
   * Used to check/uncheck all options when multi = true
   */
  @Input() selectAll = false;

  // Icon for selector
  @Input() icon: string;

  /**
   * Use true to transparent background
   */
  @Input()
  @HostBinding('class.bg-transparent')
  @HostBinding('class.border-0')
  @HostBinding('class.p-0')
  @HostBinding('class.h-100')
    noBackground = false;

  /**
   * Use true to width 100% and height 100%
   */
  @Input()
  @HostBinding('class.w-100')
  @HostBinding('class.h-100')
  @HostBinding('class.text-center')
    full = false;

  @Input() @HostBinding('class.disabled') disabled: boolean;
  @Input() placeholder = 'Select an option';
  @Input() placeholderSize = 16;
  @Input() placeholderColor = '#919398';
  @Input() multi: boolean;

  optionsList: OptionItem<Option>[] | undefined = [];
  @Input() options: Option[] = [];

  @Input() value: V<Value, Option>;
  @Output() valueChange = new EventEmitter<V<Value, Option>>();

  checkedItemsMap = {};
  selectedOption: Option | Option[];
  @Output() selectedOptionChange = new EventEmitter<Option | Option[]>();

  @Output() nextBatch: EventEmitter<string> = new EventEmitter();

  @Output() onClose: EventEmitter<boolean> = new EventEmitter();

  @Input() startOpened = false;
  @Input() optionsHeight = 'auto';
  @Input() direction = 'ltr';
  @Input() closeOnSelection = true;

  @Input() selectedClass:string;
  @Input() optionsCustomClass:string;
  @Input() showSelected = true;
  @Input() styleMarginBottom: number;
  @Input() stylePaddingTop: number;
  @Input() dropdownItemClass:string;

  @HostBinding('class.focus') showPopover: boolean;

  searchString: string;
  search$ = new BehaviorSubject<string>(null);
  results$ = new BehaviorSubject<OptionItem<Option>[]>(null);
  resultFocusIndex = 0;
  selectAllValue = false;
  itemsToDetach = [];

  private subs: { [s: string]: Subscription } = {};
  private onChange = (_: V<Value, Option>) => {};
  private onTouched = () => {};

  constructor(
    // @Host() @Optional() private formControlName: FormControlName,
    // @Host() @Optional() private formControl: FormControlDirective,
    // @Host() @Optional() private ngModel: NgModel,
    @Optional() private inputContainer: InputContainer,
    @Optional() private mdForm: MdFormDirective,
    public elementRef: ElementRef<HTMLDivElement>,
    private _cd: ChangeDetectorRef
  ) {
    this.subs['search$'] = this.search$.subscribe((string) => {
      if (string) {
        const results = this.optionsList.filter((opt) =>
          this.getSearchString(opt.option).includes(string.toUpperCase())
        );
        this.resultFocusIndex = 0;
        this.results$.next(results);
        this._cd.markForCheck();
      } else {
        this.results$.next(this.optionsList);
      }
    });
  }

  // get control(): FormControl | null {
  //   if (this.formControl) {
  //     return this.formControl.control;
  //   }

  //   if (this.formControlName) {
  //     return this.formControlName.control
  //   }

  //   if (this.ngModel) {
  //     return this.ngModel.control;
  //   }
  // }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.startOpened) {
        this.elementRef.nativeElement.click();
      }
    });

    // if (this.inputContainer) {
    //   this.inputContainer.setFormControl(this.control)
    // }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['direction']) {
      this.direction = changes['direction'].currentValue;
    }
    if (changes['options']) {
      if (!this.options) {
        this.options = [];
        return;
      }

      this.optionsList = this.options.map((opt) => ({
        $$track: this.getOptionID(opt),
        option: opt,
      })) || undefined;
      this.setSelectedOption(this.value);
      if (this.multi) {
        if (this.value) {
          const prevValue = clone(this.value);
          this.value = (this.selectedOption as Option[]).map((o) =>
            this.getOptionValue(o)
          );
          if (
            (prevValue as Value[]).length !== (this.value as Value[]).length
          ) {
            this._cd.detectChanges();
            setTimeout(() => {
              this.emitChange();
            });
          }
        }
        this.genSelectedOptionsMap();
      }
      if (this.searchString) {
        this.search$.next(this.searchString);
      } else {
        this.results$.next(this.optionsList);
      }
    }
    if (changes['value']) {
      this.selectedOption = changes['value'].currentValue;
    }
  }

  ngOnDestroy(): void {
    this.search$.complete();
    Object.values(this.subs).map((s: Subscription) => s.unsubscribe());
  }

  get selectorWidth(): number{
    return this.selectorRef.nativeElement.clientWidth;
  }
  
  select(item: OptionItem<Option>) {
    if (this.multi) {
      this.toggleSelection(item);
    } else {
      this.setSelectedItem(item.option);
    }
    this.setSelectedOption(this.value);
    this.selectedOptionChange.emit(this.selectedOption);
    if (this.closeOnSelection) {
      this.close();
    }
  }

  setSelectedItem(option: Option) {
    const value = this.getOptionValue(option);
    if (
      this.getOptionMatchValue(this.value as Value) !==
      this.getOptionMatchValue(value)
    ) {
      this.value = value;
      this.emitChange();
    } else {
      this._cd.markForCheck();
    }
  }

  toggleSelection({$$track, option}: OptionItem<Option>) {
    if (!this.value) {
      this.value = [];
    }
    if (this.checkedItemsMap[$$track]) {
      const index = (this.value as Value[]).findIndex(
        (v) => (this.getOptionID(v) || v) === this.getOptionID(option)
      );
      if (index === -1) {
        return;
      }
      (this.value as Value[]).splice(index, 1);
      this.checkedItemsMap[$$track] = false;
    } else {
      (this.value as Value[]).push(this.getOptionValue(option));
      this.checkedItemsMap[$$track] = true;
    }
    this.emitChange();
  }

  private emitChange() {
    this.valueChange.emit(this.value);
    if (this.mdForm) {
      this.mdForm.active = this.multi ?
        (this.value as []).length !== 0 :
        !!this.value;
    }
    this.onTouched();
    this.onChange(this.value);
    this._cd.markForCheck();
  }

  @HostListener('click', ['$event'])
  toggleResults() {
    if (this.disabled) {
      return;
    }

    this.showPopover = !this.showPopover;

    if (this.showPopover) {
      const option = this.findOption(this.value);
      const valueIndex = this.options.indexOf(option);
      this.resultFocusIndex = valueIndex !== -1 ? valueIndex : 0;
      this.searchString = (option || {})[this.searchField];
      this.results$.next(this.optionsList);

      if (this.searchable) {
        this._cd.detectChanges();
        const input = this.elementRef.nativeElement.querySelector('input');
        if (input) {
          input.focus();
          setTimeout(() => {
            input.setSelectionRange(0, String(this.searchString).length);
          });
        }
      }
    } else {
      this.searchString = '';
    }
  }

  focusResult(val) {
    const total = this.results$.getValue().length;
    this.resultFocusIndex = (this.resultFocusIndex + val) % total;
    if (this.resultFocusIndex < 0) {
      this.resultFocusIndex = total - 1;
    }

    this._cd.detectChanges();
    if (this.perfectScrollbar) {
      this.perfectScrollbar.directiveRef.scrollToElement('.focus', -100, 150);
    }
  }

  selectFocusResult() {
    this.select(this.results$.getValue()[this.resultFocusIndex]);
  }

  close = () => {
    this.showPopover = false;
    this.onTouched();
    this.onClose.emit(true);
    this._cd.detectChanges();
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: V<Value, Option>): void {
    if (!value) {
      this.selectedOption = undefined;
      this.checkedItemsMap = {};
      this.value = undefined;
      this._cd.detectChanges();
      return;
    }
    this.value = value;
    this.setSelectedOption(this.value);
    if (this.multi) {
      this.genSelectedOptionsMap();
    }
    if (this.mdForm) {
      this.mdForm.active = !!this.value;
    }
    this._cd.detectChanges();
  }

  private setSelectedOption(value: V<Value, Option>) {
    if (this.multi) {
      this.selectedOption = ((value as Value[]) || [])
        .map((v) => this.findOption(v))
        .filter((v) => v);
    } else {
      this.selectedOption = this.findOption(value);
    }
  }

  private genSelectedOptionsMap() {
    if (!this.value) {
      return;
    }
    this.checkedItemsMap = (this.selectedOption as Option[]).reduce(
      (acc, v) => ({...acc, [this.getOptionID(v)]: true}),
      {}
    );
  }

  private getOptionID(option: Option | Value): any {
    return this.trackBy ?
      this.getOptionTrackValue(option) :
      this.getOptionMatchValue(option);
  }

  private getOptionValue(item: Option | Value): Value {
    return this.trackBy ? this.getOptionTrackValue(item) : (item as Value);
  }

  private getOptionTrackValue(item: Option | Value): Value {
    if (item && typeof this.trackBy === 'string') {
      return item[this.trackBy];
    }
    if (item && typeof this.trackBy === 'function') {
      return this.trackBy(item);
    }
    return item as Value;
  }

  private getOptionMatchValue(item: Option | Value): Value {
    if (item && typeof this.matchBy === 'string') {
      return item[this.matchBy];
    }
    if (item && typeof this.matchBy === 'function') {
      return this.matchBy(item);
    }
    return item as Value;
  }

  private findOption(obj, _array: Option[] = this.options): Option {
    if (this.trackBy && obj !== undefined && obj !== null) {
      // @ts-ignore
      return _array.find(
        (opt) =>
          // @ts-ignore
          opt !== 'hr' &&
          (this.getOptionTrackValue(opt) === this.getOptionTrackValue(obj) ||
            this.getOptionTrackValue(opt) === obj)
      );
    }
    if (this.matchBy && obj !== undefined && obj !== null) {
      // @ts-ignore
      return _array.find(
        (opt) =>
          // @ts-ignore
          opt !== 'hr' &&
          this.getOptionMatchValue(opt) === this.getOptionMatchValue(obj)
      );
    }
    return _array.find((item) => item === obj);
  }

  private getSearchString(option: Option) {
    return String(option[this.searchField] || option).toUpperCase();
  }

  public selectAllOptions(event?: MouseEvent) {
    event?.stopPropagation();
    const totalResults = this.results$.getValue()?.length;
    this.selectAllValue = totalResults !== (this.value as Value[]).length;
    this.selectAllValue ?
      this.writeValue(this.options.map((opt) => this.getOptionTrackValue(opt))) :
      this.writeValue([]);
    this.emitChange();
  }
}
