export class FiltersName {
  static search = {
    value: 'search',
    label: 'Custom Search',
  };
  static nameFilter = {value: 'name'};
  static arc = {value: 'arc', label: 'ARC'};
  static carrier = {value: 'carrier', label: 'Company'};
  static disposition = {value: 'disposition'};
  static lineOfBusiness = {
    value: 'line of business',
  };
  static reconciliationMonth = {
    value: 'reconciliation month',
  };
  static effectiveDate = {value: 'effective date', label: 'Effective Date'};
  static status = {value: 'status'};
  static policyType = {value: 'policy type'};
  static dayPastDue = {value: 'day past due'};
  static documentsNeeded = {value: 'documents needed'};
  static paymentDisposition = {value: 'payment disposition'};
  static dateReceived = {value: 'date received'};
  static state = {value: 'state'};
  static productType = {value: 'product type'};
  static physician = {value: 'physician'};
  static agent = {value: 'agent'};
  static dates = {value: 'dates'};
  static logsDate = {value: 'Logs Date'};
}

export class DateFiltersName {
  static dob = {value: 'dob'};
  static effectiveDate = {value: 'effective date'};
  static createdDate = {value: 'created date'};
  static reconciliationMonth = {value: 'reconciliation month'};
  static dateReceived = {value: 'date received'};
  static year = {value: 'year'};
  static logsDate = {value: 'Logs Date'};
}
