import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ContractsService } from '@app/services/contracts/contracts.service';
import { Contract } from '@app/models';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent implements OnInit {
  term: Contract;

  constructor(private dialog: MatDialogRef<PrivacyPolicyComponent>, private contracts: ContractsService) { }

  ngOnInit(): void {
    this.contracts.getContract('privacy-policy').subscribe((res) => {
      this.term = res.data?.term;
    });
  }

  close() {
    this.dialog.close();
  }
}
