<form autocomplete="off">
  <div [tabindex]="0" id="filter-input-component">
    <div
      class="filter-input-container d-flex align-items-center justify-content-between">
      <ng-container *ngIf="selectedItem && showSelected; else searchTmpl">
        <ng-container
          [ngTemplateOutlet]="selectedTmpl || itemTmpl || defaultItemTmpl"
          [ngTemplateOutletContext]="{
            $implicit: selectedItem
          }"></ng-container>
      </ng-container>
      <ng-template #searchTmpl>
        <input
          type="text"
          name="input"
          id="input"
          (input)="onSearch($event)"
          (focus)="openOptions()"
          (blur)="closeOptions($event)"
          [placeholder]="placeholder" />
        <div class="input-icon d-flex align-items-center justify-content-end">
          <span cdkIcon="outlined" *ngIf="icon">{{ icon }}</span>
        </div>
      </ng-template>
    </div>
    <div
      scrollSpy
      class="options-container"
      [ngClass]="{ open: isOpen }"
      (scroll)="onScroll($event)">
      <ng-container *ngFor="let item of items; let index = index">
        <ng-container *ngTemplateOutlet="itemRenderTemplate"></ng-container>
        <ng-template #itemRenderTemplate>
          <div
            class="option"
            [ngClass]="item === selectedItem ? selectedClass : ''"
            [class.focus]="item === selectedItem || index === resultFocusIndex"
            (click)="onChangeSelected(item)">
            <ng-container
              *ngTemplateOutlet="
                itemTmpl || defaultItemTmpl;
                context: { $implicit: item }
              "></ng-container>
          </div>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="isLoading">
        <div
          class="d-flex align-items-center justify-content-center py-3 loader">
          loading...
        </div>
      </ng-container>
    </div>
  </div>
</form>
<ng-template #defaultItemTmpl let-item>
  {{ item }}
</ng-template>
