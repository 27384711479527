export interface LocalPicklistResponse {
  data: {
    values: LocalPicklistOption[];
  };
  msg: string;
}

export interface LocalPicklistOption {
  id?: number;
  name: string;
  sf_value: string;
  active?: boolean;
  parent_id?: null;
  picklist_id?: number;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: null;
  children?: any[];
}

export interface PaymentMethod {
  paymentMethod: string;
  paymentType: string[];
  ssn: boolean;
  autopay: boolean;
}
