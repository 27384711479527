import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Module, Role } from '@app/models';
import { UsersService } from '@app/services/users/users.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchInputComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput') searchInput!: ElementRef;
  offsetWidth: 0;
  items: Module[] = [];
  _items: Module[] = [];
  searchText = '';
  popupVisible = false;
  indexSelected = -1;
  currentRole: number;

  showPopover = true;

  private subscriptions: Record<string, Subscription> = {};

  constructor(
    private userService: UsersService,
    private route: Router,
    private elementRef: ElementRef
  ) {
    this.subscriptions.user = this.userService.userRole$.subscribe({
      next: (userRole: Role) => {
        this.currentRole = userRole.id;
        this._items = userRole.modules
          .map(({items}) => {
            if (!items) return;
            return items;
          })
          .filter((data) => !!data)
          .flat();
      },
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.offsetWidth = this.searchInput?.nativeElement?.offsetWidth;
    }, 250);
  }

  ngOnDestroy(): void {
    Object.values(this.subscriptions).map((s) => s.unsubscribe());
  }

  filter(event: any) {
    this.searchText = event.target.value;

    this.popupVisible = true;
    this.items = [...this._items];

    if (!this.searchText) {
      this.items = [];
      return;
    }

    this.items = this.items.filter((item: any) => {
      return (
        item.name.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1
      );
    });

    this.items.forEach((item: any) => {
      const name = this.highlightMatches(item.name, this.searchText);
      item._name = name;
    });
  }

  highlightMatches(text: string, searchText: string) {
    const regex = new RegExp(`(${searchText})`, 'gi');
    return text.replace(regex, '<span class="highlight">$1</span>');
  }

  hidePopup() {
    setTimeout(() => {
      this.popupVisible = false;
    }, 250);
  }

  showPopup() {
    this.indexSelected = -1;
    this.popupVisible = true;

    const recentSearches: any[] =
      JSON.parse(localStorage.getItem('recent-searches')) ?? [];
    if (recentSearches.length > 0 && this.currentRole) {
      this.items = recentSearches.reduce((total: any[], item: any) => {
        if (item.role === this.currentRole) {
          return [
            ...total,
            {
              ...item,
              recent: true,
            },
          ];
        }

        return total;
      }, []);
    }
  }

  navigate(item: any) {
    this.hidePopup();
    this.searchText = '';

    this.saveRecentSearch(item);

    const {route} = item;
    this.route.navigate([route]);
  }

  removeRecentSearch(item: any, event: any) {
    event.stopPropagation();

    const recentSearches =
      JSON.parse(localStorage.getItem('recent-searches')) ?? [];
    const index = recentSearches.findIndex((_item: any) => _item.id === item.id);

    if (index !== null && index !== undefined) {
      recentSearches.splice(index, 1);
      localStorage.setItem('recent-searches', JSON.stringify(recentSearches));

      if (recentSearches.length > 0) {
        this.showPopup();
      } else {
        this.popupVisible = false;
      }
    }
  }

  saveRecentSearch(item: any) {
    let recentSearches =
      JSON.parse(localStorage.getItem('recent-searches')) ?? [];

    if (!recentSearches.find((_item: any) => _item.id === item.id)) {
      recentSearches = [...recentSearches, {...item, role: this.currentRole}];
    }

    localStorage.setItem('recent-searches', JSON.stringify(recentSearches));
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'ArrowUp') {
      event.preventDefault();

      if (this.indexSelected > 0) {
        this.indexSelected--;
      }
    } else if (event.key === 'ArrowDown') {
      event.preventDefault();

      if (this.indexSelected < this.items.length - 1) {
        this.indexSelected++;
      }
    } else if (event.key === 'Enter') {
      event.preventDefault();

      if (this.indexSelected !== -1) {
        this.navigate(this.items[this.indexSelected]);
      }
    }
  }

  @HostListener('document:click', ['$event'])
  handleClick(event: MouseEvent) {
    const clickedOutside = !this.elementRef.nativeElement.contains(
      event.target
    );
    if (clickedOutside) {
      this.indexSelected = -1;
    }
  }
}
