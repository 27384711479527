import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { RootRoutingModule } from "./root-routing.module";
import { NgxMaskModule } from "ngx-mask";
import { RootComponent } from "./root.component";
import { RouterModule } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ReactiveFormsModule } from "@angular/forms";
import { MediaScreenModule } from "../modules/media-screen/media-screen.module";
import { StoreModule } from "@ngrx/store";
import { reducers } from "@app/store/user";
import { OverlayContainer, OverlayModule } from "@angular/cdk/overlay";
import { MatDialogModule } from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { AuthenticationModule } from "@app/pages/authentication/authentication.module";
import { LoadingModule } from "@app/shared/loading/loading.module";
import { AuthorizationInterceptor } from "@app/interceptors/authorization.interceptor";
import { HttpService } from "@app/services/http/http.service";
import { AuthService } from "@app/services/auth/auth-service.service";
import { UiService } from "@app/services/cdk/ui.service";
import { UsersService } from "@app/services/users/users.service";
import { NotificationsModule } from "@app/shared/notifications/notifications.module";
import { CurrencyMaskConfig, NgxCurrencyModule } from "ngx-currency";
import { BrowserModule } from "@angular/platform-browser";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatDateFormats,
} from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";

export const customCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "left",
  allowNegative: false,
  decimal: ".",
  thousands: ",",
  prefix: "$ ",
  suffix: "",
  allowZero: true,
  precision: 2,
  nullable: true,
  min: null,
  max: null,
};

export const MY_FORMATS: MatDateFormats = {
  parse: {
    dateInput: "MM/DD/YYYY",
  },
  display: {
    dateInput: "MM/DD/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@NgModule({
  imports: [
    RootRoutingModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MediaScreenModule.forRoot(["sm", "md", "lg", "xl"]),
    StoreModule.forRoot({}, {}),
    StoreModule.forFeature("user", reducers),
    OverlayModule,
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    AuthenticationModule,
    LoadingModule,
    NotificationsModule,
    NgxMaskModule.forRoot(),
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    RouterModule,
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: OverlayContainer,
      useFactory: () => {
        const container = document.createElement("div");
        container.classList.add("cdk-overlay-container");
        class Class extends OverlayContainer {
          _createContainer(): void {
            document.querySelector("body").appendChild(container);
            this._containerElement = container;
          }
        }
        return new Class(container, undefined);
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true,
    },
    NgxMaskModule,
    HttpService,
    AuthService,
    UiService,
    UsersService,
    { provide: MAT_DATE_LOCALE, useValue: "en-US" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  declarations: [RootComponent],
  bootstrap: [RootComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class RootModule {}
