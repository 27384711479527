<form [formGroup]="formContract" class="row" #formSign>
  <cdk-form-group class="pt-0 col-12 col-sm-6">
    <div class="d-flex">
      <label class="d-flex align-items-center input-label">
        <span class="me-2">Signature</span>
      </label>
      <span
        styleClass="ps-1 icon-info"
        cdkIcon="outlined"
        matTooltip="Enter name of person with authority to contract on behalf of the entity"
        matTooltipPosition="above"
        >info</span
      >
    </div>
    <input
      type="text"
      cdkInput
      inputRestriction="onlyText"
      [placeholder]="
        'E.g ' + currentUser.sf_first_name + ' ' + currentUser.sf_last_name
      "
      formControlName="signature" />
    <cdk-error *ngIf="controlHasError(formContract, 'signature', 'required')"
      >This field is required</cdk-error
    >
  </cdk-form-group>
  <cdk-form-group class="pt-0 col-12 col-sm-6">
    <div class="d-flex">
      <label class="d-flex align-items-center input-label">
        <span class="me-2">Printed name</span>
      </label>
      <span
        styleClass="ps-1 icon-info"
        cdkIcon="outlined"
        matTooltip="Enter name of person with authority to contract on behalf of the entity"
        matTooltipPosition="above"
        >info</span
      >
    </div>
    <input
      type="text"
      cdkInput
      inputRestriction="onlyText"
      [placeholder]="
        'E.g ' + currentUser.sf_first_name + ' ' + currentUser.sf_last_name
      "
      formControlName="printedName" />
    <cdk-error *ngIf="controlHasError(formContract, 'printedName', 'required')"
      >This field is required</cdk-error
    >
  </cdk-form-group>
  <cdk-form-group class="pt-0 col-12 col-sm-6 mt-0 mt-sm-3">
    <label class="input-label">NPN</label>
    <input
      type="text"
      cdkInput
      inputRestriction="onlyNumbers"
      [placeholder]="currentUser.sf_npn"
      formControlName="npn" />
    <cdk-error *ngIf="controlHasError(formContract, 'npn', 'required')"
      >This field is required</cdk-error
    >
    <cdk-error
      *ngIf="
        formContract.get('npn').value &&
        controlHasError(formContract, 'npn', 'equals')
      "
      >This value is invalid</cdk-error
    >
  </cdk-form-group>
  <cdk-form-group class="pt-0 col-12 col-sm-6 mt-0 mt-sm-3">
    <label class="input-label">Title</label>
    <input
      type="text"
      cdkInput
      placeholder="E.g manager, director, etc."
      formControlName="title" />
    <cdk-error *ngIf="controlHasError(formContract, 'title', 'required')"
      >This field is required</cdk-error
    >
  </cdk-form-group>
</form>
