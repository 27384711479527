import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddressAutocompleteModule } from '@app/modules/@cdk/address-autocomplete/address-autocomplete.module';
import { FormGroupModule } from '@app/modules/@cdk/forms/input/form-group/form-group.module';
import { InputModule } from '@app/modules/@cdk/forms/input/input.module';
import { ButtonModule } from '@app/modules/@cdk/forms/button/button.module';
import { IconModule } from '@app/modules/@cdk/icon/icon.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SelectModule } from '@app/modules/@cdk/forms/select/select.module';
import { NgxMaskModule } from 'ngx-mask';
import { SwitchControlModule } from '@app/shared/switch-control/switch-control.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { HomeAddressComponent } from './home-address.component';

import { AddressAutocompleteGoogleModule } from '@app/modules/@cdk/addres-autocomplete-google/address-autocomplete-google.module';

@NgModule({
  declarations: [HomeAddressComponent],
  imports: [
    CommonModule,
    AddressAutocompleteModule,
    SwitchControlModule,
    FormGroupModule,
    InputModule,
    ButtonModule,
    IconModule,
    ReactiveFormsModule,
    SelectModule,
    NgxMaskModule,
    AddressAutocompleteGoogleModule,
    MatSlideToggleModule,
  ],
})
export class HomeAddressModule {}
