import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '../../modules/@cdk/icon/icon.module';
import { FormGroupModule } from '../../modules/@cdk/forms/input/form-group/form-group.module';
import { InputModule } from '../../modules/@cdk/forms/input/input.module';
import { ButtonModule } from '../../modules/@cdk/forms/button/button.module';
import { CheckboxModule } from '../../modules/@cdk/forms/checkbox/checkbox.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ContractSignComponent } from './contract-sign.component';
import { ContractAgentComponent } from './forms/contract-agent/contract-agent.component';
import { ContractAgencyComponent } from './forms/contract-agency/contract-agency.component';
import { ContractTermsComponent } from './forms/contract-terms/contract-terms.component';
import { ContractCommissionInAdvanceAgentOfAgencyComponent } from './forms/contract-commission-in-advance-agent-of-agency/contract-commission-in-advance-agent-of-agency.component';
import { ContractCommissionInAdvanceDirectAgentComponent } from './forms/contract-commission-in-advance-direct-agent/contract-commission-in-advance-direct-agent.component';
import { SelectModule } from '@app/modules/@cdk';


@NgModule({
  declarations: [
    ContractSignComponent,
    ContractAgentComponent,
    ContractAgencyComponent,
    ContractTermsComponent,
    ContractCommissionInAdvanceAgentOfAgencyComponent,
    ContractCommissionInAdvanceDirectAgentComponent,
  ],

  imports: [
    CommonModule,
    IconModule,
    FormGroupModule,
    InputModule,
    ButtonModule,
    CheckboxModule,
    ReactiveFormsModule,
    FormsModule,
    MatTooltipModule,
    SelectModule
  ],
  exports: [ContractSignComponent],
})
export class ContractSignModule { }
