export function isEmptyArray(element: Array<any>): boolean {
  return !element || (element && element.length === 0);
}

export function isEmptyString(element: string): boolean {
  return !element || (element && String(element).trim() === '');
}

export function isString(s: any): boolean {
  return typeof s === 'string';
}
