<form [formGroup]="formContract" class="row" #formSign>
  <cdk-form-group class="pt-0 col-12 col-sm-6">
    <div class="d-flex">
      <label class="d-flex align-items-center input-label">
        <span class="me-2">Signature</span>
      </label>
      <span
        styleClass="ps-1 icon-info"
        cdkIcon="outlined"
        matTooltip="Enter your full legal name exactly as it appears on official documents, such as your passport."
        matTooltipPosition="above"
        >info</span
      >
    </div>
    <input
      type="text"
      cdkInput
      inputRestriction="onlyText"
      [placeholder]="
        'E.g ' + currentUser.sf_first_name + ' ' + currentUser.sf_last_name
      "
      formControlName="signature" />
    <cdk-error *ngIf="controlHasError(formContract, 'signature', 'required')"
      >This field is required</cdk-error
    >
  </cdk-form-group>
  <cdk-form-group class="pt-0 col-12 col-sm-6">
    <div class="d-flex">
      <label class="d-flex align-items-center input-label">
        <span class="me-2">Printed name</span>
      </label>
      <span
        styleClass="ps-1 icon-info"
        cdkIcon="outlined"
        matTooltip="Enter your full legal name exactly as it appears on official documents, such as your passport."
        matTooltipPosition="above"
        >info</span
      >
    </div>
    <input
      type="text"
      cdkInput
      inputRestriction="onlyText"
      [placeholder]="
        'E.g ' + currentUser.sf_first_name + ' ' + currentUser.sf_last_name
      "
      formControlName="printedName" />
    <cdk-error *ngIf="controlHasError(formContract, 'printedName', 'required')"
      >This field is required</cdk-error
    >
  </cdk-form-group>
  <cdk-form-group class="pt-0 col-12 col-sm-6 mt-0 mt-sm-3">
    <label class="input-label">NPN</label>
    <input
      type="text"
      cdkInput
      inputRestriction="onlyNumbers"
      placeholder="000000000"
      formControlName="npn" />
    <cdk-error *ngIf="controlHasError(formContract, 'npn', 'required')"
      >This field is required</cdk-error
    >
    <cdk-error
      *ngIf="
        formContract.get('npn').value &&
        controlHasError(formContract, 'npn', 'equals')
      "
      >This value is invalid</cdk-error
    >
  </cdk-form-group>
  <cdk-form-group
    class="pt-0 col-12 col-sm-6 mt-0 mt-sm-3"
    [class.form-group-fill]="!!formContract.get('state')"
    [class.form-group-invalid]="
      controlHasError(formContract, 'state', 'required')
    ">
    <div class="d-flex">
      <label class="d-flex align-items-center input-label">
        <span class="me-2">State *</span>
      </label>
      <span
        styleClass="ps-1 icon-info"
        cdkIcon="outlined"
        matTooltip="Select the state where you reside, for example: Florida"
        matTooltipPosition="above"
        >info</span
      >
    </div>
    <cdk-select
      [options]="allStates"
      formControlName="state"
      placeholder="Select an option"
      trackBy="name"
      [icon]="'arrow_drop_down'"
      cdkInput
      [noBackground]="true">
      <ng-template #itemTmpl let-item> {{ item.name }} </ng-template>
    </cdk-select>
    <cdk-error>State is required</cdk-error>
  </cdk-form-group>
</form>
