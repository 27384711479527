import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { HttpService } from '../http/http.service';
import { LocalStorageService } from '../storage/local-storage.service';
import { BehaviorSubject } from 'rxjs';
import { Role } from '../../models/users/user.model';
import { Module } from '@app/models';
import { ModuleAction } from '@app/enums/role';

export interface AuthData {
  username: string;
  password: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authModule: Record<string, string> = {
    login: 'login',
    sendVerificationCode: 'send-verification-code',
    verificateCode: 'verificate-code',
  };

  private authToken: string;

  private resetUser: string;
  private resetToken: string;

  private _isLogin = false;
  public declineWithCounter$ = new BehaviorSubject<boolean>(undefined);

  constructor(
    private http: HttpService,
    private localStorage: LocalStorageService
  ) {}

  loadMenuOptions(
    data: Role[],
    additionalInformation: Record<ModuleAction, string[]>
  ) {
    const othersOption = {
      name: 'Others',
      icon: 'more_horiz',
      order: 0,
      route: '/others',
    };

    const groups = data.map((role: Role) => {
      const parents = role.modules
        .filter(
          m => !m.parent_id && !additionalInformation.EXCLUDE.includes(m.route)
        )
        .map(m => {
          const items = role.modules
            .filter(_m => !!_m.parent_id && _m.parent_id === m.id)
            .map(module => ({
              ...module,
              isPowerBi: module.route.includes('powerbi'),
            }))
            .sort((a, b) => a.pivot.order - b.pivot.order);

          return { ...m, items };
        });

      // @TODO evaluar cargue de modulos adicionales

      // const modules =
      //   additionalInformation.INCLUDE.length > 0
      //     ? [...parents, othersOption]
      //     : parents;

      // return { ...role, modules };

      return { ...role, modules: parents };
    });

    return groups;
  }

  setAuthToken(token: string): void {
    this.authToken = token;
  }

  getAuthToken(): string {
    return this.authToken;
  }

  getUserData() {
    return this.http
      .get(`${this.http.mainUrl}/tenant/v1/profile`)
      .pipe(filter(data => !!data));
  }

  updateUserData(data: Record<string, any>) {
    return this.http.put(`${this.http.mainUrl}/tenant/v1/profile`, data);
  }

  updateUserPassword(data: Record<string, any>) {
    return this.http.put(
      `${this.http.mainUrl}/tenant/v1/profile/update/password`,
      data
    );
  }

  login(data: AuthData) {
    return this.http.postModule(this.authModule['login'], data);
  }

  logout(reload = true) {
    this.localStorage.clear();

    if (reload) {
      (window as Window).location.reload();
    }
  }

  sendVerificationCode(token: string) {
    return this.http.getModule(
      this.authModule['sendVerificationCode'],
      false,
      token
    );
  }

  checkVerificationCode(code: string, trust_device: boolean, token) {
    return this.http.postModule(
      `${this.authModule.verificateCode}/${code}`,
      { trust_device: Number(trust_device).valueOf() },
      token
    );
  }

  clearToken() {
    this.localStorage.deleteItem('token');
  }

  setDefaultRol(id: number) {
    return this.http.put(
      `${this.http.mainUrl}/tenant/v1/profile/update/default-role-id/${id}`,
      {}
    );
  }

  updateData(payload: any) {
    return this.http.put(
      `${this.http.mainUrl}/tenant/v1/profile/update/first`,
      payload
    );
  }

  updateProfileLeadData(payload: any) {
    return this.http.put(
      `${this.http.mainUrl}/tenant/v1/profile-lead/update/first`,
      payload
    );
  }

  updateOnboarding() {
    return this.http.put(
      `${this.http.mainUrl}/tenant/v1/profile/update/onboarding-at`,
      {}
    );
  }

  forgotPassword(username: string) {
    this.setResetUser(username);
    return this.http.post(`${this.http.mainUrl}/tenant/v1/forgot-password`, {
      username,
    });
  }

  resetPassword(data) {
    return this.http.post(
      `${this.http.mainUrl}/tenant/v1/reset-password`,
      data
    );
  }

  setResetUser(user: string) {
    this.resetUser = user;
  }

  getResetUser() {
    return this.resetUser;
  }

  setResetToken(token: string) {
    this.resetToken = token;
  }

  getResetToken() {
    return this.resetToken;
  }

  getActiveSessions() {
    return this.http.getModule(`sessions`).pipe(map(resp => resp.data));
  }

  logoutFromDevice(id: number) {
    return this.http.deleteModule(`sessions/${id}`);
  }

  get isLogin() {
    return this._isLogin;
  }

  set isLogin(isLogin: boolean) {
    this._isLogin = isLogin;
  }
}
