import {ComponentType} from '@angular/cdk/portal';
import {FormGroup} from '@angular/forms';

export interface ConfigView<T> {
  title: string;
  name: string;
  component: ComponentType<T>;
  formGroup?: FormGroup;
}


