<ng-container *ngIf="!authed; else authedMedia">
  <div class="social-media-wrapper d-none d-lg-flex" [class]="backgroundClass">
    <ng-container [ngTemplateOutlet]="socialMediaLinks"></ng-container>
  </div>
  <div
    class="d-flex justify-content-center align-items-center py-5 d-lg-none auth-flex-media">
    <ng-container [ngTemplateOutlet]="socialMediaLinks"></ng-container>
  </div>
</ng-container>

<ng-template #authedMedia>
  <footer class="d-flex align-items-center justify-content-center py-4 px-3">
    <div class="text-center">
      <p>{{ appName }} by {{ company }}</p>
      <div
        class="d-flex align-items-center social-media-row mx-auto"
        [class]="
          socialMediaList.length
            ? 'justify-content-between'
            : 'justify-content-center'
        ">
        <ng-container
          [ngTemplateOutlet]="socialMediaLinks"
          *ngIf="socialMediaList.length; else appLogoTmpl"></ng-container>

        <ng-template #appLogoTmpl>
          <img
            class="footer-image"
            [src]="'assets/img/logo/' + appLogo"
            [alt]="appName" />
        </ng-template>
      </div>
    </div>
  </footer>
</ng-template>

<ng-template #socialMediaLinks>
  <ng-container *ngIf="!authed; else socialMediaAuthed">
    <a
      [href]="social?.url"
      target="_blank"
      rel="noopener noreferrer"
      *ngFor="let social of socialMediaList">
      <i> <img [src]="'/assets/img/social-media/' + social?.icon" /></i>
    </a>
  </ng-container>
  <ng-template #socialMediaAuthed>
    <a
      [href]="social?.url"
      target="_blank"
      rel="noopener noreferrer"
      *ngFor="let social of socialMediaList">
      <i> <img [src]="'/assets/img/social-media/' + social?.auth_icon" /></i>
    </a>
  </ng-template>
</ng-template>
