<div class="container py-4">
    <h4 class="title">{{ term?.name | titlecase }}</h4>
    <div class="text-container">
      <div class="text">
        <div [innerHTML]="term?.content"></div>
        <p *ngIf="!term">loading...</p>
      </div>
    </div>
    <div class="d-flex justify-content-end action">
      <button cdkButton (click)="close()">I agree</button>
    </div>
  </div>
  