import { Injectable } from "@angular/core";
import { HttpService, IHttpResponse } from "../http/http.service";
import { map } from "rxjs";
import { AuthService } from "../auth/auth-service.service";
import { LocalStorageService } from "../storage/local-storage.service";
import { Referral } from "@app/pages/assisted-enrollment/models/referrals";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";

interface IReferralRequestParams {
  token: string;
  domain: string;
}

@Injectable({
  providedIn: "root",
})
export class ProviderService {
  private _referralRequestParams: IReferralRequestParams;

  constructor(
    private _http: HttpService,
    private _localStorage: LocalStorageService,
    private _router: Router
  ) {}

  redirect(url: string) {
    const token_initializer = this._localStorage.getItem("token");

    const queryParams = {
      token_initializer,
      domain: environment.domain ?? (window as Window).location.host,
    };

    const path = this._router
      .createUrlTree(["/login"], { queryParams })
      .toString();

    const fullURL = this._http.normalizeURL(`${url}${path}`);

    (window as Window).open(fullURL, "_blank");
  }

  setReferralRequestParams(token: string, domain: string) {
    this._referralRequestParams = { token, domain };
  }

  evaluateTokenARC(token: string, domain: string) {
    return this._http
      .postModule<any>("arc-provider/connect", {
        token,
        domain,
      })
      .pipe(map(resp => resp.data));
  }

  loadReferral(referral_uuid: string) {
    const { token, domain } = this._referralRequestParams;

    return this._http
      .postModule<IHttpResponse<Referral>>("aca/referral-from-provider", {
        referral_uuid,
        domain,
        token,
      })
      .pipe(map(resp => resp.data.referral));
  }
}
