import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ValidationService} from '@app/services/validation/validation.service';
import {AuthService} from '@app/services/auth/auth-service.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  form: FormGroup = this._fb.group({
    password: [null, [Validators.required]],
    password_confirmation: [null, [Validators.required, this._validations.sameValue('password')]],
    sf_email: [
      null,
      [
        Validators.required,
        Validators.email,
        Validators.pattern(this._validations.emailPattern),
      ],
    ],
    token: [null, [Validators.required]],
  });

  visibility = {
    password: false,
    password_confirmation: false,
  };

  loading = false;

  public passwordRegex =
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}$';

  public passwordValidations = {
    caracters: false,
    uppercase: false,
    number: false,
    symbols: false,
  };

  public validationForm = true;

  constructor(
    private _fb: FormBuilder,
    private _route: ActivatedRoute,
    private _validations: ValidationService,
    private _auth: AuthService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this._route.queryParamMap.subscribe((query) => {
      this.form.patchValue({
        sf_email: query.get('email'),
        token: query.get('token'),
      });

      this._auth.setResetToken(query.get('token'));
    });
  }

  submit() {
    if (this.form.invalid) {
      return;
    }
    this.loading = true;
    this._auth.resetPassword(this.form.value).subscribe((res) => {
      this.loading = false;
      this._router.navigate(['/forgot-password/reset-confirmation']);
    });
  }
}
