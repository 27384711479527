import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DialogModule} from '@angular/cdk/dialog';
import {OverlayModule} from '@angular/cdk/overlay';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MenuComponent, MessageComponent} from './menu';
import {ButtonComponent} from './button';
import {ButtonModule} from '../../forms/button/button.module';
import {MenuModule} from '../../menu/menu.module';
import {IconModule} from '../../icon/icon.module';
import {PopoverModule} from '../../../popover/popover.module';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [
    ButtonComponent,
    MenuComponent,
    MessageComponent,
  ],
  imports: [
    CommonModule,
    ButtonModule,
    MenuModule,
    IconModule,
    PopoverModule,
    MatTooltipModule,
    DialogModule,
    OverlayModule,
    RouterModule,
    PopoverModule,
  ],
  exports: [ButtonComponent],
})
export class NotificationsModule {}
