import { Component, OnInit } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { UiService } from '@app/services/cdk/ui.service';
import { UsersService } from '@app/services/users/users.service';
import { ConfigView } from '@app/models/views/views';
import { Role, User } from '@app/models';
import { StepService } from './services/step.service';
import { UserRole } from '@app/enums/user-role';
import { LocalStorageService } from '@app/services/storage/local-storage.service';

@Component({
  selector: 'app-update-information',
  templateUrl: './update-information.component.html',
  styleUrls: ['./update-information.component.scss'],
})
export class UpdateInformationComponent implements OnInit {
  private steps: { [key: string]: ConfigView<any> };

  private _userRole: Role;
  private _user: User;

  configView: ConfigView<any>;

  currentPortal: ComponentPortal<any>;

  currentIndex = 0;

  init = true;

  isPreregisterRole = false;

  constructor(
    private _ui: UiService,
    private _userService: UsersService,
    private _localStorageService: LocalStorageService,
    private _stepService: StepService
  ) {}

  ngOnInit(): void {
    this._setCurrentUser();
    this.steps = this._stepService.steps;

    this._configView(this.steps);

    this._getStepIndexAndSetupComponentView();

    this._userRole = this._localStorageService.getItem('roles')[0];
    this._setupStepsByUser();

    this._getSteps();
  }

  private _setCurrentUser(): void {
    if (this._userService.hasStorageUser()) {
      this._userService.getUserDetails().subscribe(({ data }: any) => {
        this._userService.setCurrentUser(data);
      });
    }
  }

  private _configView(steps: { [key: string]: ConfigView<any> }): void {
    this.configView = steps.personalInfo;
    this.currentPortal = this._ui.createComponentPortal(
      this.configView.component
    );
  }

  private _getStepIndexAndSetupComponentView(): void {
    this._userService.updateStep$.subscribe(step => {
      const config = Object.keys(this.steps)[step];
      this.loadComponentView(config, step);
    });
  }

  private _getSteps(): void {
    this._stepService.getSteps$().subscribe(setps => {
      this.steps = setps;
    });
  }

  getStepsOptions() {
    return Object.values(this.steps);
  }

  public loadComponentView(step: string, index = 0) {
    this.configView.formGroup.markAllAsTouched();

    if (this.init) {
      this._userService.loadUserData();
      this.init = false;
    }

    if (this.configView.formGroup.invalid && index > this.currentIndex) {
      return;
    }

    this.currentIndex = index;
    this.configView = this.steps[step];
    this.currentPortal = this._ui.createComponentPortal(
      this.configView.component
    );
  }

  private _setupStepsByUser(): void {
    this._userService.fetchUser().subscribe(({ user }) => {
      this._user = user;
      this._changeStepTitleByUserRole();
    });
  }

  private _changeStepTitleByUserRole(): void {
    if (this._userRole?.name === UserRole.PREREGISTER) {
      this.isPreregisterRole = true;
      this.steps.address.title = 'Mailing information';
      this.steps.password.title = 'Password update';
      this._changeStepsByUserRole();
    } else {
      delete this.steps.homeAddress;
    }
  }

  private _changeStepsByUserRole(): void {
    if (
      this._user.sf_same_mailing ||
      this._user.sf_same_mailing === undefined
    ) {
      delete this.steps.homeAddress;
    }
  }
}
