import {Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'cdk-collapse',
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.scss'],
})
export class CollapseComponent implements OnInit {
  id: string;

  @Input() hideShadow = false;
  @Input() showArrow = false;
  @Input() showDivider = false;
  @Input() expanded = false;

  constructor() {}

  ngOnInit(): void {
    this.id = `collapse_${this.generateString(10)}`;
  }

  get conditionalClasses() {
    return {
      'collapse-shadowed': !this.hideShadow,
    };
  }

  generateString(length) {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }
}
