import { ComponentType } from '@angular/cdk/portal';

export interface IPopUp {
  id: number;
  name: string;
  active: boolean;
  class: string;
  start_at: string | Date;
  expire_at: string | Date;
  created_at: string | Date;
  updated_at: string | Date;
  deleted_at: string | Date;
  content: ContentModal;
  type: string;
  show_at_start:number;
  width: number;
  slug: string;
}

export interface ContentModal {
  type: string
  title: string
  value: ValueModal[]
  subtitle: string
}

export interface ValueModal {
  text: string
  value: string
}

export interface IPopUpComponent {
    component: ComponentType<any>;
    config?: Record<string, any>;
    rules?: (...args) => boolean;
    onClose?: (...args) => void;
}

export interface ResponsePopUp {
  msg: string;
  data: IPopUp;
}
