import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {HttpService} from '@app/services/http/http.service';

@Injectable({providedIn: 'root'})
export class PingService {
  constructor(private _http: HttpService) {}

  pingToServer(): Observable<any> {
    const url = 'ping';
    return this._http.getModule<any>(url).pipe(map((res) => res));
  }
}
