import {Injectable} from '@angular/core';
import {HttpService} from '../http/http.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  LocalPicklistOption,
  LocalPicklistResponse,
} from '@app/models/picklist/picklists';

interface PicklistResponse {
  data: PicklistData;
  msg: string;
}

export interface PicklistData {
  values: { [key: string]: string };
}

@Injectable({
  providedIn: 'root',
})
export class PicklistService {
  constructor(private http: HttpService) {}

  getPicklist(
    sobj: string,
    picklist: string
  ): Observable<{ [key: string]: string }> {
    return this.http.getModule(`picklist/${sobj}/${picklist}`).pipe(
      map((res: PicklistResponse) => {
        return res?.data?.values ?? {};
      })
    );
  }

  getLocalPicklist(picklist: string): Observable<LocalPicklistOption[]> {
    return this.http
      .getModule<LocalPicklistResponse>(`local-picklist/${picklist}`)
      .pipe(
        map((res) => {
          return res?.data?.values ?? [];
        })
      );
  }
}
