import {
  COMPONENT_MAP,
  EventOnContract,
  TypeOfSignatures,
} from "@app/models/contracts/contracts";
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { Contract, ContractSign } from "@app/models";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { catchError, finalize, throwError } from "rxjs";

import { Asr } from "@app/enums/localstorage/asr";
import { AuthService } from "@app/services/auth/auth-service.service";
import { ComponentType } from "@angular/cdk/portal";
import { LocalStorageService } from "@app/services/storage/local-storage.service";
import { Router } from "@angular/router";
import { UiService } from "@app/services/cdk/ui.service";
import moment from "moment";
import { ValidationService } from "@app/services/validation/validation.service";
import { ContractsService } from "@app/services/contracts/contracts.service";
import { UsersService } from "@app/services/users/users.service";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";

@Component({
  selector: "app-contract-sign",
  templateUrl: "./contract-sign.component.html",
  styleUrls: ["./contract-sign.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("inOutAnimation", [
      transition(":enter", [
        style({ height: 0, opacity: 0 }),
        animate("210ms ease-out", style({ height: "auto", opacity: 1 })),
      ]),
      transition(":leave", [
        style({ height: "auto", opacity: 1 }),
        animate("210ms ease-in", style({ height: 0, opacity: 0 })),
      ]),
    ]),
  ],
})
export class ContractSignComponent implements OnInit {
  @ViewChild("scrollContainer") scrollContainer!: ElementRef;

  @Input() decline = false;
  @Input() isShowSkip = false;
  @Input() declineRoute: string;
  @Input() successRoute: string;
  @Input() counterToDecline = false;

  @Output() counterContracts: EventEmitter<{
    index: number;
    totalContracts: number;
  }> = new EventEmitter();
  @Output() eventOnContract: EventEmitter<EventOnContract> = new EventEmitter();

  contract: Contract;
  contracts: Contract[] = [];

  sendCopy = false;
  isSendCopyReadOnly = false;
  loading = false;
  message = {
    has: false,
    content: "",
  };

  controlHasError = this._validations.controlHasError;
  indexSign = 1;

  isAsr = false;

  componentName: string | null = null;
  componentMap: Record<string, ComponentType<any>> = COMPONENT_MAP;

  totalContracts = 1;

  lastScrollTop: number = 0;
  hasRead: boolean = false;
  shouldShowBanner: boolean = true;
  isSmallScreen: boolean = false;

  constructor(
    private _auth: AuthService,
    private _router: Router,
    private _localStorage: LocalStorageService,
    private _ui: UiService,
    private _validations: ValidationService,
    private _contractService: ContractsService,
    private _sanitizer: DomSanitizer,
    private _usersService: UsersService
  ) {
    this._validateIfIsAsr();
    const stored = this._localStorage.getItem("contracts") || [];
    this.contracts = stored as Contract[];

    this.totalContracts = stored.length;
  }

  ngOnInit(): void {
    this._setupContractForm();
    this._checkScreenSize();
  }

  /**
   * Obtenemos el contrato, luego poder renderizar
   * el formulario correspondiente y el usuario pueda firmarlo.
   */
  private _setupContractForm(): void {
    if (!this.contracts.length) {
      return;
    }

    this.contract = this.contracts[this.indexSign - 1];

    this.sendCopy = this.contract.send_copy_value;
    this.isSendCopyReadOnly = this.contract.send_copy_read_only;

    this.renderComponentByName(this.contract.front_component);

    this.counterContracts.emit({
      index: this.indexSign,
      totalContracts: this.totalContracts,
    });

    document.getElementById("bodyContract").scrollTop = 0;

    this.hasRead = false;
    this.shouldShowBanner = true;
  }

  /**
   * Realiza la firma de contratos por medio de un EndPoint, realiza las validaciones necesarias
   * dependiendo el formulario para poder seguir el flujo, ademas una vez exitosa la firma de
   * contrato este emite un evento si ya no tiene contratos por firmar, se puede usar para realizar
   * algún tipo de operación desde el componente padre, siempre y cuando no tenga una ruta Success
   */
  submit() {
    if (!this.validateForm()) {
      return;
    }

    this._ui.startLoading();
    this.loading = true;
    this.message.has = false;

    const effectiveDate = moment(new Date()).format("MMMM DD");

    const url = this._router.url;

    const fieldsContract: ContractSign = {
      id_contract: this.contract.id,
      send_copy: this.sendCopy ? 1 : 0,
      effectiveDate,
      ...this._contractService.formContract.getRawValue(),
    };

    const role = this._usersService.getRole();

    this._contractService
      .signContract(fieldsContract, role.name, url)
      .pipe(
        catchError(err => {
          this.loading = false;
          return throwError(() => new Error(err));
        }),
        finalize(() => this._ui.stopLoading())
      )
      .subscribe(res => {
        this.loading = false;
        this._contractService.formContract.reset();
        this.sendCopy = false;

        if (this.indexSign < this.totalContracts) {
          this.indexSign++;
          this._setupContractForm();
          return;
        }

        if (this.successRoute) {
          this._router.navigate([this.successRoute]);
        }

        const resultContract: EventOnContract = {
          contractSign: TypeOfSignatures.SIGNED,
          contract: this.componentName,
        };
        this.eventOnContract.emit(resultContract);
      });
  }

  /**
   * Esta función permite declinar un contrato, no consume algún tipo de servicio para
   * esta acción, emite un evento al ser declinado el contrato, se puede usar para
   * realizar algún tipo de operación desde el componente padre, siempre y cuando no
   * tenga una ruta Declined.
   */
  declineContract() {
    const resultContract: EventOnContract = {
      contractSign: TypeOfSignatures.DECLINED,
      contract: this.componentName,
    };
    this.eventOnContract.emit(resultContract);

    if (this.declineRoute) {
      this._router.navigate([this.declineRoute]);
    }

    if (this.counterToDecline) {
      this._auth.declineWithCounter$.next(true);
    }
  }

  /**
   * Esta función se encarga de conectar con el formulario que se ha vinculado en los
   * demás formularios para poder validar la integridad de la información ingresada,
   * de cumplir todo los criterios este deberá retornar True, de no ser asi retorna False
   *
   * @return Retorna un booleano, este indica el estado del formulario, si esta valido es True, si es invalido es False
   */
  validateForm() {
    return this._contractService.formContract?.valid;
  }

  private _validateIfIsAsr(): void {
    this.isAsr = this._localStorage.getItem(Asr.ASR_STATUS);
  }

  skipToHome(): void {
    if (this.isAsr) {
      this._localStorage.setItem(Asr.SKIP_CONTRACT, true);
      this._router.navigate(["/home"]);
      this._auth.declineWithCounter$.next(false);
    }
  }

  private renderComponentByName(componentName: string) {
    this.componentName = null;
    if (this.componentMap[componentName]) {
      this.componentName = componentName;
    }
  }

  sanitizeInnerHtml(html: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: Event) {
    this._checkScreenSize();
  }

  _checkScreenSize(): void {
    this.isSmallScreen = window.innerWidth <= 576; // sm y menores
  }

  onScroll(event: Event): void {
    const target = event.target as HTMLElement;
    const currentScrollTop = target.scrollTop;

    if (
      Math.round(target.scrollHeight - currentScrollTop) - 100 <=
      target.clientHeight
    ) {
      this.hasRead = true;
      this.shouldShowBanner = false;
      this._contractService.formContract.enable();
      return;
    }

    if (this.isSmallScreen) {
      if (currentScrollTop > this.lastScrollTop && !this.hasRead) {
        this.shouldShowBanner = false;
      } else if (currentScrollTop < this.lastScrollTop && !this.hasRead) {
        this.shouldShowBanner = true;
      }
    }

    this.lastScrollTop = currentScrollTop;
  }
}
