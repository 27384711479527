import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "@app/services/auth/auth-service.service";
import { lastValueFrom, throwError } from "rxjs";
import { filter, catchError, finalize } from "rxjs/operators";
import { ValidationService } from "@app/services/validation/validation.service";
import { OverlayService } from "@app/services/cdk/overlay.service";
import { PrivacyPolicyComponent } from "../../components/privacy-policy/privacy-policy.component";
import { UiService } from "@app/services/cdk/ui.service";
import { HttpErrorResponse } from "@angular/common/http";
import { UsersService } from "@app/services/users/users.service";
import { toRem } from "@app/utils/styles";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent {
  visiblePassword = false;
  rememberUser = false;
  signUp = true;
  verify_url: string | boolean;
  redirectTo: string;

  form: FormGroup = this._fb.group({
    username: [null, [Validators.required]],
    password: [null, [Validators.required]],
  });

  message: string;

  controlHasError = this.validations.controlHasError;

  loading = false;

  constructor(
    private _fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private validations: ValidationService,
    private overlay: OverlayService,
    private auth: AuthService,
    private ui: UiService,
    private user: UsersService
  ) {
    const { verify_url, redirectTo } = this.activatedRoute.snapshot.queryParams;
    this.verify_url = verify_url ?? false;
    this.redirectTo = redirectTo;
  }

  async submit() {
    if (this.form.invalid) {
      this.form.reset();
    } else {
      this.loading = true;
      this.ui.startLoading();
      this.message = "";

      try {
        const resp = await lastValueFrom(
          this.auth.login({ ...this.form.value }).pipe(
            filter(data => !!data),
            catchError((err: HttpErrorResponse) => {
              if (err.status === 422) {
                this.message = "Username or Password Invalid";
              } else {
                this.message = "Unexpected Error";
              }
              this.form.reset();
              return throwError(() => new Error(this.message));
            }),
            finalize(() => {
              this.loading = false;
              this.ui.stopLoading();
            })
          )
        );

        const { msg, data } = resp;
        this.auth.isLogin = true;
        this.auth.clearToken();

        if (data.token_verification) {
          const { token_verification } = data;
          this.auth.setAuthToken(token_verification);
          this.router.navigate([`/verification-code`], {
            relativeTo: this.activatedRoute,
            queryParams: {
              remember: this.rememberUser,
              token: token_verification,
            },
          });

          return;
        }

        this.auth.setAuthToken(data.token);
        this.user.loadLoginData(data, true);

        this.router.navigateByUrl(this.redirectTo ?? "/home");
      } catch (e) {
        this.loading = false;
      }
    }
  }

  rememberMe() {
    this.rememberUser = !this.rememberUser;
  }

  openPrivacyPolicy() {
    this.overlay.openDialog(PrivacyPolicyComponent, {
      maxWidth: toRem(700),
      width: "90%",
    });
  }
}
