import { Component, ViewChild, ElementRef } from "@angular/core";
import { UsersService } from "@app/services/users/users.service";
import { FormGroup } from "@angular/forms";
import { ValidationService } from "@app/services/validation/validation.service";
import { UiService } from "@app/services/cdk/ui.service";
import { UserData } from "@app/models/users/user.model";
import { AddressAutocompleteResponse, ConfigView } from "@app/models";
import { HomeAddressComponent } from "../home-address/home-address.component";
import { StepService } from "../../services/step.service";
import { UserRole } from "@app/enums/user-role";
import { CarrierStateLobService } from "@app/services/carrier-state-lob/carrier-state-lob.service";
import { LocalStorageService } from "@app/services/storage/local-storage.service";

@Component({
  selector: "app-address",
  templateUrl: "./address.component.html",
  styleUrls: ["./address.component.scss"],
})
export class AddressComponent {
  form: FormGroup;
  state;
  states = [];
  isTheSameAddress = true;

  isPreregisterRole = false;

  controlHasError = this.validations.controlHasError;

  @ViewChild("addressInputAutocomplete", { static: true })
  addressInput: ElementRef<HTMLInputElement>;

  constructor(
    private _userService: UsersService,
    private validations: ValidationService,
    private _carrierState: CarrierStateLobService,
    private _ui: UiService,
    private _stepService: StepService,
    private _localStorageService: LocalStorageService
  ) {
    this.form = this._userService.updateStep2;
    this.loadStates();
    this._validateIfIsPreRegisterRole();
  }

  get address(): string {
    return this.form.get("sf_address").value;
  }

  private _validateIfIsPreRegisterRole(): void {
    const role = this._localStorageService.getItem("roles")[0];
    this.isPreregisterRole = role?.name === UserRole.PREREGISTER;
  }

  loadStates() {
    this._ui.startLoading();
    this._carrierState.getResource("states").subscribe({
      next: resp => {
        this.states = resp;

        const stateForm = this._userService.updateStep2.get("sf_state").value;
        const state = this.states.find(
          ({ value_salesforce }) => value_salesforce === stateForm
        );

        this.form.get("sf_state").setValue(state);
      },
      error: () => {
        this._ui.stopLoading();
      },
      complete: () => {
        this._ui.stopLoading();
      },
    });
  }

  updateAddress(event: AddressAutocompleteResponse) {
    const { address, addressData } = event;

    this.state = this.states.find(({ item }) => item === addressData.state);

    this.form.patchValue({
      sf_address: address,
      sf_state: this.state,
      sf_city: addressData.city,
      sf_county: addressData.county,
      sf_zip_code: addressData?.zip_code ?? "",
    });
  }

  goBack() {
    this.saveInformation();
    this._userService.updateStep$.next(0);
  }

  next() {
    this.saveInformation();
    this._userService.updateStep$.next(2);
  }

  saveInformation() {
    const user = this._userService.getUser();
    const token = this._userService.loadUserToken();
    const menu = this._userService.getMenu();

    const dataFrom = this.form.getRawValue();
    dataFrom.sf_state = dataFrom.sf_state?.value_salesforce ?? {};
    const userData: UserData = { menu, token, user: { ...user, ...dataFrom } };
    this._userService.setCurrentUser(userData);
  }

  onMailingAddressChange() {
    const isTheSameAddress = this.form.get("sf_same_mailing").value;
    this._userService.updateStep2HomeAddress
      .get("sf_same_mailing")
      .setValue(isTheSameAddress);

    if (isTheSameAddress) {
      delete this._stepService.steps.homeAddress;
    } else {
      const arraySteps = Object.entries(this._stepService.steps);
      const newData = {
        title: "Home Address",
        name: "homeAddress",
        component: HomeAddressComponent,
        formGroup: this._userService.updateStep2HomeAddress,
      };

      arraySteps.splice(-1, 0, ["homeAddress", newData]);

      const steps: { [key: string]: ConfigView<any> } = arraySteps.reduce(
        (acc, [key, value]) => {
          acc[key] = value;
          return acc;
        },
        {} as { [key: string]: ConfigView<any> }
      );

      this._stepService.steps = steps;
    }
  }
}
