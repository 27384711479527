import {Injectable} from '@angular/core';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import {environment} from 'src/environments/environment';

(window as Window)['Pusher'] = Pusher;

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private echo: Echo;

  wsHost = `${environment.serverUrl}`;
  wsKey = `${environment.wsKey}`;
  constructor() {
    this.echo = this.initWebSocket();
  }

  public getSocketInstance(): Echo {
    return this.echo;
  }

  private initWebSocket() {
    const {production} = environment;

    return new Echo({
      broadcaster: 'pusher',
      cluster: 'mt1',
      key: this.wsKey,
      wsHost: this.wsHost,
      forceTLS: production,
      disableStats: true,
      enabledTransports: ['ws', 'wss'],
    });
  }
}
