<div class="collapse-container" [class]="conditionalClasses">
  <div
    class="collapse-header"
    data-bs-toggle="collapse"
    [attr.data-bs-target]="'#' + id"
    [attr.aria-expanded]="expanded"
    [attr.aria-controls]="id"
  >
    <ng-content select="[cdk-collapse-header]"></ng-content>
    <div class="collapse-header-expand-icon" *ngIf="showArrow">
      <span cdkIcon="outlined">expand_more</span>
    </div>
  </div>
  <span class="collapse-divider" *ngIf="showDivider"></span>
  <div class="collapse" [class.show]="expanded" [id]="id">
    <ng-content select="[cdk-collapse-content]"></ng-content>
  </div>
</div>
