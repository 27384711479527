import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/services/auth/auth-service.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-message-with-counter',
  templateUrl: './message-with-counter.component.html',
  styleUrls: ['./message-with-counter.component.scss'],
})
export class MessageWithCounterComponent implements OnDestroy {
  minutes = 0;
  seconds = 59;
  show = true;
  intervalId: any;
  private subcriptions: Record<string, Subscription> = {};

  constructor(private router: Router, private _auth:AuthService) {
    this.subcriptions['counterMessage'] = this._auth.
      declineWithCounter$.subscribe(
        (open:boolean) =>{
          this.show = open;
          if (this.show === true) {
            this.minutes = 0;
            this.seconds = 60;
            this.startCountdown();
          } else if (this.show === false) {
            this.cancelCount();
          }
        }
      );
  }

  startCountdown() {
    clearInterval(this.intervalId);

    this.intervalId = setInterval(() => {
      if (this.minutes > 0 || this.seconds > 0) {
        if (this.seconds === 0) {
          this.minutes--;
          this.seconds = 59;
        } else {
          this.seconds--;
        }
      } else {
        clearInterval(this.intervalId);
        this._auth.logout();
      }
    }, 1000);
  }

  close():void {
    this.show = false;
    this.minutes = 0;
    this.seconds = 0;
    this._auth.logout();
  }

  ngOnDestroy(): void {
    Object.values(this.subcriptions).map((value) => value.unsubscribe());
  }

  cancelCount():void {
    this.show = false;
    clearInterval(this.intervalId);
  }

  convertTimeToString(second: number) {
    return second < 10 ? `0${second}` : second;
  }
}
