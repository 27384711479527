import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '@app/services/auth/auth-service.service';

@Injectable({
  providedIn: 'root',
})
export class ResetPasswordGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = this.auth.getResetToken();
    const email = this.auth.getResetUser();

    const routeToken = route.queryParamMap.get('token');
    const routeEmail = route.queryParamMap.get('email');

    if (!(token || email) && !(routeToken || routeEmail)) {
      return this.router.navigate(['/login']);
    }

    return true;
  }
}
