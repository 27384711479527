import 'moment-duration-format';
import * as moment from 'moment';

export function humanizeSeconds(
  seconds: number,
  customFormat?: string,
  // @ts-ignore
  customOptions?: moment.DurationFormatSettings
): string {
  return (
    moment
      .duration(seconds, 'seconds')
    // @ts-ignore
      .format(customFormat || 'hh:mm:ss', customOptions || {trim: false})
  );
}
