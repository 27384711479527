<form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()">
  <p class="text-center">
    Your new password must be different to previously used password and contain
    at least:
  </p>
  <app-password-form
    component="update-information"
    [passwordControl]="form.get('password')"
    [passwordConfirmationControl]="form.get('password_confirmation')"
    (validationForm)="validationForm = !$event"
    [styleMarginTop]="1.5"
    (next)="submit()"></app-password-form>
  <div class="d-flex justify-content-around mt-5 gx-2">
    <div>
      <button cdkButton class="go-back" type="button" (click)="goBack()">
        Go back
      </button>
    </div>
    <div>
      <button
        cdkButton
        type="submit"
        class="text-light"
        [disabled]="form.invalid || loading || validationForm">
        Submit
      </button>
    </div>
  </div>
</form>
