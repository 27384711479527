import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable, catchError, map, of } from "rxjs";

import { AuthService } from "@app/services/auth/auth-service.service";
import { Injectable } from "@angular/core";
import { LocalStorageService } from "@app/services/storage/local-storage.service";
import { UsersService } from "@app/services/users/users.service";
import { ProviderService } from "@app/services/arc/provider.service";

@Injectable({
  providedIn: "root",
})
export class LoggedInGuard implements CanActivate {
  private defaultRoute: string;

  constructor(
    private userService: UsersService,
    private router: Router,
    private _auth: AuthService,
    private localStorge: LocalStorageService,
    private _provider: ProviderService
  ) {
    // not todo
    this.defaultRoute = this.localStorge.getItem("defaultRoute") ?? "home";
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const { token_initializer, domain, redirectTo } = route.queryParams;
    if (token_initializer && domain) {
      return this._provider.evaluateTokenARC(token_initializer, domain).pipe(
        map(data => {
          this._auth.setAuthToken(data.token);
          this.userService.loadLoginData(data, true);

          return this.router.parseUrl(redirectTo ?? this.defaultRoute);
        }),
        catchError(err => this.handleError(err))
      );
    }

    if (this.userService.hasStorageUser()) {
      return this.userService.getUserDetails().pipe(
        map(() => this.router.parseUrl(this.defaultRoute)),
        catchError(err => {
          const message = err.error.data.error ?? undefined;
          if (
            err.status === 401 ||
            (message && message === "Unauthenticated.")
          ) {
            this._auth.logout();
            return of(true);
          }
          this.router.parseUrl(this.defaultRoute);
        })
      );
    } else {
      return true;
    }
  }

  handleError(err) {
    const message = err.error?.data.error;
    if (err.status === 401 || message === "Unauthenticated.") {
      this._auth.logout();
      return of(true);
    }
    this.router.navigate([this.defaultRoute]);
  }
}
