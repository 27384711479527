import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {LocalStorageService} from '../storage/local-storage.service';

export type ToggleMenu = 'collapsed' | 'expanded'
@Injectable({
  providedIn: 'root',
})
export class MenuService {
  public toggleSateMenuArcMobile$: BehaviorSubject<ToggleMenu> = new BehaviorSubject('collapsed');
  public toggleSateMoreNavBar$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private storage: LocalStorageService) {
    // not todo
  }

  update(custom_menu: Record<string, any>) {
    this.storage.setItem('menu', custom_menu);
  }

  menu(): Record<string, unknown>[] | void {
    let menu: Record<string, unknown> = {};

    try {
      menu = JSON.parse(this.storage.getItem('menu'));
    } catch (e) {
      new Error(e);
    }
  }

  toggleMenuArcMobile() {
    let stateToggle: ToggleMenu = 'collapsed';

    this.closeMenuArcMobile();

    if (this.toggleSateMenuArcMobile$.value === 'collapsed') {
      stateToggle = 'expanded';
      document.getElementById('app').classList.add('removeScroll');
    }

    this.toggleSateMenuArcMobile$.next(stateToggle);
  }

  closeMenuArcMobile() {
    document.getElementById('app').classList.remove('removeScroll');

    this.toggleSateMenuArcMobile$.next('collapsed');
  }
}
