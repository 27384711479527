export enum RoleEnum {
  AGENT = 'Agent',
  DIRECT_AGENT = 'Direct Agent',
  AGENT_OF_AGENCY = 'Agent of Agency',
  AGENCY = 'Agency',
  ASR = 'ASR',
}

export enum ModuleAction {
  INCLUDE = 'INCLUDE',
  EXCLUDE = 'EXCLUDE',
}
