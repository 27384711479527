import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

const nope = (_?: any) => {
  // not-todo
};

@Component({
  host: {class: 'form-check cursor-pointer d-inline-flex align-items-center'},
  selector: 'cdk-switch',
  template: `<button
    class="checkbox d-flex align-items-center p-2"
    role="switch"
    aria-checked="false"
  ></button>`,
  styles: [
    `
      :host {
        z-index: 0;
        min-height: 1rem !important;
      }
      .checkbox {
        width: 2.688rem;
        height: 1.5rem;
        background: rgba(0, 0, 0, 0.38);
        box-shadow: 0px 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
        border-radius: 0.875rem;
        border: #fff;
        transition: border-color 200ms, background-color 200ms;
        cursor: pointer;
      }
      .checkbox::before {
        content: '';
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 0.75rem;
        background: #ffffff;
        transition: width 200ms, height 200ms, border-color 200ms,
          background-color 200ms;
      }
      .checkbox.on {
        border-radius: 0.875rem;
        background-color: #e8eaf6;
      }
      .checkbox.on::before {
        background: #304ffe;
        width: 1.25rem;
        height: 1.25rem;
        transform: translateX(1.125rem);
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => SwitchComponent),
      multi: true,
    },
  ],
})
export class SwitchComponent
implements ControlValueAccessor, OnInit, OnChanges {
  @Input() disabled: boolean;
  @Input() checked: boolean;
  @Input() name = 'defaultName';
  @Input() controlForm: FormGroup;

  @Output() checkedChange = new EventEmitter<boolean>();
  @ViewChild('input', {static: true, read: ElementRef})
    input: ElementRef<HTMLInputElement>;

  private onChange = nope;
  private onTouched = nope;

  ngOnInit(): void {
    if (this.controlForm) {
      this.checked = this.controlForm.controls[this.name].value;
    }
    const switchElement = document.querySelector('.checkbox');
    if (this.checked) {
      switchElement.classList.add('on');
    }
  }

  constructor() {
    // not to do
  }
  ngOnChanges(changes: SimpleChanges): void {
    const switchElement = document.querySelector('.checkbox');
    if (changes.checked.currentValue) {
      switchElement.classList.add('on');
    } else {
      switchElement.classList.remove('on');
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    this.checked = obj;
  }

  @HostListener('click', ['$event'])
  click(e: MouseEvent) {
    const switchElement = document.querySelector('.checkbox');
    e.stopPropagation();
    if (!this.disabled) {
      this.checked = !this.checked;
      if (this.checked) {
        switchElement.classList.add('on');
      } else {
        switchElement.classList.remove('on');
      }
      if (this.controlForm) {
        this.controlForm.patchValue({[this.name]: this.checked});
      }
      this.checkedChange.emit(this.checked);
    }
  }
}
