import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PrivacyPolicyComponent} from './components/privacy-policy/privacy-policy.component';

import {ContractSignModule} from '@app/shared/contract-sign/contract-sign.module';
import {SocialMediaModule} from '@app/shared/social-media/social-media.module';
import {InputModule} from '@app/modules/@cdk/forms/input/input.module';
import {FormGroupModule} from '@app/modules/@cdk/forms/input/form-group/form-group.module';
import {CheckboxModule} from '@app/modules/@cdk/forms/checkbox/checkbox.module';
import {ButtonModule} from '@app/modules/@cdk/forms/button/button.module';
import {IconModule} from '@app/modules/@cdk/icon/icon.module';
import {ReactiveFormsModule} from '@angular/forms';
import {AuthLayoutModule} from '@app/layout/auth-layout/auth-layout.module';
import {LogoArcModule} from '@app/images/logo-arc/logo-arc.module';
import {NgxMaskModule} from 'ngx-mask';

import {UpdateInformationModule} from './pages/update-information/update-information.module';
import {ContractsModule} from './pages/contracts/contracts.module';
import {LoginModule} from './pages/login/login.module';
import {VerificationCodeModule} from './pages/verification-code/verification-code.module';
import {WelcomeModule} from './pages/welcome/welcome.module';
import {ForgotPasswordModule} from './pages/forgot-password/forgot-password.module';

@NgModule({
  declarations: [
    PrivacyPolicyComponent,
  ],
  imports: [
    CommonModule,
    AuthLayoutModule,
    ButtonModule,
    CheckboxModule,
    ContractSignModule,
    FormGroupModule,
    IconModule,
    InputModule,
    LogoArcModule,
    ReactiveFormsModule,
    SocialMediaModule,
    NgxMaskModule,

    // PAGES
    ContractsModule,
    UpdateInformationModule,
    LoginModule,
    VerificationCodeModule,
    WelcomeModule,
    ForgotPasswordModule,
  ],
})
export class AuthenticationModule { }
