import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SwitchControlComponent} from './switch-control.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SelectModule} from '@app/modules/@cdk/forms/select/select.module';
import {IconModule} from '@app/modules/@cdk/icon/icon.module';
import {MenuItemModule} from '../../modules/@cdk/menu-item/menu-item.module';
import {SwitchControlMobileComponent} from './switch-control-mobile/switch-control-mobile.component';

@NgModule({
  declarations: [SwitchControlComponent, SwitchControlMobileComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SelectModule,
    IconModule,
    MenuItemModule,
  ],
  exports: [SwitchControlComponent],
})
export class SwitchControlModule {}
