import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthLayoutComponent} from './auth-layout.component';
import {SocialMediaModule} from '@app/shared/social-media/social-media.module';

@NgModule({
  declarations: [AuthLayoutComponent],
  imports: [
    CommonModule,
    SocialMediaModule,
  ],
  exports: [
    AuthLayoutComponent,
  ],
})
export class AuthLayoutModule { }
