export interface SaveHomeStep {
  Communications__c?: string;
  Preferred_Language__c?: string;
  AgencyNPN__c?: string;
  CommissionsPaid__c?: string;
  step?: string;
  FormSteps__c?: string;
}

export interface SaveHomeStepResponse {
  resgister_params: HomeStepResponse;
}

interface HomeStepResponse {
  Id: string;
  FormSteps__c: string;
}
