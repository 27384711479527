import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {ModuleStatusEnum} from '@app/models';
import { PingService } from '@app/services/ping/ping.service';
import { UsersService } from '@app/services/users/users.service';
import {IBreadcrumb} from '@app/shared/breadcrumbs/breadcrumbs.component';

@Component({
  selector: 'app-maintenance',
  styleUrls: ['./maintenance.component.scss'],
  template: `
    <div class="inner-container d-flex flex-column h-100 w-100">
      <app-breadcrumbs></app-breadcrumbs>
      <div class="container h-100">
        <div class="d-flex justify-content-center align-items-center align-content-center h-100">
          <div class="d-inline d-md-flex justify-content-between">
            <div class="image mx-auto me-md-5"></div>
            <div class="align-items-center align-content-center text-center">
              <h1 class="title fw-bold">Sorry, we are in <span class="d-block d-md-inline">maintenance</span></h1>
              <p>We're performing essential maintenance to bring you <br> even better features and functionality.</p>
              <br>
              <p>
                We want to hear you! If the problem persists you can <br> report it from
                <span class="support fw-semibold"><img class="align-middle text-center" src="../../../assets/img/maintenance/support.svg"> Support</span>
                or call at <span class="phone">1-800 806 2527</span>
              </p>
              <br>
              <p>Thank you for your patience!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class MaintenanceComponent implements OnInit {
  breadcrumbs: (IBreadcrumb | string)[] = [];

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _userService: UsersService,
    private _pingService: PingService
  ) {}

  ngOnInit(): void {
    const {url} = this._router;
    if (url !== '/maintenance') {
      this._validateIfTheModuleIsActive();
    } else {
      this._pingToServer();
    }
  }

  private _pingToServer() {
    this._pingService.pingToServer().subscribe((_) => {
      this._router.navigate(['/']);
    }, (error) => {
      if (error.status !== 503) {
        this._router.navigate(['/']);
      }
    });
  }

  private _validateIfTheModuleIsActive() {
    this._activatedRoute.queryParams.subscribe((params) => {
      const urlParam = params['module'];

      if (urlParam) {
        const moduleRouterHeaderIsRequired = false;
        this._userService.getModulesByRoute(`?route=${urlParam}`, moduleRouterHeaderIsRequired).subscribe(({data}) => {
          const {module} = data;

          if (module.status === ModuleStatusEnum.ACTIVE) {
            this._router.navigateByUrl(`/${urlParam}`);
          }
        }, ({status}) => {
          if (status === 404) this._router.navigate(['/']);
        });
      }
    });
  }
}
