import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RolChoiseComponent} from './rol-choise.component';

@NgModule({
  declarations: [RolChoiseComponent],
  imports: [
    CommonModule,
  ],
  exports: [RolChoiseComponent],
})
export class RolChoiseModule { }
