import {User, Role} from '@app/models/users/user.model';
import {createReducer, on} from '@ngrx/store';
import {activeAsr, changeRole, login} from './user.actions';

export const user: User = new User();
export const role: Role = {};
export const asr = false;

export const userReducer = createReducer(
  user,
  on(login, (state, data: User) => {
    return { ...state, ...data };
  })
);

export const roleReduce = createReducer(
  role,
  on(changeRole, (state, data: Role) => {
    return {...state, ...data};
  })
);

export const activeAsrReducer = createReducer(
  asr,
  on(activeAsr, (state, data) => {
    const {status} = data;
    return status;
  })
);
