import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SearchInputComponent} from './search-input.component';
import {IconModule} from '../icon/icon.module';
import {FormsModule} from '@angular/forms';
import {PopoverModule} from '@app/modules/popover/popover.module';

@NgModule({
  declarations: [SearchInputComponent],
  imports: [CommonModule, IconModule, FormsModule, PopoverModule],
  exports: [SearchInputComponent],
})
export class SearchInputModule {}
