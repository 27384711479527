import {Directive} from '@angular/core';

@Directive({
  selector: '[cdkSuffix]',
  host: {
    class: 'cdk-input-suffix',
  },
})
export class SuffixDirective {
  constructor() { }
}
