import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {LoginRoutingModule} from './login-routing.module';
import {LoginComponent} from './login.component';
import {InputModule} from '@app/modules/@cdk/forms/input/input.module';
import {FormGroupModule} from '@app/modules/@cdk/forms/input/form-group/form-group.module';
import {CheckboxModule} from '@app/modules/@cdk/forms/checkbox/checkbox.module';
import {ButtonModule} from '@app/modules/@cdk/forms/button/button.module';
import {IconModule} from '@app/modules/@cdk/icon/icon.module';
import {ReactiveFormsModule} from '@angular/forms';
import {AuthLayoutModule} from '../../../../layout/auth-layout/auth-layout.module';


@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    LoginRoutingModule,
    InputModule,
    FormGroupModule,
    CheckboxModule,
    ButtonModule,
    IconModule,
    ReactiveFormsModule,
    AuthLayoutModule,
  ],
  exports: [LoginComponent],
})
export class LoginModule { }
