import {
  ConnectedPosition,
  FlexibleConnectedPositionStrategy,
  FlexibleConnectedPositionStrategyOrigin,
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import {TemplatePortal} from '@angular/cdk/portal';
import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  Optional,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {Subscription} from 'rxjs';
import {PopoverContextmenuTriggerDirective} from './popover-contextmenu-triger.directive';
import {
  PopoverDir,
  PopoverOptions,
  POPOVER_OPTIONS,
} from './popover-options.directive';

@Directive({
  selector: '[popover]',
})
export class PopoverDirective implements OnDestroy {
  private _popover: boolean;

  get popover() {
    return this._popover;
  }

  @Input()
  set popover(show: boolean) {
    this._popover = show;
    if (show) {
      this.createPopover();
    } else if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }

  @Input('popoverClosed') closed: (e: MouseEvent) => void;

  private _target: HTMLElement;

  get target() {
    return this._target;
  }

  @Input('popoverTarget')
  set target(target: HTMLElement) {
    this._target = target;
  }

  @Input('popoverMinwidth') minwidth = false;
  @Input('popoverBackdrop') backdrop = true;
  @Input('popoverWatch') watch = false;
  @Input('popoverDir') dir: PopoverDir = 'ltr';
  @Input('popoverContextmenu') contextmenu = false;
  @Input('popoverHeight') height: number | string = null;
  @Input('popoverIsCloseActionEnable') isCloseActionEnable = true;

  private overlayRef: OverlayRef;
  private positionStrategy: FlexibleConnectedPositionStrategy;
  private templatePortal: TemplatePortal;
  private subs = new Subscription();

  constructor(
    private tpl: TemplateRef<any>,
    private vcr: ViewContainerRef,
    private el: ElementRef,
    private zone: NgZone,
    private _viewContainerRef: ViewContainerRef,
    private _cd: ChangeDetectorRef,
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    @Inject(POPOVER_OPTIONS) @Optional() private options: PopoverOptions,
    @Optional()
    private popoverContextmenuTrigger: PopoverContextmenuTriggerDirective
  ) {
    if (this.popoverContextmenuTrigger) {
      this.subs.add(
        this.popoverContextmenuTrigger.trigger$.subscribe(
          ({origin, options: _options}) => {
            if (this.contextmenu) {
              this.createPopover(origin, _options);
            }
          }
        )
      );
    }
  }

  // eslint-disable-next-line consistent-return
  getDirPositions(dir?: PopoverDir): ConnectedPosition[] {
    const revert = {
      start: 'end',
      end: 'start',
      bottom: 'top',
      top: 'bottom',
      center: 'center',
    };
    const centerY = (pos): ConnectedPosition => ({
      originX: 'center',
      originY: pos,
      overlayX: 'center',
      overlayY: revert[pos],
    });
    const centerX = (pos): ConnectedPosition => ({
      originX: revert[pos],
      originY: 'center',
      overlayX: pos,
      overlayY: 'center',
    });
    const ltr = (axis): ConnectedPosition => ({
      originX: 'start',
      originY: axis,
      overlayX: 'start',
      overlayY: revert[axis],
    });
    const rtl = (axis): ConnectedPosition => ({
      originX: 'end',
      originY: axis,
      overlayX: 'end',
      overlayY: revert[axis],
    });
    const side = (axis, axis2): ConnectedPosition => ({
      originX: axis,
      originY: axis2,
      overlayX: revert[axis],
      overlayY: axis2,
    });

    switch (dir || this.options?.dir || this.dir) {
    case 'ct':
      return [
        centerY('top'),
        centerY('bottom'),
        centerX('start'),
        centerX('end'),
      ];
    case 'cr':
      return [
        centerX('end'),
        centerX('start'),
        centerY('top'),
        centerY('bottom'),
      ];
    case 'cc':
      return [centerX('center')];
    case 'ltr':
      return [ltr('bottom'), ltr('top')];
    case 'rtl':
      return [rtl('bottom'), rtl('top')];
    case 'tltr':
      return [ltr('top'), ltr('bottom')];
    case 'trtl':
      return [rtl('top'), rtl('bottom')];
    case 'rt':
      return [side('end', 'top'), side('end', 'bottom'), ltr('top')];
    case 'rb':
      return [
        side('end', 'bottom'),
        side('end', 'top'),
        ltr('bottom'),
        rtl('bottom'),
      ];
    case 'lt':
      return [side('start', 'top'), side('start', 'bottom'), rtl('top')];
    case 'lb':
      return [side('start', 'bottom'), side('start', 'top'), rtl('bottom')];
    default:
      break;
    }
  }

  createPopover(
    origin?: FlexibleConnectedPositionStrategyOrigin,
    options?: PopoverOptions
  ) {
    this.templatePortal = new TemplatePortal(this.tpl, this._viewContainerRef);
    this.positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(origin || this.target)
      .withPush(true)
      .withGrowAfterOpen(true)
      .withPositions(this.getDirPositions(options?.dir));

    this.overlayRef = this.overlay.create({
      positionStrategy: this.positionStrategy,
      disposeOnNavigation: true,
      direction: 'ltr',
      hasBackdrop: this.backdrop,
      scrollStrategy: this.overlay.scrollStrategies.reposition({}),
      backdropClass: 'cdk-overlay-transparent-backdrop',
      width:
        this.options?.minwidth || this.minwidth ?
          this.target.offsetWidth - 2 :
          undefined,
      maxHeight: '95%',
      height: this.height,
    });

    let resizeObserver: any;

    const subs = this.overlayRef.backdropClick().subscribe((e) => {
      if (!this.isCloseActionEnable) return;

      this.popover = false;

      subs.unsubscribe();
      resizeObserver?.disconnect();
      this.zone.run(() => {
        if (this.closed) {
          this.closed(e);
        }
      });
    });

    const attached = this.overlayRef.attach(this.templatePortal);

    if (this.watch) {
      try {
        if ('ResizeObserver' in window) {
          resizeObserver = new ResizeObserver(([{contentRect}]) => {
            this.overlayRef.updatePosition();
          }).observe(attached.rootNodes[0]);
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  ngOnDestroy(): void {
    this.popover = false;
    this.subs.unsubscribe();
  }
}
