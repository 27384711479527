import {Component, OnInit, Input} from '@angular/core';
import {trigger, state, style, transition, animate} from '@angular/animations';
import {Role} from '@app/models/users/user.model';
import {MenuService} from '@app/services/menu/menu.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'switch-control-mobile',
  templateUrl: './switch-control-mobile.component.html',
  styleUrls: ['./switch-control-mobile.component.scss'],
  animations: [
    trigger('slideDown', [
      state('hidden', style({height: '0', opacity: '0'})),
      state('shown', style({height: '*', opacity: '1'})),
      transition('hidden => shown', animate('300ms ease-in')),
      transition('shown => hidden', animate('300ms ease-out')),
    ]),

  ],
})
export class SwitchControlMobileComponent implements OnInit {
  @Input() roles: Role[] = [];

  public showList: 'shown' | 'hidden' = 'hidden';

  private subscriptions: Record<string, Subscription> = {};

  constructor(
    private _menuService: MenuService
  ) {
    this.subscriptions['menuService'] =
      this._menuService.toggleSateMenuArcMobile$.subscribe(() => {
        this.showList = 'hidden';
      });
  }

  ngOnInit(): void {
  }

  changeStateList() {
    this.showList = this.showList === 'hidden' ? 'shown' : 'hidden';
  }
}
