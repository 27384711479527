import { Injectable } from "@angular/core";
import {
  AccountExecutive,
  Menu,
  Role,
  User,
  UserData,
} from "@app/models/users/user.model";
import { activeAsr, login } from "@app/store/user/user.actions";
import { Store } from "@ngrx/store";
import { BehaviorSubject, filter, Observable, of } from "rxjs";
import { AuthService } from "../auth/auth-service.service";
import { LocalStorageService } from "../storage/local-storage.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ValidationService } from "../validation/validation.service";
import { Router } from "@angular/router";
import { HttpService } from "../http/http.service";
import { UiService } from "../cdk/ui.service";
import { finalize, map, switchMap, tap } from "rxjs/operators";
import moment from "moment";
import { AsrService } from "../asr/asr.service";
import {
  joinAsrTypesModules,
  joinUserModulesWithAsr,
} from "@app/utils/asr-helpers";
import { AsrType, Contract, Module, ModuleDataResponse } from "@app/models";
import { environment } from "src/environments/environment";
import { Asr } from "@app/enums/localstorage/asr";
import { UserRole } from "@app/enums/user-role";
import { MenuTypeEnum } from "@app/enums/module";
import { ModuleAction } from "@app/enums/role";
import { AgencyTree } from "@app/models/tree/tree-agency-subangency.model";
import { agencyTreeToList, agentsTreeToList } from "@app/utils/agency-tree-helpers";

export const ARC_STORAGE_TOKEN = "arc_user";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  private userPrefix = "user";
  private profilePrefix = "profile";
  private modulePrefix = "modules";
  private commissionPayment: string = null;

  private favModules = {
    favorites: `${this.modulePrefix}/favorites`,
    addToFav: (id: number) => `${this.modulePrefix}/${id}/add-to-favorites`,
    removeFromFav: (id: number) =>
      `${this.modulePrefix}/${id}/remove-from-favorites`,
  };

  private contractModules = {
    contracts: `contracts`,
    sign: (id: string) => {
      `${this.contractModules.contracts}/${id}/sign`;
    },
  };

  private profileModule = {
    update: "update",
    firstTime: () => `${this.profileModule.update}/firts`,
    onboarding: () => `${this.profileModule.update}/onboarding-at`,
    terms: () => `${this.profileModule.update}/terms`,
    update_role: () => `${this.profileModule.update}/default-role-id`,
  };

  // todo: todo los type any se tienen que sustituir
  private sessionToken: string;
  private activeAsrSubject = new BehaviorSubject<boolean>(false);
  private userSubject = new BehaviorSubject<User>(null);
  private roleSubject = new BehaviorSubject<Role>(null);
  private navbarModulesSubject = new BehaviorSubject<Role>(null);
  private asrTypesSubject = new BehaviorSubject<AsrType[]>([]);
  private menuSubject = new BehaviorSubject<Menu>(null);
  private rolesSubject = new BehaviorSubject<Record<string, any>[]>([]);
  private accountExecutiveSubject = new BehaviorSubject<AccountExecutive>(null);
  private currentModuleSubject = new BehaviorSubject<Module>(null);

  private updateInfoModuleSubject = new BehaviorSubject<string>(null);

  private includeModulesSubject = new BehaviorSubject<Module[]>([]);
  private excludeModulesSubject = new BehaviorSubject<Module[]>([]);

  public readonly user$: Observable<Record<string, any>> =
    this.userSubject.pipe(filter(data => !!data));

  public readonly userRole$: Observable<Role> = this.roleSubject.pipe(
    filter(data => !!data)
  );

  public readonly navbarModules$: Observable<any> =
    this.navbarModulesSubject.pipe(filter(data => !!data));

  public readonly menu$: Observable<Menu> = this.menuSubject.pipe(
    filter(data => !!data)
  );

  public readonly activeAsr$: Observable<boolean> = this.activeAsrSubject;

  public readonly userRoles$: Observable<Record<string, any>> =
    this.rolesSubject.pipe(filter(data => !!data));

  public readonly userAsrTypes$: Observable<AsrType[]> =
    this.asrTypesSubject.pipe(filter(data => !!data));

  public readonly accountExecutive$: Observable<AccountExecutive> =
    this.accountExecutiveSubject.pipe(filter(data => !!data));

  private pendingContracts$ = new BehaviorSubject<Contract[]>([]);

  public readonly contracts$: Observable<Contract[]> =
    this.pendingContracts$.pipe(filter(data => !!data));

  public readonly updateInfoModule$: Observable<string> =
    this.updateInfoModuleSubject.pipe(filter(data => !!data));

  public readonly currentModule$: Observable<Module> =
    this.currentModuleSubject.pipe(filter(data => !!data));

  public readonly includeModules$: Observable<Module[]> =
    this.includeModulesSubject.pipe(filter(data => !!data));

  public readonly excludeModules$: Observable<Module[]> =
    this.excludeModulesSubject.pipe(filter(data => !!data));

  public remember: boolean;

  private _updateForm: FormGroup = this.fb.group({
    step1: this.fb.group({
      sf_first_name: [null, [Validators.required, Validators.pattern(/\D+/)]],
      sf_last_name: [null, [Validators.required, Validators.pattern(/\D+/)]],
      sf_birth_day: [null, [Validators.required]],
      locale: [null],
      sf_ssn: [null, [Validators.required]],
      sf_email: [
        null,
        [
          Validators.required,
          Validators.email,
          Validators.pattern(this.validations.emailPattern),
        ],
      ],
      sf_phone: [null, [Validators.required]],
      sf_ffm: [null, [Validators.required]],
    }),
    step2: this.fb.group({
      sf_address: [null, [Validators.required]],
      sf_state: [null, [Validators.required]],
      sf_city: [null, [Validators.required]],
      sf_county: [null, [Validators.required]],
      sf_zip_code: [null, [Validators.required]],
      sf_same_mailing: [1],
    }),
    step2HomeAddress: this.fb.group({
      sf_address: [null, [Validators.required]],
      sf_state: [null, [Validators.required]],
      sf_city: [null, [Validators.required]],
      sf_county: [null, [Validators.required]],
      sf_zip_code: [null, [Validators.required]],
      sf_same_mailing: [1],
    }),
    step3: this.fb.group({
      current_password: [null],
      password: [null, [Validators.required]],
      password_confirmation: [
        null,
        [Validators.required, this.validations.sameValue("password")],
      ],
    }),
  });

  private accountSettingsForm: FormGroup = this.fb.group({
    profile: this.fb.group({
      sf_first_name: [null, [Validators.required, Validators.pattern(/\D+/)]],
      sf_last_name: [null, [Validators.required, Validators.pattern(/\D+/)]],
      sf_npn: [{ value: null, disabled: true }],
      sf_phone: [null, [Validators.required]],
      sf_email: [
        null,
        [
          Validators.required,
          Validators.email,
          Validators.pattern(this.validations.emailPattern),
        ],
      ],
    }),
    payment: this.fb.group({
      paymentData: [null, [Validators.required]],
      paymentDocuments: [null, Validators.required],
    }),
  });

  updateStep$ = new BehaviorSubject<number>(0);

  private clientIPSubject = new BehaviorSubject<string>("");
  public readonly clientIP$: Observable<string> = this.clientIPSubject.pipe(
    filter(data => !!data)
  );

  constructor(
    private http: HttpService,
    private auth: AuthService,
    private localStore: LocalStorageService,
    private store: Store<Record<string, any>>,
    private fb: FormBuilder,
    private validations: ValidationService,
    private router: Router,
    private ui: UiService,
    private asr: AsrService
  ) {
    this.store.select("login").subscribe((user: User) => {
      this.userSubject.next(user);
    });

    this.store.select("activeAsr").subscribe((status: boolean) => {
      this.activeAsrSubject.next(status);
    });

    this.userRole$.subscribe({
      next: role => {
        this.localStore.setItem("currentRole", role);
      },
    });
  }

  //  FIRST LOGIN
  get updateStep1(): FormGroup {
    return this._updateForm.get("step1") as FormGroup;
  }

  get updateStep2(): FormGroup {
    return this._updateForm.get("step2") as FormGroup;
  }

  get updateStep2HomeAddress(): FormGroup {
    return this._updateForm.get("step2HomeAddress") as FormGroup;
  }

  get updateStep3(): FormGroup {
    return this._updateForm.get("step3") as FormGroup;
  }

  // ACCOUNT SETTINGS
  get profileInfo() {
    return this.accountSettingsForm.get("profile") as FormGroup;
  }

  get paymentInfo() {
    return this.accountSettingsForm.get("payment") as FormGroup;
  }

  loadInitValues(form: FormGroup, source: any): void {
    if (source) {
      Object.keys(form.controls).forEach(key => {
        form.get(key).setValue(source[key] ?? "");
      });
      form.markAsTouched();
    }
  }

  loadUserData() {
    this.userSubject.subscribe(user => {
      if (user) {
        this.updateStep1.patchValue({ ...user });
        this.updateStep2.patchValue({ ...user });

        const userRole = this.localStore.getItem("roles")[0];

        const isTheSameAddress = this.updateStep2.get("sf_same_mailing").value;

        if (userRole?.name === UserRole.PREREGISTER && !isTheSameAddress) {
          this.updateStep2HomeAddress.patchValue({
            ...user.home_address,
            sf_same_mailing: isTheSameAddress,
          });
        }
      }
    });
  }

  loadClientIP() {
    this.ui
      .getAppSetup$()
      .pipe(
        switchMap(setup => {
          if (!setup?.ip_info_token) return of(null);

          const storedIP = this.localStore.getItem("arc.client");

          if (storedIP) {
            this.clientIPSubject.next(storedIP);
            return of(null);
          }

          return this.http.get(
            `${environment.ipServer}?token=${setup.ip_info_token}`
          );
        })
      )
      .subscribe(resp => {
        if (!resp) return;

        const ip = resp?.ip ?? "";
        this.clientIPSubject.next(ip);
        this.localStore.setItem("arc.client", ip);
      });
  }

  getClientIP() {
    return this.clientIPSubject.getValue();
  }

  updateData() {
    if (
      this.updateStep1.invalid ||
      this.updateStep2.invalid ||
      this.updateStep3.invalid
    ) {
      return;
    }

    const payload = {
      ...this.updateStep1.value,
      ...this.updateStep2.value,
      ...this.updateStep3.value,
    };

    payload.sf_birth_day = moment(payload.sf_birth_day)
      .utc()
      .format("YYYY-MM-DD");

    const userRole = this.localStore.getItem("roles")[0];

    if (userRole.name === UserRole.PREREGISTER) {
      return this._updateProfileLeadData(payload);
    }

    return this.auth
      .updateData(payload)
      .pipe(tap(() => this._updateForm.reset()));
  }

  private _updateProfileLeadData(payloadObj: any) {
    const homeAddress = {
      sf_address: null,
      sf_state: null,
      sf_city: null,
      sf_county: null,
      sf_zip_code: null,
    };

    const sfSameMailing = this.updateStep2.get("sf_same_mailing").value;

    const payloadBase = {
      ...payloadObj,
      home_address: homeAddress,
      sf_same_mailing: !sfSameMailing ? 0 : sfSameMailing,
    };

    if (!sfSameMailing) {
      if (this.updateStep2HomeAddress.invalid) return;

      payloadBase.home_address = { ...this.updateStep2HomeAddress.value };
      delete payloadBase.home_address.sf_same_mailing;
    }

    const payload = payloadBase;

    delete payload.locale;

    return this.auth
      .updateProfileLeadData(payload)
      .pipe(tap(() => this._updateForm.reset()));
  }

  getToken(): string | false {
    return this.hasUser() ? this.loadUserToken() : false;
  }

  hasStorageUser(): boolean {
    return !!this.getToken();
  }

  hasUser(): boolean {
    return !!this.loadUserToken();
  }

  loadUserToken(): string {
    return this.sessionToken || this.localStore.getItem("token");
  }

  getUser(): User {
    return this.userSubject.getValue();
  }

  getRole(): Role {
    return this.roleSubject.getValue();
  }

  getRoles(): Record<string, any>[] {
    return this.rolesSubject.getValue();
  }

  getAsrTypes(): AsrType[] {
    return this.asrTypesSubject.getValue();
  }

  getAccountExecutive(): AccountExecutive {
    return this.accountExecutiveSubject.getValue();
  }

  getCommissionPayment(): string {
    return this.commissionPayment;
  }

  setCurrentUser(userData: UserData, store: boolean | string = false) {
    const { user } = userData;
    user.sf_birth_day = new Date(
      moment(user.sf_birth_day).utc().format("MM/DD/YYYY")
    );

    this.userSubject.next(user);
    this.store.dispatch(login(user));
    if (store) {
      this.storeUser(userData);
    }
  }

  setCurrentRole(role: Record<string, any>) {
    if (!role) return;

    const menuModules = role.modules.filter(
      (m: Module) =>
        m.type === MenuTypeEnum.MENU || m.type === undefined || m.type === null
    );

    const navbarModules = role.modules.filter(
      (m: Module) => m.type === MenuTypeEnum.NAVBAR
    );

    role.modules = [...menuModules];

    this.navbarModulesSubject.next(navbarModules);
    this.roleSubject.next(role);
  }

  setRoles(roles: Record<string, any>[]) {
    this.rolesSubject.next(roles);
  }

  setAsrTypes(asrTypes: AsrType[]) {
    this.asrTypesSubject.next(asrTypes);
  }

  setAccountExecutive(accountExecutive: AccountExecutive) {
    this.accountExecutiveSubject.next(accountExecutive);
  }

  setMenu(menu: Menu) {
    this.menuSubject.next(menu);
  }

  getMenu(): Menu {
    return this.menuSubject.value;
  }

  setAdditionalModules(modules: Module[]) {
    this.includeModulesSubject.next(
      modules.filter(m => m.action === ModuleAction.INCLUDE)
    );

    this.excludeModulesSubject.next(
      modules.filter(m => m.action === ModuleAction.EXCLUDE)
    );
  }

  getAdditionalModules(): Record<ModuleAction, Module[]> {
    return {
      INCLUDE: this.includeModulesSubject.getValue(),
      EXCLUDE: this.excludeModulesSubject.getValue(),
    };
  }

  getAdditionalRoutes(): Record<ModuleAction, string[]> {
    const info = this.getAdditionalModules();

    return {
      INCLUDE: info.INCLUDE.map(m => m.route),
      EXCLUDE: info.EXCLUDE.map(m => m.route),
    };
  }

  setCurrentModule(module: Module) {
    this.currentModuleSubject.next(module);
  }

  async storeUser(userData: UserData) {
    this.sessionToken = userData.token;
    const d = new Date();
    document.cookie = `clarologged=true;expires=${d};domain=.;path=/`;
    this.localStore.setItem("token", userData.token);
  }

  async fetchUserData() {
    return this.http
      .getModule(`${this.profileModule}`, false, this.loadUserToken())
      .pipe(filter(data => !!data));
  }

  fetchUser(displayLoad = true): Observable<UserData> {
    if (displayLoad) {
      this.ui.startLoading();
    }

    this.loadClientIP();

    return this.getUserDetails().pipe(
      switchMap(resp => {
        if (resp.data.logged_in_as) {
          return this.asr.getAsrTypes().pipe(
            map(asrTypes => joinAsrTypesModules(asrTypes)),
            map(asrModules =>
              joinUserModulesWithAsr(resp.data.menu.roles, asrModules)
            ),
            map(roles => {
              resp.data.menu.roles = roles;
              return resp;
            })
          );
        }
        return of(resp);
      }),
      map((response: { msg: string; data: UserData }) => {
        const { data } = response;

        if (data.contracts?.length > 0) {
          this.localStore.setItem("contracts", data.contracts);
          this.pendingContracts$.next(data.contracts);
        }

        if (data.account_commissions_paid_type) {
          this.commissionPayment =
            data.account_commissions_paid_type.Commission_Payment__c;
        }

        this.setCurrentUser(data);
        this.localStore.setItem("roles", data.menu.roles);

        this.setAdditionalModules(data.menu.modules);
        this.setRoles(
          this.auth.loadMenuOptions(data.menu.roles, this.getAdditionalRoutes())
        );
        this.setAsrTypes(data.menu.asrTypes ?? []);
        const favoriteRole =
          this.getRoles().find(role => role.id === data.user.default_role_id) ??
          this.getRoles()[0];
        const rawDefaultRole = this.localStore.getItem("currentRole");
        const defaultRole = rawDefaultRole
          ? this.getRoles().find(role => role.id === rawDefaultRole.id)
          : undefined;
        defaultRole
          ? this.setCurrentRole(defaultRole)
          : this.setCurrentRole(favoriteRole);
        this.setActiveAsr(data.logged_in_as);
        this.setAccountExecutive(data.account_executive);

        return data;
      }),
      finalize(() => this.ui.stopLoading())
    );
  }

  setActiveAsr(status: boolean): void {
    if (status !== undefined) {
      this.localStore.setItem(Asr.ASR_STATUS, status);
      const statusAsr = this.localStore.getItem(Asr.ASR_STATUS) as boolean;
      this.store.dispatch(activeAsr({ status: statusAsr }));
    }
  }

  getActiveAsr(): boolean {
    return this.activeAsrSubject.getValue();
  }

  goToContracts(contract: string) {
    this.router.navigate(["/agreements", contract]);
  }

  goToUpdateData() {
    this.router.navigate(["/update-info"]);
  }

  goToSelectRole() {
    this.router.navigate(["/welcome"]);
  }

  removeUserData() {
    this.auth.logout();
  }

  // Fav Modules

  getFavModules() {
    return this.http.getModule(
      `${this.userPrefix}/${this.favModules.favorites}`
    );
  }

  addFavModule(id: number) {
    return this.http
      .getModule(`${this.userPrefix}/${this.favModules.addToFav(id)}`)
      .pipe(filter(data => !!data));
  }

  removeFromFav(id: number) {
    return this.http
      .getModule(`${this.userPrefix}/${this.favModules.removeFromFav(id)}`)
      .pipe(filter(data => !!data));
  }

  // Profile Modules
  getUserDetails(token = "") {
    return this.http.getModule(`${this.profilePrefix}`);
  }

  getModulesByRoute(
    url: string,
    moduleRouterHeaderIsRequired = true
  ): Observable<ModuleDataResponse> {
    return this.http.getModuleWithoutParams<ModuleDataResponse>(
      `${this.modulePrefix}/by-route${url}`,
      moduleRouterHeaderIsRequired
    );
  }

  // Update info
  updateFirstTimeUser(data: Record<string, string | number>) {
    return this.http
      .putModule(
        `${this.profilePrefix}/${this.profileModule.firstTime()}`,
        data
      )
      .pipe(filter(data => !!data));
  }

  updateOmboardingAt() {
    return this.http.putModule(
      `${this.profilePrefix}/${this.profileModule.onboarding()}`,
      ""
    );
  }

  updateTermsAndConditions(data: boolean) {
    return this.http.putModule(
      `${this.profilePrefix}/${this.profileModule.terms()}`,
      { terms_conditions: data ? 1 : 0 }
    );
  }

  updateDefaultProfile(roleId: number) {
    return this.http.putModule(
      `${this.profilePrefix}/${this.profileModule.update_role()}/${roleId}`,
      ""
    );
  }

  setOnboardingTrue() {
    return this.auth.updateOnboarding();
  }

  listOptionsSelect(id: any, option: string, lob?): Observable<any | boolean> {
    const options = {
      params: { id, option },
    };
    if (lob) {
      options["params"]["lob"] = lob;
    }

    return this.http.getModule("");
  }

  readConfig(id): Observable<any | boolean> | void {
    const options = {
      params: { id },
    };
  }

  loadLoginData(data, storeUser?: boolean | string) {
    const { menu, token, user } = data;
    this.setAdditionalModules(menu.modules);
    this.setRoles(
      this.auth.loadMenuOptions(menu.roles, this.getAdditionalRoutes())
    );
    this.setCurrentUser({ user, token, menu }, storeUser);
  }

  isASR() {
    return this.getAsrTypes().length > 0 || this.getActiveAsr();
  }

  getAgencies(): Observable<AgencyTree[]>{
    const url = `${this.profilePrefix}/agency/agents`;
    return this.http.getModule<{data: {agency_tree_from_agency: {treeAgencies: AgencyTree}}}>(url)
      .pipe(
        map((res) => res.data.agency_tree_from_agency.treeAgencies),
        map((res) => agencyTreeToList(res))
      );
  }

  getAgents(): Observable<AgencyTree[]>{
    const url = `${this.profilePrefix}/agency/agents`;
    return this.http.getModule<{data: {agency_tree_from_agency: {treeAgencies: AgencyTree}}}>(url)
      .pipe(
        map((res) => res.data.agency_tree_from_agency.treeAgencies),
        map((res) => agentsTreeToList(res))
      );
  }
}
