import {Directive} from '@angular/core';

@Directive({
  selector: '[cdkPrefix]',
  host: {
    class: 'cdk-input-prefix',
  },

})
export class PrefixDirective {
  constructor() { }
}
