<app-message-with-counter>
  <span class="font-bold" Message>
    Important: You must sign the contract to access your ARC account. Need
    assistance? Get in touch with your account executive.
  </span>
  <span ToGoTo> To go to Login </span>
</app-message-with-counter>
<div class="contract">
  <div class="d-sm-none px-4 w-100 my-5 my-sm-0">
    <img [src]="appLogo" alt="ARC" height="40" />
  </div>
  <div class="d-flex justify-content-end contract-content">
    <div
      class="d-flex flex-column justify-content-center align-items-center bg-white counter-contracts mb-2">
      {{ counterContracts.index }}/{{ counterContracts.totalContracts }}
    </div>
  </div>
  <div class="card contract-content">
    <app-contract-sign
      (counterContracts)="counterContracts = $event"
      [decline]="true"
      [counterToDecline]="true"
      [successRoute]="'/home'"
      [isShowSkip]="true">
    </app-contract-sign>
  </div>
  <app-social-media backgroundClass="bg-warning"></app-social-media>
</div>
