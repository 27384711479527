import {Directive, ElementRef, Input, HostListener} from '@angular/core';

@Directive({
  selector: '[inputrestriction]',
})
export class InputRestrictionDirective {
  @Input('inputrestriction') InputRestriction : string;
  @Input('chars') Chars = 0;

  private element : ElementRef;

  constructor(element : ElementRef) {
    this.element = element;
  }

  @HostListener('keypress', ['$event'])
  handleKeyPress(event : KeyboardEvent) {
    const regex = new RegExp(this.InputRestriction);
    const str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    console.log(this.element.nativeElement.value.length);
    if (this.element.nativeElement.value.length > this.Chars) return false;
    if (regex.test(str)) {
      return true;
    }

    event.preventDefault();
    return false;
  }
}
