import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {environment} from './environments/environment';
import {RootModule} from './app/root/root.module';

if (environment.production) {
  enableProdMode();
  // check if window exists, if you render backend window will not be available
  // if(window) {
  //   window.console.log = () => { }
  // }
}

platformBrowserDynamic().bootstrapModule(RootModule)
  .catch((err) => console.error(err));
