<div class="notifications-container">
  <ul class="notifications list-group list-group-flush w-100">
    <div
      class="notifications-header d-flex justify-content-between align-items-center shadow">
      <div class="notifications-header-pending d-flex align-item-center">
        <span class="length">{{ notificationsCount }}&nbsp;</span>
        <span>Pending notifications</span>
      </div>
      <button
        cdkButton
        class="action-button notifications-mark"
        [class.mark-disabled]="notificationsCount < 1"
        (click)="markAllAsRead()">
        Mark all as read
      </button>
    </div>
    <div class="notifications-list">
      <ng-container *ngIf="notifications.length; else noNotifications">
        <a
          class="list-group-item shadow notification p-0"
          href="javascript:void(0)"
          *ngFor="let notification of notifications; let i = index">
          <app-message
            [notification]="notification"
            (click)="markAsRead(notification.id)"></app-message>
        </a>
      </ng-container>
      <ng-template #noNotifications>
        <li class="list-group-item">
          <p class="empty-state">No pending notifications</p>
        </li>
      </ng-template>
    </div>
    <a
      class="list-group-item list-group-item-action notifications-footer shadow text-center p-2"
      routerLink="/notifications"
      (click)="close()">
      <span class="notifications-all">View all notifications</span>
    </a>
  </ul>
</div>
