import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdateInformationComponent } from './update-information.component';
import { AddressAutocompleteModule } from '@app/modules/@cdk/address-autocomplete/address-autocomplete.module';
import { FormGroupModule } from '@app/modules/@cdk/forms/input/form-group/form-group.module';
import { InputModule } from '@app/modules/@cdk/forms/input/input.module';
import { ButtonModule } from '@app/modules/@cdk/forms/button/button.module';
import { IconModule } from '@app/modules/@cdk/icon/icon.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SelectModule } from '@app/modules/@cdk/forms/select/select.module';
import { NgxMaskModule } from 'ngx-mask';
import { AddressModule } from './components/address/address.module';
import { PasswordModule } from './components/password/password.module';
import { PersonalInformationModule } from './components/personal-information/personal-information.module';
import { AuthLayoutModule } from '../../../../layout/auth-layout/auth-layout.module';
import { UpdateInformationRoutingModule } from './update-information-routing.module';
import { PortalModule } from '@angular/cdk/portal';
import { DirectivesModule } from '@app/directives/directives.module';
import { HomeAddressModule } from './components/home-address/home-address.module';

@NgModule({
  declarations: [UpdateInformationComponent],
  imports: [
    CommonModule,
    AddressAutocompleteModule,
    FormGroupModule,
    InputModule,
    ButtonModule,
    IconModule,
    ReactiveFormsModule,
    SelectModule,
    NgxMaskModule,
    AddressModule,
    HomeAddressModule,
    PasswordModule,
    PersonalInformationModule,
    AuthLayoutModule,
    UpdateInformationRoutingModule,
    PortalModule,
    DirectivesModule,
  ],
  exports: [UpdateInformationComponent],
})
export class UpdateInformationModule {}
