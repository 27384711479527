export interface AddressAutocompleteResponse {
  address: any;
  addressData: AddressData;
}

export interface AddressData {
  city: string;
  state: string;
  county: string;
  zip_code: any;
}

export interface GeocoderAddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}
