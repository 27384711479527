import {
  Component,
  Input,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidationService } from '@app/services/validation/validation.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-password-form',
  templateUrl: './password-form.component.html',
  styleUrls: ['./password-form.component.scss'],
})
export class PasswordFormComponent
implements AfterViewInit, OnChanges, OnInit, OnDestroy {
  inputErrors = {
    password: false,
    passwordConfirm: false,
    currentPassword: false,
  };

  visibility = {
    current_password: false,
    password: false,
    password_confirmation: false,
  };

  public passwordValidations = {
    change: false,
    caracters: false,
    uppercase: false,
    lowercase: false,
    number: false,
    symbols: false,
  };

  @Input() passwordControl: FormControl;
  @Input() passwordConfirmationControl: FormControl;
  @Input() oldPasswordControl: FormControl;
  @Input() accountSettings = false;
  @Input() styleMarginTop: number;
  @Input() component: string;

  @Output() validationForm: EventEmitter<boolean> = new EventEmitter(false);
  @Output() next: EventEmitter<boolean> = new EventEmitter(false);

  controlHasError = this.validations.controlHasError;
  private subscriptions: Record<string, Subscription> = {};

  constructor(private validations: ValidationService) {}

  ngOnInit(): void {
    this.validationFormEmit(this.passwordControl?.value);
  }

  ngOnDestroy(): void {
    Object.values(this.subscriptions).forEach((value) => value?.unsubscribe());
  }

  ngAfterViewInit(): void {
    this.subscriptions['passwordControl'] =
      this.passwordControl?.valueChanges.subscribe({
        next: (nextValue) => {
          this.validationFormEmit(nextValue, true);
        },
      });

    this.subscriptions['passwordConfirmationControl'] =
      this.passwordConfirmationControl?.valueChanges.subscribe({
        next: (nextValue) => {
          this.validationFormEmit(nextValue, true);
        },
      });
  }

  validationFormEmit(value: string, change = false) {
    this.passwordValidations.change = change;
    this.passwordValidations.caracters =
      value?.length >= this.validations.passwordMinLength;
    this.passwordValidations.number =
      this.validations.numberPattern.test(value);
    this.passwordValidations.uppercase =
      this.validations.uppercasePattern.test(value);
    this.passwordValidations.lowercase =
      this.validations.lowercasePattern.test(value);
    this.passwordValidations.symbols =
      this.validations.symbolsPattern.test(value);

    const validation = Object.values(this.passwordValidations).every(Boolean);
    this.validationForm.emit(
      validation && !this.passwordConfirmationControl.hasError('mismatchValues')
    );
  }

  ngOnChanges({ accountSettings }: SimpleChanges) {
    if (!accountSettings?.currentValue) return;
    this.accountSettings = accountSettings.currentValue;
  }

  viewPassword() {
    if (!this.passwordControl.value) return;
    this.visibility.password = !this.visibility.password;
  }

  viewPasswordConfirmation() {
    if (!this.passwordConfirmationControl?.value) return;
    this.visibility.password_confirmation =
      !this.visibility.password_confirmation;
  }

  validatePasswordRequireKeyUp(data: string, inputName: string) {
    let hasMismatchValues =
      this.passwordConfirmationControl.hasError('mismatchValues');

    if (inputName === 'password') {
      hasMismatchValues = this.passwordControl.hasError('mismatchValues');

      this.passwordConfirmationControl.value?.length &&
        this.passwordConfirmationControl.markAsTouched({ onlySelf: true });
    } else {
      this.passwordControl.markAsTouched({ onlySelf: true });
    }

    const hasEmptyValue = (data?.trim()?.length ?? 0) <= 0;
    const hasError = hasEmptyValue || hasMismatchValues;

    this.inputErrors[inputName] = hasError;
  }

  validateCurrentPassword(data: string) {
    const isRequired = this.oldPasswordControl.hasError('required');

    const hasEmptyValue = (data?.trim()?.length ?? 0) <= 0;
    const hasError = hasEmptyValue || isRequired;

    this.inputErrors['currentPassword'] = hasError;
  }

  keyDown() {
    this.next.emit(true);
  }
}
