<div class="row mb-2 mb-md-0 {{ component }}">
  <div
    [ngStyle]="{ 'margin-top.rem': styleMarginTop }"
    class="text-center col-6 col-md-3">
    <strong
      class="text-center"
      [ngClass]="
        change
          ? passwordValidations.caracters
            ? 'rule-valid'
            : 'text-danger'
          : ''
      ">
      +8
      <br />
      Characters</strong
    >
  </div>

  <div
    [ngStyle]="{ 'margin-top.rem': styleMarginTop }"
    class="text-center col-6 col-md-3">
    <strong
      class="text-center"
      [ngClass]="
        change
          ? passwordValidations.uppercase && passwordValidations.lowercase
            ? 'rule-valid'
            : 'text-danger'
          : ''
      ">
      AAaa
      <br />
      Upper&lower
    </strong>
  </div>

  <div
    [ngStyle]="{ 'margin-top.rem': styleMarginTop }"
    class="text-center col-6 col-md-3">
    <strong
      class="text-center"
      [ngClass]="
        change
          ? passwordValidations.number
            ? 'rule-valid'
            : 'text-danger'
          : ''
      ">
      123
      <br />
      1 number
    </strong>
  </div>

  <div
    [ngStyle]="{ 'margin-top.rem': styleMarginTop }"
    class="text-center col-6 col-md-3">
    <strong
      class="text-center"
      [ngClass]="
        change
          ? passwordValidations.symbols
            ? 'rule-valid'
            : 'text-danger'
          : ''
      ">
      #%$
      <br />
      1 symbol
    </strong>
  </div>
</div>