import {MediaScreenDownDirective} from './media-screen-down.directive';
import {MediaScreenUpDirective} from './media-screen-up.directive';

import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';

export const BREAK_POINTS_TOKEN = 'BREAK_POINTS';

@NgModule({
  declarations: [MediaScreenUpDirective, MediaScreenDownDirective],
  imports: [CommonModule],
  exports: [MediaScreenDownDirective, MediaScreenUpDirective],
})
export class MediaScreenModule {
  static forRoot(
    breakpoints: string[]
  ): ModuleWithProviders<MediaScreenModule> {
    return {
      ngModule: MediaScreenModule,
      providers: [{provide: BREAK_POINTS_TOKEN, useValue: breakpoints}],
    };
  }
}
