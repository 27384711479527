import {Component, OnInit} from '@angular/core';
import {humanizeSeconds} from '@app/utils/humanizeSeconds';
import {AuthService} from '@app/services/auth/auth-service.service';
import {lastValueFrom} from 'rxjs';

@Component({
  selector: 'app-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styleUrls: ['./email-confirmation.component.scss'],
})
export class EmailConfirmationComponent implements OnInit {
  timeout = 60000; // 1 min
  displayTimeout: string = humanizeSeconds(Math.round(this.timeout / 1000), 'mm:ss');
  loading = false;

  constructor(private auth: AuthService) { }

  ngOnInit(): void {
    this.startTimeout();
  }

  async sendEmail() {
    if (this.timeout !== 0) {
      return;
    }

    this.loading = true;
    try {
      await lastValueFrom(this.auth.forgotPassword(this.auth.getResetUser()));
      this.timeout = 60000;
      this.startTimeout();
    } catch (e) {
      console.log(e);
    }
    this.loading = false;
  }

  startTimeout() {
    const interval = setInterval(() => {
      this.timeout -= 1000;
      this.displayTimeout = humanizeSeconds(Math.round(this.timeout / 1000), 'mm:ss');

      if (this.timeout === 0) clearInterval(interval);
    }, 1000);
  }
}
