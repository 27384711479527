import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { UsersService } from "@app/services/users/users.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private userService: UsersService, private router: Router) {
    // not todo
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.userService.hasStorageUser()) {
      return this.router.parseUrl("/login");
    }
    this.userService.loadClientIP();
    return true;
  }
}
