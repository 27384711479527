import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PersonalInformationComponent} from './personal-information.component';
import {FormGroupModule} from '@app/modules/@cdk/forms/input/form-group/form-group.module';
import {InputModule} from '@app/modules/@cdk/forms/input/input.module';
import {ButtonModule} from '@app/modules/@cdk/forms/button/button.module';
import {IconModule} from '@app/modules/@cdk/icon/icon.module';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {SelectModule} from '@app/modules/@cdk/forms/select/select.module';
import {NgxMaskModule} from 'ngx-mask';
import {DirectivesModule} from '@app/directives/directives.module';

@NgModule({
  declarations: [PersonalInformationComponent],
  imports: [
    CommonModule,
    FormGroupModule,
    InputModule,
    ButtonModule,
    IconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    SelectModule,
    NgxMaskModule,
    FormsModule,
    DirectivesModule,
  ],
  exports: [PersonalInformationComponent],
})
export class PersonalInformationModule { }
