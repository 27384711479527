import { ComponentType } from '@angular/cdk/portal';
import { ContractAgencyComponent } from '@app/shared/contract-sign/forms/contract-agency/contract-agency.component';
import { ContractAgentComponent } from '@app/shared/contract-sign/forms/contract-agent/contract-agent.component';
import { ContractCommissionInAdvanceAgentOfAgencyComponent } from '@app/shared/contract-sign/forms/contract-commission-in-advance-agent-of-agency/contract-commission-in-advance-agent-of-agency.component';
import { ContractCommissionInAdvanceDirectAgentComponent } from '@app/shared/contract-sign/forms/contract-commission-in-advance-direct-agent/contract-commission-in-advance-direct-agent.component';
import { ContractTermsComponent } from '@app/shared/contract-sign/forms/contract-terms/contract-terms.component';

export interface Contract {
  id: number;
  name: string;
  slug: string;
  content: string;
  type: string;
  active: boolean;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
}

export enum TypeOfSignatures {
  DECLINED = 'declined',
  SIGNED = 'signed',
}

export interface EventOnContract {
  contractSign: TypeOfSignatures,
  contract: string
}

export enum ComponentMapName {
  CONTRACT_AGENCY = 'app-contract-agency',
  CONTRACT_AGENT = 'app-contract-agent',
  CONTRACT_TERMS = 'app-contract-terms',
  CONTRACT_COMMISSION_IN_ADVANCE_AGENT_OF_AGENCY = 'contract-commission-in-advance-agent-of-agency',
  CONTRACT_COMMISSION_IN_ADVANCE_DIRECT_AGENT = 'contract-commission-in-advance-direct-agent',
  CONTRACT_COMMISSION_IN_ADVANCE_MY_BUSINESS = 'contract-commission-in-advance-my-business',
}

export const COMPONENT_MAP: Record<string, ComponentType<any>> = {
  [ComponentMapName.CONTRACT_AGENCY]: ContractAgencyComponent,
  [ComponentMapName.CONTRACT_AGENT]: ContractAgentComponent,
  [ComponentMapName.CONTRACT_TERMS]: ContractTermsComponent,
  [ComponentMapName.CONTRACT_COMMISSION_IN_ADVANCE_AGENT_OF_AGENCY]: ContractCommissionInAdvanceAgentOfAgencyComponent,
  [ComponentMapName.CONTRACT_COMMISSION_IN_ADVANCE_DIRECT_AGENT]: ContractCommissionInAdvanceDirectAgentComponent,
};
