export interface DocsLicense {
  attributes: Attributes;
  Id: string;
  Name: string;
  DocumentType__c: string;
  Url__c: string;
  UsuarioArc__c: string;
  UploadDate__c: Date;
  Lead__c: string;
  Verified__c: string;
}

interface Attributes {
  type: string;
  url: string;
}
