import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { LocalStorageService } from "@app/services/storage/local-storage.service";
import { AuthService } from "@app/services/auth/auth-service.service";
import { UiService } from "@app/services/cdk/ui.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(
    private _localStorage: LocalStorageService,
    private _auth: AuthService,
    private _ui: UiService,
    private _router: Router
  ) {
    // not todo
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Obtenemos el token del sessioStorage
    // por favor respetar el es6 al usar typescript
    const token: string = this._localStorage.getItem("token");
    const ip: string = this._localStorage.getItem("arc.client");

    let headers: any = { domain: environment.domain };

    // Validamos si el token existe
    if (token) {
      // Autorizaciòn de tipo Bearer + token
      // El tipo de autorizaciòn depende del back
      headers = { ...headers, Authorization: `Bearer ${token}` };
    }

    if (ip) {
      headers["ip"] = ip;
    }

    // Clonamos el token y lo mandamos en la cabecera de todas las peticiones HTTP
    const request = req.clone({
      setHeaders: {
        ...headers,
      },
    });

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        const { status, error } = err;
        const { msg, data, errors, message } = error ?? {};

        const errorhandle = {
          "401": () => {
            this._ui.showAlert("Please login again", "error", "body", "top");
            this._auth.logout();
            return throwError(() => err);
          },
          "403": () => {
            this._ui.showAlert(
              "You do not have sufficient permissions to access this site",
              "warning",
              "body",
              "top"
            );
            return throwError(() => err);
          },
          "404": () => {
            this._ui.showAlert(
              data?.error ??
                msg ??
                message ??
                "There has ocurred a unexpected error, please try again",
              "error",
              "body",
              "top"
            );
            return throwError(() => err);
          },
          "500": () => {
            this._ui.showAlert(
              msg ??
                message ??
                "There has ocurred a unexpected error, please try again",
              "error",
              "body",
              "top"
            );
            return throwError(() => err);
          },
          "503": () => {
            const { url } = this._router;
            if (url !== "/maintenace") {
              this._router.navigate(["/maintenance"]);
            }

            return throwError(() => err);
          },
          "533": () => {
            const { url } = this._router;
            if (url !== 'home' && !url.includes('module-maintenance')) {
              this._router.navigate([`/module-maintenance`], {
                queryParams: { module: url },
              });
            }

            return throwError(() => err);
          },
          default: () => {
            if (!(data || errors)) {
              return throwError(() => of(null));
            }

            const errorsFields = Object.values(data ?? errors).map(
              (err: Array<string>) => err.join(", ").replace(/\./g, "")
            );

            this._ui.showAlert(
              `${(msg ?? message).replace(".", "")}: ${errorsFields
                .join(", ")
                .replace(/\./g, "")}.`,
              "error",
              "body",
              "top"
            );
            return throwError(() => err);
          },
        };

        return (
          errorhandle[status] ? errorhandle[status]() : errorhandle.default()
        ) as Observable<HttpEvent<any>>;
      })
    );
  }
}
