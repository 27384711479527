import {Component, Input, OnInit} from '@angular/core';
import { ISocialMedia, UiService } from '@app/services/cdk/ui.service';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.scss'],
})
export class SocialMediaComponent implements OnInit {
  @Input() backgroundClass = '';
  @Input() authed = false;

  socialMediaList: ISocialMedia[] = [];
  appName: string;
  appLogo: string;

  constructor(private _ui: UiService) {
    this._ui.getAppSetup$().subscribe((setup) => {
      this.socialMediaList = setup?.social_media ?
        Object.values(setup.social_media) :
        [];
      this.appName = setup?.title;
      this.appLogo = setup?.logo;
    });
  }

  ngOnInit(): void {}

  get company(): string {
    return environment.companyName;
  }
}
