import { AbstractControl } from '@angular/forms';

const notEmailValidator = (control: AbstractControl) => {
  const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

  if (emailRegex.test(control.value)) {
    return { notEmail: true };
  }

  return null;
};

export default notEmailValidator;
