import {Component, OnInit} from '@angular/core';
import {UsersService} from '@app/services/users/users.service';
import {AuthService} from '@app/services/auth/auth-service.service';
import {Router} from '@angular/router';
import {UiService} from '@app/services/cdk/ui.service';
import {finalize} from 'rxjs/operators';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit {
  public roles = [];
  selectedRol: number;
  companyName = environment.companyName;

  constructor(
    private user: UsersService,
    private auth: AuthService,
    private router: Router,
    private ui: UiService
  ) {
    this.user.userRoles$.subscribe((roles) => {
      if (roles?.length > 0) {
        this.roles = roles
          .map((r: { id: any; title: string }) => ({
            id: r.id,
            name: r.title?.toLowerCase(),
          }))
          .filter((r: { name: string }) => r.name?.toUpperCase() !== 'ASR');

        this.selectedRol = this.roles[0].id;
      }
    });
  }

  ngOnInit(): void {}

  submit() {
    if (!this.selectedRol) return;
    this.ui.startLoading();
    this.auth
      .setDefaultRol(this.selectedRol)
      .pipe(finalize(() => this.ui.stopLoading()))
      .subscribe((res) => {
        this.router.navigate(['/home']);
      });
  }
}
