import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ForgotPasswordRoutingModule} from './forgot-password-routing.module';
import {ForgotPasswordComponent} from './pages/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './pages/reset-password/reset-password.component';
import {ResetConfirmationComponent} from './pages/reset-confirmation/reset-confirmation.component';
import {EmailConfirmationComponent} from './pages/email-confirmation/email-confirmation.component';
import {AuthLayoutModule} from '@app/layout/auth-layout/auth-layout.module';
import {FormGroupModule} from '@app/modules/@cdk/forms/input/form-group/form-group.module';
import {InputModule} from '@app/modules/@cdk/forms/input/input.module';
import {IconModule} from '@app/modules/@cdk/icon/icon.module';
import {ButtonModule} from '@app/modules/@cdk/forms/button/button.module';
import {RouterModule} from '@angular/router';
import {ReactiveFormsModule} from '@angular/forms';
import {AddressAutocompleteModule} from '@app/modules/@cdk/address-autocomplete/address-autocomplete.module';
import {PasswordFormModule} from '../../../../shared/password-form/password-form.module';


@NgModule({
  declarations: [
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ResetConfirmationComponent,
    EmailConfirmationComponent,
  ],
  imports: [
    CommonModule,
    ForgotPasswordRoutingModule,
    AuthLayoutModule,
    FormGroupModule,
    InputModule,
    IconModule,
    ButtonModule,
    RouterModule,
    ReactiveFormsModule,
    AddressAutocompleteModule,
    PasswordFormModule,
  ],
})
export class ForgotPasswordModule { }
