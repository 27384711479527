import {NgModule} from '@angular/core';
import {Route, RouterModule, Routes} from '@angular/router';
import {ForgotPasswordComponent} from './pages/forgot-password/forgot-password.component';
import {EmailConfirmationComponent} from './pages/email-confirmation/email-confirmation.component';
import {ResetPasswordComponent} from './pages/reset-password/reset-password.component';
import {ResetConfirmationComponent} from './pages/reset-confirmation/reset-confirmation.component';
import {EmailConfirmationGuard} from './guards/email-confirmation.guard';
import {ResetPasswordGuard} from './guards/reset-password.guard';

const routes: Route[] = [
  {
    path: '',
    component: ForgotPasswordComponent,
  },
  {
    path: 'email-confirmation',
    component: EmailConfirmationComponent,
    canActivate: [EmailConfirmationGuard],
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    canActivate: [ResetPasswordGuard],
  },
  {
    path: 'reset-confirmation',
    component: ResetConfirmationComponent,
    canActivate: [ResetPasswordGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ForgotPasswordRoutingModule { }
