export interface AgencyApi {
    current_page?: number;
    data?: any[];
    first_page_url?: string;
    from?: number;
    last_page?: number;
    last_page_url?: string;
    next_page_url?: string;
    path?: string;
    per_page?: number;
    prev_page_url?: any;
    to?: number;
    total?: number;
  }

export interface AgencyChildrens {
    IdsAgent: string[];
    treeAgencysContacts: TreeAgencysContacts;
  }

export interface TreeAgencysContacts {
    Id?: string;
    Name?: string;
    Type?: string;
    Dependents?: Dependent2[];
  }

export interface Dependent2 {
    Id: string;
    Type: string;
    Name: string;
    Dependents?: Dependent[];
  }

export interface Dependent {
    Id: string;
    Type: string;
    Name: string;
  }
