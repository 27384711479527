<app-auth-layout hero="/assets/img/temp/password-reset-confirmation.svg">
  <div class="d-flex flex-column align-items-center">
    <img src="/assets/img/icons/key.svg" alt="Key" />
    <h4 class="text-center">Password reset</h4>
    <p class="text-center">
      Your password has been succesfully reset.
      <br />
      Click below to enter your account.
    </p>
    <button cdkButton class="text-light redirect" routerLink="/login">
      Go to login
    </button>
    <p class="timer d-flex flex-sm-column align-items-sm-center">
      Redirecting to Sign In:&nbsp;
      <strong class="timeout-text"
        >{{ displayTimeout || '' }}<span *ngIf="displayTimeout">s</span></strong
      >
    </p>
  </div>
</app-auth-layout>
