import {Directive, forwardRef, InjectionToken, Input} from '@angular/core';

export const POPOVER_OPTIONS = new InjectionToken('POPOVER_OPTIONS');

/*
rtl   : Right to left
ltr   : Left to Right
trtl  : On Top and from Right To Left
tltr  : On Top and from Left To Right
ct    : Center Top
cc    : Center of target on center of overlay
cr    : Center Right (Apparently its center left for some reason)
rt    : On the Right aligned with the top
rb    : On the Right aligned with the bottom
lt    : On the Left aligned with the top
lb    : On the Left aligned with the bottom
*/
export type PopoverDir =
  | 'rtl'
  | 'ltr'
  | 'trtl'
  | 'tltr'
  | 'ct'
  | 'cc'
  | 'cr'
  | 'rt'
  | 'rb'
  | 'lt'
  | 'lb';

export interface PopoverOptions {
  dir?: PopoverDir;
  minwidth?: boolean;
  backdrop?: boolean;
  contextmenu?: boolean;
}

@Directive({
  selector: '[popoverOptions]',
  // eslint-disable-next-line no-use-before-define
  providers: [
    {
      provide: POPOVER_OPTIONS,
      useExisting: forwardRef(() => PopoverOptionsDirective),
    },
  ],
})
export class PopoverOptionsDirective implements PopoverOptions {
  @Input('popoverOptions')
  set options(options: PopoverOptions) {
    Object.entries(options).forEach(([k, v]) => (this[k] = v));
  }

  minwidth;
  dir;
  backdrop;
  contextmenu;
}
