import { environment } from "../../../environments/environment";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, Observable, throwError } from "rxjs";
import { retry } from "rxjs/operators";
import { LocalStorageService } from "@app/services/storage/local-storage.service";

export interface IHttpResponse<T = any> {
  msg: string;
  data: Record<string, T>;
}

@Injectable({
  providedIn: "root",
})
export class HttpService {
  // cambiar si se trabaja con api local
  private remote = environment.production;

  public mainUrl = environment.apiURL;
  public prefix = "tenant/v1";
  public modules = {
    setup: "setup",
  };

  public token;

  constructor(
    private httpClient: HttpClient,
    private _router: Router,
    private _localStorage: LocalStorageService
  ) {}

  get(url: string, headers = {}): Observable<any> {
    return this.httpClient
      .get(url, headers)
      .pipe(catchError(error => this.handleError(error)));
  }

  post<T = any>(url: string, data: Record<string, any>, headers = {}) {
    return this.httpClient.post<T>(url, data, headers).pipe(
      // retry(3),
      catchError(error => this.handleError(error))
    );
  }

  put(url: string, data: Record<string, any>, headers = {}): Observable<any> {
    return this.httpClient.put(url, data, headers).pipe(
      retry(3),
      catchError(error => this.handleError(error))
    );
  }

  delete(url: string, headers = {}): Observable<any> {
    return this.httpClient.delete(url, headers).pipe(
      retry(3),
      catchError(error => this.handleError(error))
    );
  }

  patch(url: string, data: Record<string, any>, headers = {}): Observable<any> {
    return this.httpClient.patch(url, data, headers).pipe(
      retry(3),
      catchError(error => this.handleError(error))
    );
  }

  getDefaultHeader(isBlob: Blob) {
    return {
      responseType: isBlob ? ("blob" as "json") : "json",
      headers: new HttpHeaders({ domain: environment.domain }),
    };
  }

  getModuleroute() {
    let moduleRoute = "";
    const path = this._router.url;
    let currentModuleName = path;
    if (path.includes("?")) {
      currentModuleName = path.split("?")[0];
    }

    const currentRole = this._localStorage.getItem("currentRole");
    currentRole?.modules?.forEach(({ route, items }) => {
      if (route === currentModuleName) {
        moduleRoute = path;
      }

      items?.forEach(({ route }) => {
        if (route === currentModuleName) {
          moduleRoute = route;
        }
      });
    });

    return moduleRoute;
  }

  public handleError(errorResponse: HttpErrorResponse) {
    return throwError(() => errorResponse);
  }

  // Root modules
  //
  getModule<T = any>(
    module,
    isBlob?,
    token = this.token,
    pub = false,
    mainUrl = this.mainUrl,
    noCache = false,
    isOptional = true
  ): Observable<T> {
    const header = this.getHeader(pub, token, isBlob, noCache, isOptional);
    if (isBlob) {
      header.reportProgress = true;
      const req = new HttpRequest(
        "GET",
        `${mainUrl}/${this.prefix}/${module}`,
        header
      );
      return this.httpClient.request(req) as any;
    }
    return this.get(`${mainUrl}/${this.prefix}/${module}`, header);
  }

  postModule<T = any>(
    module,
    data = {},
    token = this.token,
    pub = false,
    reportProgress = false,
    mainUrl = this.mainUrl,
    fileName?,
    mimeType?
  ): Observable<T> {
    const header = this.getHeader(pub, token, false, false, fileName, mimeType);

    if (reportProgress) {
      header.reportProgress = true;
      const req = new HttpRequest(
        "POST",
        `${mainUrl}/${this.prefix}/${module}`,
        data,
        header
      );
      return this.httpClient.request(req) as any;
    }

    return this.post<T>(`${mainUrl}/${this.prefix}/${module}`, data, header);
  }

  putModule(
    module,
    data,
    token = this.token,
    pub = false,
    reportProgress = false,
    mainUrl = this.mainUrl
  ) {
    const header = this.getHeader(pub, token);

    if (reportProgress) {
      header.reportProgress = true;
      const req = new HttpRequest(
        "PUT",
        `${mainUrl}/${this.prefix}/${module}`,
        data,
        header
      );
      return this.httpClient.request(req);
    }
    return this.put(`${mainUrl}/${this.prefix}/${module}`, data, header);
  }

  deleteModule(
    module,
    token = this.token,
    pub = false,
    mainUrl = this.mainUrl
  ) {
    const header = this.getHeader(pub, token);
    return this.delete(`${mainUrl}/${this.prefix}/${module}`, header);
  }

  patchModule(
    module,
    data?,
    token = this.token,
    pub = false,
    mainUrl = this.mainUrl
  ) {
    const header = this.getHeader(pub, token);
    return this.patch(`${mainUrl}/${this.prefix}/${module}`, data, header);
  }

  getModuleWithoutParams<T = any>(
    module,
    isOptional = true,
    token = this.token,
    pub = false,
    mainUrl = this.mainUrl,
    noCache = false
  ): Observable<T> {
    const header = this.getHeader(pub, token, undefined, noCache, isOptional);
    return this.get(`${mainUrl}/${this.prefix}/${module}`, header);
  }

  getHeader(
    pub,
    token,
    isBlob?,
    noCache = false,
    fileName?,
    mimeType?,
    isOptional = true
  ) {
    let header;
    if (pub) {
      header = token
        ? { headers: new HttpHeaders({ Authorization: `Bearer ${token}` }) }
        : this.getDefaultHeader(isBlob);
    } else {
      header = token
        ? {
            responseType: isBlob ? ("blob" as "json") : "json",
            headers: new HttpHeaders({ Authorization: `Bearer ${token}` }),
          }
        : this.getDefaultHeader(isBlob);
    }

    if (noCache) {
      header.headers = header.headers
        // .set('Content-Type', 'application/json; charset=utf-8')
        .set(
          "Cache-Control",
          "no-cache, no-store, must-revalidate, post-check=0, pre-check=0"
        )
        .set("Pragma", "no-cache")
        .set("Expires", "0");
    } else {
      header.headers = header.headers.set("domain", environment.domain);

      const currentRole = this._localStorage.getItem("currentRole");
      if (currentRole) {
        header.headers = header.headers.set(
          "current-role-name",
          `${currentRole.name}`
        );

        header.headers = header.headers.set(
          "current-role-id",
          `${currentRole.id}`
        );
      }

      if (this.getModuleroute() && isOptional) {
        header.headers = header.headers.set(
          "module-route",
          this.getModuleroute()
        );
      }
    }
    return header;
  }

  normalizeURL(url: string): string {
    if (!(url.startsWith("http://") || url.startsWith("https://")))
      url = `http://${url}`;

    return new URL(url).toString();
  }
}
