import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageWithCounterComponent } from './message-with-counter.component';
import { IconModule } from '@app/modules/@cdk';


@NgModule({
  declarations: [MessageWithCounterComponent],
  imports: [
    CommonModule,
    IconModule,
  ],
  exports: [MessageWithCounterComponent],
})
export class MessageWithCounterModule { }
